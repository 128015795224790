/** @module managers */
import { Howl } from "howler";
import { EnvConfig } from "../helpers/EnvConfig";
import { log } from "../helpers/Logger";
import { Viewer3DConfiguration } from "../models/Viewer3DConfiguration";

/** This class is responsible to load and reproduce audio resources. */
export class AudioManager {

  /** Object holding the sound sources. */
  private soundsSources: Map<string, string>;
  /** Object holding the loaded sounds. */
  private sounds: Map<string, Howl>;

  /**
   * Creates a new instance.
   * @param audioConfig The audio config containing the audio resources.
   */
  constructor(audioConfig: Viewer3DConfiguration["audio"]) {
    const absStaticSoundBaseUrl: string = EnvConfig.GetStaticFilesBaseUrl() + "sounds/";
    this.sounds = new Map();
    this.soundsSources = new Map();
    for (const audioResourceKey in audioConfig) {
      if (audioConfig.hasOwnProperty(audioResourceKey)) {
        const audioResourceName: string = audioConfig[audioResourceKey];
        const soundSource: string = absStaticSoundBaseUrl + audioResourceName;
        this.soundsSources.set(audioResourceKey, soundSource);
      }
    }
  }

  /**
   * Play the sound identified by the provided id.
   * @param id The sound id.
   * @param isLoop Whether the sound has to be looped.
   */
  public playSound(id: string, isLoop: boolean): void {
    if (this.soundsSources.has(id)) {
      let sound: Howl | undefined;
      if (!this.sounds.has(id)) {
        const src: string = this.soundsSources.get(id) as string;
        sound = new Howl({ src: [src] });
        this.sounds.set(id, sound);
      }
      else {
        sound = this.sounds.get(id) as Howl;
      }
      if (!sound.playing()) {
        sound.loop(isLoop);
        sound.play();
      }
    }
    else {
      log.warn(`Sound ${id} not found in configuration.`);
    }
  }


  /**
   * Stop the sound identified by the passed id.
   * @param id The sound id.
   */
  public stopSound(id: string): void {
    if (this.sounds.has(id)) {
      const sound: Howl = this.sounds.get(id) as Howl;
      sound.stop();
    }
    else {
      log.warn(`Sound ${id} not found in configuration.`);
    }
  }

}
