/** @module managers */
import dat from "dat.gui";
import { AnimationClip, AxesHelper } from "three";
import { SceneManager } from "./SceneManager";
import { ViewManager } from "./ViewManager";
import { PostProcessingManager } from "./PostProcessingManager";
import { RenderManager } from "./RenderManager";
import { AssetDescriptor } from "../models/Viewer3DProxy";
import { AnimationManager } from "./AnimationManager";
import { SignalRBackend } from "../services/SignalRBackend";
import { GUIManager } from "./GUIManager";
import { Water } from "../objects/Water";
import { EventManager } from "./EventManager";
import { BusBackend } from "../services/BusBackend";
import { TestInputAPI } from "../helpers/TestInputAPI";
import { Viewer3DConfiguration } from "../models/Viewer3DConfiguration";
import { ErrorUtils } from "../helpers/ErrorUtils";

/**
 * This class is responsible for the debug gui (dat.gui). It provides a button for loading the assets
 * and other options to alter the scene parameters and test the input APIs.
 */
export class DebugGUIManager {

  /** The gui manager instance. */
  private guiManager: GUIManager;
  /** The scene manager instance. */
  private sceneManager!: SceneManager;
  /** The view manager instance. */
  private viewManager!: ViewManager;
  /** The event manager instance. */
  private eventManager!: EventManager;
  /** The debug gui html container element. */
  private datGuiContainer: HTMLElement;
  /** The loading bar html container element. */
  private loadingBarContainer: HTMLElement;
  /** The debug gui. */
  private datGui: dat.GUI;
  /** The debug gui load scene button. */
  private loadSceneItem: dat.GUIController | undefined;
  /** The debug gui views folder. */
  private viewsFolder: dat.GUI | undefined;
  /** The debug gui sky folder. */
  private skyFolder: dat.GUI | undefined;
  /** The debug gui water folder. */
  private waterFolder: dat.GUI | undefined;
  /** The debug gui animations folder. */
  private animationsFolder: dat.GUI | undefined;
  /** The debug gui debug tools folder. */
  private debugToolsFolder: dat.GUI | undefined;
  /** The message api debug folder. */
  private messageApiFolder: dat.GUI | undefined;
  /** The debug gui malamocco animations subfolder. */
  private animationMalamocco: dat.GUI | undefined;
  /** The debug gui lido treporti animations subfolder. */
  private animationLidoTre: dat.GUI | undefined;
  /** The debug gui lido san nicolò animations subfolder. */
  private animationLidoNico: dat.GUI | undefined;
  /** The debug gui chioggia animations subfolder. */
  private animationChioggia: dat.GUI | undefined;
  /** Temp value for populating the animations. */
  private animationTimeTmp: number;
  /** The test input api helper. */
  private testInputApi: TestInputAPI;
  /** The Axes Helper located at the scene origin */
  private axesHelper: AxesHelper;

  /**
   * Creates a new instance
   * @param guiManager The instance of gui manager.
   */
  constructor(guiManager: GUIManager) {
    this.guiManager = guiManager;
    this.loadingBarContainer = this.createLoadingBar();
    this.datGuiContainer = this.createDebugGUIContainer();
    this.datGui = new dat.GUI({ width: 320 });
    this.datGuiContainer.appendChild(this.datGui.domElement);
    this.animationTimeTmp = 0;
    this.testInputApi = new TestInputAPI(this);
    this.axesHelper = new AxesHelper(1000); // 1 km
  }

  /**
   * Sets the view manager instance.
   * @param viewManager The view manager.
   */
  public setViewManager(viewManager: ViewManager): void {
    this.viewManager = viewManager;
  }

  /**
   * Sets the scene manager instance.
   * @param sceneManager The scene manager.
   */
  public setSceneManager(sceneManager: SceneManager): void {
    this.sceneManager = sceneManager;
  }

  /**
   * Sets the event manager instance.
   * @param eventManager The event manager.
   */
  public setEventManager(eventManager: EventManager): void {
    this.eventManager = eventManager;
    this.testInputApi.setSignalRHandler(eventManager.getSignalRMessageHandler());
  }

  /**
   * Sets the bus backend service.
   * @param busBackend The bus backend service.
   */
  public setBusBackend(busBackend: BusBackend): void {
    this.testInputApi.setBusBackend(busBackend);
  }

  /**
   * Populates the debug gui with items (e.g. folders, buttons) and binds them to their controllers.
   * @param sceneManager The scene manager instance.
   * @param signalrBackend The signalr backend instance.
   * @param postprocessingManager The postprocessing manager instance.
   * @param renderManager The render manager instance.
   */
  public addParams(sceneManager: SceneManager, signalrBackend: SignalRBackend, postprocessingManager: PostProcessingManager, renderManager: RenderManager): void {
    this.skyFolder = this.datGui.addFolder("Sky");
    this.waterFolder = this.datGui.addFolder("Water");
    this.animationsFolder = this.datGui.addFolder("Animations");
    this.viewsFolder = this.datGui.addFolder("Views");
    this.debugToolsFolder = this.datGui.addFolder("Debug Tools");
    this.messageApiFolder = this.datGui.addFolder("Test Bus Input API");
    this.loadSceneItem = this.datGui.add(this, "loadScene").name("Load Scene");
    this.skyFolder.add(sceneManager.getSkyParameters(), "inclination", 0, 0.8, 0.0001)
      .onChange(() => sceneManager.updateSkyWaterLight()).name("Inclination");
    this.skyFolder.add(sceneManager.getSkyParameters(), "azimuth", 0, 1, 0.0001)
      .onChange(() => sceneManager.updateSkyWaterLight()).name("Azimuth");
    this.waterFolder.add(sceneManager, "toggleWaters").name("Toggle Water");
    this.animationLidoTre = this.animationsFolder.addFolder("Lido Treporti");
    this.animationLidoNico = this.animationsFolder.addFolder("Lido San Nicolò");
    this.animationMalamocco = this.animationsFolder.addFolder("Malamocco");
    this.animationChioggia = this.animationsFolder.addFolder("Chioggia");
    this.viewsFolder.add(this, "saveCurrentView").name("Save Current View");
    this.addViewToDatGUI("Malamocco");
    this.addViewToDatGUI("Chioggia");
    this.addViewToDatGUI("Lido");
    this.debugToolsFolder.add(postprocessingManager, "isPostProcessingEnabled").name("PostProcessing");
    this.debugToolsFolder.add(renderManager.getWebGLRenderer().shadowMap, "enabled", false).name("Shadows");
    this.debugToolsFolder.add(sceneManager.getDynamicAssetManager(), "isMockTemplate").name("Use Dyn Asset Mock");
    this.debugToolsFolder.add(signalrBackend, "getIsConnected").name("Get SignalR state");
    this.debugToolsFolder.add(this, "toggleAxisHelper").name("Toggle Origin Axes");
    this.debugToolsFolder.add(this.guiManager.getMinimapManager(), "toggleViewConeVisible").name("Toggle View Cone");
    this.messageApiFolder.add(this.testInputApi, "setRenderingEnabled").name("Rendering Enable");
    this.messageApiFolder.add(this.testInputApi, "setRenderingDisabled").name("Rendering Disable");
    this.messageApiFolder.add(this.testInputApi, "setNavigationOrigin").name("Navigation Origin");
    this.messageApiFolder.add(this.testInputApi, "setNavigationLido").name("Navigation Lido");
    this.messageApiFolder.add(this.testInputApi, "setNavigationMalamocco").name("Navigation Malamocco");
    this.messageApiFolder.add(this.testInputApi, "setNavigationChioggia").name("Navigation Chioggia");
    this.messageApiFolder.add(this.testInputApi, "setNavigationAsset").name("Navigation Asset");
    this.messageApiFolder.add(this.testInputApi, "playSoundAlertLow").name("Play alert low");
    this.messageApiFolder.add(this.testInputApi, "stopSoundAlertLow").name("Stop alert low");
    this.messageApiFolder.add(this.testInputApi, "selectAsset").name("Select Asset");
    this.messageApiFolder.add(this.testInputApi, "selectAssets").name("Select Assets");
    this.messageApiFolder.add(this.testInputApi, "selectDynamicAsset").name("Select Dyn Asset");
    this.messageApiFolder.add(this.testInputApi, "clearAssetSelection").name("Clear Assets Selection");
    this.messageApiFolder.add(this.testInputApi, "createMarkers").name("Create Markers");
    this.messageApiFolder.add(this.testInputApi, "createDynamicAssetMarkers").name("Create Dyn Assets Markers");
    this.messageApiFolder.add(this.testInputApi, "clearMarkers").name("Clear Markers");
    this.messageApiFolder.add(this.testInputApi, "setAnimationValue").name("Set Animation Value");
    this.messageApiFolder.add(this.testInputApi, "toggleDynamicAssetsVisibility").name("Toggle Dyn Assets");
    this.messageApiFolder.add(this.testInputApi, "setDynamicAssetsLocation").name("Dyn Assets Location");
    this.messageApiFolder.add(this.testInputApi, "setDynamicAssetsAdd").name("Dyn Assets Add");
    this.messageApiFolder.add(this.testInputApi, "setDynamicAssetsRemove").name("Dyn Assets Remove");
  }

  /** Get all the dynamic asset ids from the inner map. */
  public getDynamicAssetsIds(): string[] {
    return this.sceneManager.getDynamicAssetManager().getDynamicAssetIds();
  }

  /**
   * Adds waters items to the debug gui.
   * @param water The water instance.
   * @param name The water name.
   */
  public addWaterItems(water: Water, name: string): void {
    if (this.waterFolder) {
      this.waterFolder.add(water.material.uniforms.distortionScale, "value", 0, 8, 0.1).name(name + " Dist");
      this.waterFolder.add(water.material.uniforms.size, "value", 0.1, 10, 0.1).name(name + " Size");
      this.waterFolder.add(water.material.uniforms.alpha, "value", 0.8, 1, .001).name(name + " Alpha");
      this.waterFolder.add(water.material.uniforms.speed, "value", 0, 5, .01).name(name + " Speed");
    }
  }


  /**
   * Populates the debug gui with assets animations organizing them by asset prefix.
   * @param assetDescriptor The asset descriptor.
   * @param animationManager The animation manager instance.
   * @param animations The assets animations.
   */
  public pushAnimations(assetDescriptor: AssetDescriptor, animationManager: AnimationManager, animations: AnimationClip[]): void {
    const prefix: string = assetDescriptor.title[0];
    switch (prefix) {
      case "1": {
        this.pushAnimation(this.animationLidoTre, animationManager, assetDescriptor, animations);
        break;
      }
      case "2": {
        this.pushAnimation(this.animationLidoNico, animationManager, assetDescriptor, animations);
        break;
      }
      case "3": {
        this.pushAnimation(this.animationMalamocco, animationManager, assetDescriptor, animations);
        break;
      }
      case "4": {
        this.pushAnimation(this.animationChioggia, animationManager, assetDescriptor, animations);
        break;
      }
      default: {
        this.pushAnimation(this.animationsFolder, animationManager, assetDescriptor, animations);
        break;
      }
    }
  }

  /**
   * Add animations to the specified folder.
   * @param folder The debug gui folder.
   * @param animationManager The animation manager instance.
   * @param assetDescriptor The asset descriptor.
   * @param animations The animations to add.
   */
  public pushAnimation(folder: dat.GUI | undefined, animationManager: AnimationManager, assetDescriptor: AssetDescriptor, animations: AnimationClip[]): void {
    for (const animation of animations) {
      if (folder) {
        folder.add(this, "animationTimeTmp", 0, 1, 0.001)
          .onChange(() => animationManager.updateAnimation(assetDescriptor.id.toString(), this.animationTimeTmp.valueOf(), animation.name.toString()))
          .name(assetDescriptor.title + " " + animation.name);
      }
    }
  }

  /**
   * Displays a toast message on the bottom part of the screen. It closes automatically after 3 seconds.
   * @param message The message to be displayed.
   */
  public showToastMessage(message: string): void {
    const config: Viewer3DConfiguration = this.guiManager.getConfig();
    if (config.gui.isDebugGUIEnabled) {
      const toastMessage: HTMLDivElement = document.createElement("div");
      toastMessage.id = "snack-bar";
      toastMessage.innerText = message;
      toastMessage.className = "show";
      const container: HTMLElement = this.guiManager.getContainer();
      setTimeout(() => {
        toastMessage.className = toastMessage.className.replace("show", "");
        container.removeChild(toastMessage);
      }, 3000);
      container.appendChild(toastMessage);
    }
  }

  /**
   * Updates the loading bar on the top of the screen.
   * @param progress The progress value (from 0 to 1).
   */
  public updateLoadingBar(progress: number): void {
    this.loadingBarContainer.style.width = (progress * 100) + "%";
    this.loadingBarContainer.style.display = progress === 1 ? "none" : "block";
  }

  /** Gets the debug gui html container element. */
  public getDatGuiContainer(): HTMLElement {
    return this.datGuiContainer;
  }

  /** Gets the loading bar html container element. */
  public getLoadingBarContainer(): HTMLElement {
    return this.loadingBarContainer;
  }

  /** Toggles the axis helper at the origin of the scene. */
  private toggleAxisHelper(): void {
    if (!this.axesHelper.name) {
      this.sceneManager.getScene().add(this.axesHelper);
      this.axesHelper.name = "originAxesHelper";
    }
    else {
      this.axesHelper.visible = !this.axesHelper.visible;
    }
  }

  /** Saves the current view and adds the new saved view reference to the debug gui. */
  private saveCurrentView(): void {
    const name: string = this.viewManager.saveCurrentView();
    this.addViewToDatGUI(name);
  }

  /** Loads the scene. */
  private async loadScene(): Promise<void> {
    try {
      await this.sceneManager.getAssetLoader().loadSceneAsync();
      if (this.loadSceneItem)
        this.datGui.remove(this.loadSceneItem);
    }
    catch (error) {
      const errorMessage: string = ErrorUtils.GetErrorMessage(error);
      this.showToastMessage("Load scene failed: " + errorMessage);
    }
  }

  /**
   * Adds the view item to the dat gui.
   * @param name The view name to be displayed.
   */
  private addViewToDatGUI(name: string): void {
    if (this.viewsFolder)
      this.viewsFolder.add({ go: () => this.viewManager.loadViewFromName(name) }, "go").name(name);
  }

  /** Creates the debug gui html container container. */
  private createDebugGUIContainer(): HTMLElement {
    const datguiContainer: HTMLElement = document.createElement("div");
    datguiContainer.id = "dat-gui";
    return datguiContainer;
  }

  /** Creates the loading bar html container element. */
  private createLoadingBar(): HTMLElement {
    const loadingBarContainer: HTMLElement = document.createElement("div");
    loadingBarContainer.id = "loading-bar";
    return loadingBarContainer;
  }

}
