/** @module managers */
import {
  Scene, DirectionalLight, Vector3, AmbientLight, Texture, CubeTextureLoader, Camera, Vector2, Object3D,
  MathUtils as TMath, PerspectiveCamera, PlaneGeometry, MeshStandardMaterial, Mesh, BufferGeometry, Box3, BoxHelper, Box3Helper, Group
} from "three";
import { EnvConfig } from "../helpers/EnvConfig";
import { Raycaster, Intersection } from "three";
import { AnimationManager } from "./AnimationManager";
import { Water } from "../objects/Water";
import { Sky } from "../objects/Sky";
import { OrbitControls } from "../controls/OrbitControls";
import { AssetLoader } from "../loaders/AssetLoader";
import { PostProcessingManager } from "./PostProcessingManager";
import { ProxyBackendLegacy, ProxyBackend } from "../services/ProxyBackend";
import { AssetDescriptor } from "../models/Viewer3DProxy";
import { LoadedAssetDescriptor } from "../models/LoadedAssetDescriptor";
import { DynamicAssetManager } from "./DynamicAssetManager";
import { Viewer3DConfiguration } from "../models/Viewer3DConfiguration";
import { SetNavigationInputMessageParameters } from "../models/BusInputMessage";
import { SkyParameters } from "../models/SkyParameters";
import { AssetType, LoadedAsset } from "../models/LoadedAsset";
import { IFCManager, IFCModel } from "three/examples/jsm/loaders/IFCLoader";
import { ErrorUtils } from "../helpers/ErrorUtils";
import { log } from "../helpers/Logger";
import { BusBackend } from "../services/BusBackend";

/** This class is responsible for the scene management. It creates all the scene objects and populates the scene. */
export class SceneManager {

  /** The main scene. */
  private scene: Scene;
  /** The main camera. */
  private mainCamera: PerspectiveCamera;
  /** The directional light. */
  private directionalLight: DirectionalLight;
  /** The directional light offset. */
  private directionalLightOffset: Vector3;
  /** The directional night light. */
  private directionalNightLight: DirectionalLight;
  /** The ambient light. */
  private ambientLight: AmbientLight;
  /** The ambient night light. */
  private ambientNightLight: AmbientLight;
  /** The array holding the waters. */
  private waters: Water[] = [];
  /** The sky. */
  private sky: Sky;
  /** The sky parameters. */
  private skyParameters: SkyParameters;
  /** The main orbit controls. */
  private orbitControls!: OrbitControls;
  /** The raycaster. */
  private raycaster: Raycaster;
  /** The selected assets */
  private selectedAssets: string[] = [];
  /** The asset loader. */
  private assetLoader: AssetLoader;
  /** The environment map. */
  private envMap: Texture;
  /** The animation manager. */
  private animationManager: AnimationManager;
  /** The postprocessing manager. */
  private postProcessingManager!: PostProcessingManager;
  /** The dynamic asset manager */
  private dynamicAssetManager: DynamicAssetManager;

  /** The sun maximum intensity */
  private sunMaxIntensity: number = 1.5;
  /** The moon maximum intensity */
  private moonMaxIntensity: number = 0.75;
  /** The ambient light maximum intensity */
  private ambientMaxIntensity: number = 0.75;
  /** The ambient night light maximum intensity */
  private ambientNightMaxIntensity: number = 0.5;

  /** The material used for IFC models highlight. */
  private highlightMaterial: MeshStandardMaterial;

  /**
   * Creates a new instance.
   * @param container The viewer 3d html container element.
   * @param proxyBackend The proxy backend service.
   * @param busBackend The bus backend instance.
   * @param config The viewer configuration.
   */
  constructor(container: HTMLElement, proxyBackend: ProxyBackendLegacy | ProxyBackend, busBackend: BusBackend, config: Viewer3DConfiguration) {
    this.scene = new Scene();
    const aspectRatio: number = container.offsetWidth / container.offsetHeight;
    this.mainCamera = this.createMainCamera(aspectRatio);
    this.directionalLight = this.createDirectionalLight(this.sunMaxIntensity);
    this.directionalLightOffset = new Vector3();
    this.ambientLight = this.createAmbientLight(this.ambientMaxIntensity);
    this.ambientNightLight = this.createAmbientNightLight(0);
    this.directionalNightLight = this.createDirectionalNightLight(this.moonMaxIntensity);
    this.sky = this.createSky(this.directionalLightOffset);
    this.skyParameters = {
      distance: 1000,
      inclination: 0.7,
      azimuth: 0.75
    };
    this.raycaster = new Raycaster();
    this.raycaster.layers.mask = 1 << 0;
    this.envMap = new CubeTextureLoader()
      .setPath(EnvConfig.GetStaticFilesBaseUrl() + "textures/cube/")
      .load(["posx.jpg", "negx.jpg", "posy.jpg", "negy.jpg", "posz.jpg", "negz.jpg"]);
    this.highlightMaterial = new MeshStandardMaterial({ color: 0xff00ff, depthTest: false, transparent: true, opacity: 0.3 });
    this.assetLoader = new AssetLoader(this, proxyBackend, busBackend);
    this.animationManager = new AnimationManager();
    this.dynamicAssetManager = new DynamicAssetManager(config);
    this.dynamicAssetManager.setSceneManager(this);
    this.scene.add(this.directionalLight);
    this.scene.add(this.directionalNightLight);
    this.scene.add(this.directionalLight.target);
    this.scene.add(this.ambientLight);
    this.scene.add(this.ambientNightLight);
    this.scene.add(this.sky);
    this.scene.add(this.mainCamera);
    this.mainCamera.lookAt(new Vector3());

    // ADD UNDERWATER PLANE
    const planeGeometry: PlaneGeometry = new PlaneGeometry(100000, 100000);
    const planeMaterial: MeshStandardMaterial = new MeshStandardMaterial({ color: 0x786c5d, roughness: 1 });
    const plane: Mesh = new Mesh(planeGeometry, planeMaterial);
    plane.rotateX(-Math.PI / 2);
    this.scene.add(plane);
    plane.position.set(0, -50, 0);

    this.updateSkyWaterLight();
  }

  /**
   * Creates orbit controls.
   * @param canvas The main html canvas.
   */
  public createOrbitControls(canvas: HTMLCanvasElement): void {
    const orbitControls: OrbitControls = new OrbitControls(this.mainCamera, canvas, true);
    orbitControls.enableDamping = true;
    orbitControls.dampingFactor = 0.2;
    orbitControls.screenSpacePanning = false;
    orbitControls.minDistance = 80;
    orbitControls.maxDistance = 800;
    orbitControls.maxPolarAngle = Math.PI / 2.1;
    orbitControls.panSpeed = 0.2;
    orbitControls.rotateSpeed = 0.2;
    orbitControls.target = new Vector3(0, 0, 0);
    this.orbitControls = orbitControls;
  }

  /**
   * Sets the postprocessing manager.
   * @param manager The postprocessing manager.
   */
  public setPostProcessingManager(manager: PostProcessingManager): void {
    this.postProcessingManager = manager;
  }

  /** Toggle the waters visibility. */
  public toggleWaters(): void {
    for (const w of this.waters) {
      w.visible = !w.visible;
    }
  }

  /**
   * Converts a screen point to a world point.
   * @param camera The camera.
   * @param mouseCoord The mouse coordinates.
   */
  public screenToWorldPoint(camera: Camera, mouseCoord: Vector2): Vector3 {
    const raycaster: Raycaster = new Raycaster();
    raycaster.setFromCamera(mouseCoord, camera);
    const intersects: Intersection[] = raycaster.intersectObjects(this.scene.children, true);
    return intersects.length > 0 ? intersects[0].point : new Vector3();
  }

  /** Updates the sky and the waters lights. */
  public updateSkyWaterLight(): void {
    const theta: number = Math.PI * (this.skyParameters.inclination - 0.5);
    const phi: number = 2 * Math.PI * (this.skyParameters.azimuth - 0.5);
    this.directionalLightOffset.x = this.skyParameters.distance * Math.cos(phi);
    this.directionalLightOffset.y = this.skyParameters.distance * Math.sin(phi) * Math.sin(theta);
    this.directionalLightOffset.z = this.skyParameters.distance * Math.sin(phi) * Math.cos(theta);
    this.sky.material.uniforms.sunPosition.value = this.directionalLightOffset.copy(this.directionalLightOffset);
    for (const water of this.waters) {
      water.material.uniforms.sunDirection.value.copy(this.directionalLightOffset).normalize();
    }
    const sunIntensity: number = TMath.clamp(
      TMath.mapLinear(
        this.directionalLightOffset.dot(new Vector3(0, 1, 0)), -600, 600, 0, 1),
      0,
      1
    );
    this.updateAmbientLightIntensity(sunIntensity);
    this.updateAmbientNightLightIntensity(1 - sunIntensity);
    this.updateSunLightIntensity(sunIntensity);
    this.updateDirectionalNightLight(1 - sunIntensity);
  }

  /**
   * Updates the ambient light intensity.
   * @param intensity The light intensity.
   */
  public updateAmbientLightIntensity(intensity: number): void {
    this.ambientLight.intensity = Math.max(0.3, intensity * this.ambientMaxIntensity);
  }

  /**
   * Updates the ambient night light intensity.
   * @param intensity The light intensity.
   */
  public updateAmbientNightLightIntensity(intensity: number): void {
    this.ambientNightLight.intensity = Math.max(0.3, intensity * this.ambientNightMaxIntensity) * ((Math.abs(intensity * this.ambientNightMaxIntensity) > 0.3) ? 1 : 0);
  }

  /**
   * Updates the sun light intensity.
   * @param intensity The light intensity.
   */
  public updateSunLightIntensity(intensity: number): void {
    this.directionalLight.intensity = intensity * this.sunMaxIntensity;
  }

  /**
   * Updates the directional night light intensity.
   * @param intensity The light intensity.
   */
  public updateDirectionalNightLight(intensity: number): void {
    this.directionalNightLight.intensity = (intensity) * this.moonMaxIntensity * ((Math.abs(intensity) > 0.51) ? 1 : 0);
  }

  /**
   * Select the asset raycasting it from mouse coordinates.
   * @param mouseCoord The mouse coordinates.
   */
  public selectAssetFromMouseCoords(mouseCoord: Vector2): { assetDescriptor: AssetDescriptor | undefined, itemProps: any } {
    this.raycaster.setFromCamera(mouseCoord, this.mainCamera);
    const intersects: Intersection[] = this.raycaster.intersectObjects(this.scene.children, true);
    let assetDescriptor: AssetDescriptor | undefined;
    let itemProps: any;
    if (intersects.length > 0) {
      const intersection: Intersection = intersects[0];
      const selectedObject: Object3D = intersection.object;
      const assetId: string = selectedObject.userData.id;
      const value: LoadedAssetDescriptor | undefined = this.assetLoader.getAssetMap().get(assetId);
      if (value) {
        assetDescriptor = value.descriptor;
        itemProps = this.selectAssetsFromIds([assetId], true, intersection);
      }
    }
    return {
      assetDescriptor,
      itemProps
    };
  }

  /**
   * Highlight assets given their ids
   * @param assetIds The asset ids identfying the asset to be highlighted.
   * @param clear Determines whether to clear the existing selection before applying this selection.
   */
  public selectAssetsFromIds(assetIds: string[], clear?: boolean, intersection?: Intersection): any {
    if (clear) {
      this.clearAssetsHighlight();
    }
    let itemProps: any;
    for (const assetId of assetIds) {
      if (!this.selectedAssets.includes(assetId)) {
        const value: LoadedAssetDescriptor | undefined = this.assetLoader.getAssetMap().get(assetId);
        if (value) {
          const scene: Group = value.loadedAsset.getScene();
          if (value.loadedAsset.getType() === AssetType.IFC) {
            this.setIFCAssetHighlight(scene, true);
            if (intersection) {
              itemProps = this.setIFCAssetPartHighlight(intersection, scene);
            }
          }
          else {
            this.setAssetHighlight(scene, true);
          }
          this.selectedAssets.push(assetId);
        }
      }
    }
    return itemProps;
  }

  /** Clear the highlights on the currently selected assets. */
  public clearAssetsHighlight(): void {
    for (const assetId of this.selectedAssets) {
      const value: LoadedAssetDescriptor | undefined = this.assetLoader.getAssetMap().get(assetId);
      if (value) {
        const scene: Group = value.loadedAsset.getScene();
        if (value.loadedAsset.getType() === AssetType.IFC) {
          this.setIFCAssetHighlight(scene, false);
        }
        else {
          this.setAssetHighlight(value.loadedAsset.getScene(), false);
        }
      }
    }
    this.selectedAssets = [];
  }

  /** Gets the scene. */
  public getScene(): Scene {
    return this.scene;
  }

  /** Gets the main camera. */
  public getMainCamera(): PerspectiveCamera {
    return this.mainCamera;
  }

  /**
   * Sets the main camera view from navigation input message.
   * @param params The navigation input message params.
   */
  public setMainCameraViewFromEvent(params: SetNavigationInputMessageParameters): void {
    if (params.assetId) {
      const loadedAssetDescriptor: LoadedAssetDescriptor | undefined = this.assetLoader.getAssetMap().get(params.assetId);
      if (loadedAssetDescriptor) {
        let asset: Object3D = loadedAssetDescriptor.loadedAsset.getScene();
        if (loadedAssetDescriptor.descriptor.isDynamicAsset && asset.parent) {
          asset = asset.parent;
        }
        const direction: Vector3 = new Vector3();
        direction.subVectors(this.orbitControls.target, this.mainCamera.position);
        const newCameraPosition: Vector3 = new Vector3(asset.position.x - direction.x, this.mainCamera.position.y, asset.position.z - direction.z);
        this.setMainCameraView(newCameraPosition, asset.position);
        this.orbitControls.resetZoom();
      }
    }
    else {
      if (params.position != null) {
        this.setMainCameraView(params.position as Vector3, params.target as Vector3);
      }
    }
  }

  /**
   * Sets the main camera view parameters.
   * @param position The camera position.
   * @param target The camera target.
   */
  public setMainCameraView(position: Vector3, target?: Vector3): void {
    this.mainCamera.position.set(position.x, position.y, position.z);
    if (target) {
      this.orbitControls.target.set(target.x, target.y, target.z);
    }
  }

  /** Gets the directional light. */
  public getDirectionalLight(): DirectionalLight {
    return this.directionalLight;
  }

  /** Get the directional light offest. */
  public getDirectionalLightOffset(): Vector3 {
    return this.directionalLightOffset;
  }

  /** Get the water array. */
  public getWaters(): Water[] {
    return this.waters;
  }

  /**
   * Pushes a water into the water array.
   * @param water The water asset.
   */
  public pushWater(water: Water): void {
    this.waters.push(water);
  }

  /** Gets sky parameters. */
  public getSkyParameters(): SkyParameters {
    return this.skyParameters;
  }

  /** Gets main orbit controls. */
  public getOrbitControls(): OrbitControls {
    return this.orbitControls;
  }

  /** Gets the environment map. */
  public getEnvMap(): Texture {
    return this.envMap;
  }

  /** Gets the asset loader. */
  public getAssetLoader(): AssetLoader {
    return this.assetLoader;
  }

  /** Gets the animation manager. */
  public getAnimationManager(): AnimationManager {
    return this.animationManager;
  }

  /** Get the dynamic asset manager. */
  public getDynamicAssetManager(): DynamicAssetManager {
    return this.dynamicAssetManager;
  }

  /**
   * Updates the water speed.
   * @param speed The water speed.
   */
  public updateWaterSpeed(speed: number): void {
    this.waters.forEach((water) => {
      water.material.uniforms.speed.value = speed;
    }
    );
  }

  /**
   * Set the IFC asset highlight.
   * @param scene The loaded asset scene.
   * @param isHighlight Boolean flag to enable/disable asset highlight.
   */
  private setIFCAssetHighlight(scene: Group, isHighlight: boolean): void {
    if (isHighlight) {
      if (!scene.userData.highlight) {
        const boxHelper: BoxHelper = new BoxHelper(scene, 0xff00ff);
        scene.userData.highlight = boxHelper;
        this.scene.add(boxHelper);
      }
    }
    else {
      const ifcManager: IFCManager = this.assetLoader.getIfcLoader().ifcManager;
      const ifc: IFCModel = scene.children[0] as IFCModel;
      ifcManager.removeSubset(ifc.modelID, scene, this.highlightMaterial);
      if (scene.userData.highlight) {
        this.scene.remove(scene.userData.highlight);
        scene.userData.highlight.dispose();
        scene.userData.highlight = undefined;
      }
    }
  }

  /**
   * Set the IFC asset part highlight.
   * @param intersection The intersection object from raycaster.
   * @param scene The loaded asset scene.
   */
  private setIFCAssetPartHighlight(intersection: Intersection, scene: Group): any {
    let itemProps: any;
    const faceIndex: number | undefined = intersection.faceIndex;
    const foundObject: IFCModel = intersection.object as IFCModel;
    const geometry: BufferGeometry = foundObject.geometry;
    const modelId: number = foundObject.modelID;
    const ifcManager: IFCManager = this.assetLoader.getIfcLoader().ifcManager;
    if (faceIndex != null) {
      const id: number | undefined = ifcManager.getExpressId(geometry, faceIndex);
      if (id != null) {
        try {
          ifcManager.createSubset({
            modelID: modelId,
            ids: [id],
            scene,
            removePrevious: true,
            material: this.highlightMaterial
          });
          itemProps = ifcManager.getItemProperties(modelId, id);
        }
        catch (error) {
          const errorMessage: string = ErrorUtils.GetErrorMessage(error);
          log.warn(`Unable to create subset and get item properties: ` + errorMessage);
        }
      }
    }
    return itemProps;
  }

  /**
   * Set the asset highlight.
   * @param asset The asset.
   * @param isHighlight Boolean flag to enable/disable asset highlight.
   */
  private setAssetHighlight(asset: Object3D, isHighlight: boolean): void {
    const isPostProcessingEnabled: boolean = this.postProcessingManager.getIsPostProcessingEnabled();
    for (const child of asset.children) {
      switch (child.type) {
        case "Mesh":
          if (isHighlight)
            this.setObjectsHighlight(isPostProcessingEnabled, [child]);
          else
            this.unsetObjectsHighlight(isPostProcessingEnabled, [child]);
          break;
        case "Object3D":
        case "Group":
          this.setAssetHighlight(child, isHighlight);
          break;
      }
    }
  }

  /**
   * Sets assets highlights.
   * @param isPostProcessingEnabled If true uses outline (postprocessing) instead of highligh.
   * @param selectedAssets The selected assets.
   */
  private setObjectsHighlight(isPostProcessingEnabled: boolean, selectedAssets: any[]): void {
    for (const selectedAsset of selectedAssets) {
      if (isPostProcessingEnabled) {
        this.postProcessingManager.pushOutlineObject(selectedAsset);
      }
      else {
        if (selectedAsset.material && !Array.isArray(selectedAsset.material) && selectedAsset.material.emissive) {
          // selectedAsset.userData.currentHex = selectedAsset.material.emissive.getHex();
          selectedAsset.userData.oldMaterial = selectedAsset.material;
          selectedAsset.material = selectedAsset.material.clone();
          selectedAsset.material.emissive.setHex(0x005c99);
        }
      }
    }
  }

  /**
   * Unsets assets highlights.
   * @param isPostProcessingEnabled If true uses outline (postprocessing) instead of highligh.
   * @param selectedAssets The selected assets.
   */
  private unsetObjectsHighlight(isPostProcessingEnabled: boolean, selectedAssets: any[]): void {
    for (const selectedAsset of selectedAssets) {
      if (isPostProcessingEnabled) {
        this.postProcessingManager.removeOutlineObject(selectedAsset);
      }
      else {
        if (selectedAsset.material && selectedAsset.material.emissive) {
          // selectedAsset.material.emissive.setHex(selectedAsset.userData.currentHex);
          selectedAsset.material = selectedAsset.userData.oldMaterial;
          delete selectedAsset.userData.oldMaterial;
        }
      }
    }
  }

  /**
   * Creates the main camera.
   * @param aspectRatio The aspect ratio.
   */
  private createMainCamera(aspectRatio: number): PerspectiveCamera {
    const mainCamera: PerspectiveCamera = new PerspectiveCamera(60, aspectRatio, 10, 4000);
    mainCamera.position.set(0, 400, 600);
    mainCamera.layers.mask = ((1 << 0) | (1 << 1) | (1 << 2));
    mainCamera.name = "MainCamera";
    return mainCamera;
  }

  /**
   * Creates the directional light.
   * @param intensity The light intensity.
   */
  private createDirectionalLight(intensity: number): DirectionalLight {
    const light: DirectionalLight = new DirectionalLight(0xffffff, intensity);
    light.castShadow = true;
    light.shadow.bias = -0.0005;
    light.shadow.mapSize.width = 1024 * 8;
    light.shadow.mapSize.height = 1024 * 8;
    light.shadow.camera.near = 200;
    light.shadow.camera.far = 2500;
    light.shadow.camera.left = -500;
    light.shadow.camera.right = 500;
    light.shadow.camera.top = 500;
    light.shadow.camera.bottom = -500;
    return light;
  }

  /**
   * Creates the directional night light.
   * @param intensity The light intensity.
   */
  private createDirectionalNightLight(intensity: number): DirectionalLight {
    const light: DirectionalLight = new DirectionalLight(0x003388, intensity);
    light.castShadow = true;
    light.shadow.bias = -0.0005;
    light.shadow.mapSize.width = 1024 * 8;
    light.shadow.mapSize.height = 1024 * 8;
    light.shadow.camera.near = 200;
    light.shadow.camera.far = 2500;
    light.shadow.camera.left = -500;
    light.shadow.camera.right = 500;
    light.shadow.camera.top = 500;
    light.shadow.camera.bottom = -500;
    return light;
  }

  /**
   * Creates the ambient light.
   * @param intensity The light intensity.
   */
  private createAmbientLight(intensity: number): AmbientLight {
    const light: AmbientLight = new AmbientLight(0xffffff, intensity);
    return light;
  }

  /**
   * Creates the ambient night light.
   * @param intensity The light intensity.
   */
  private createAmbientNightLight(intensity: number): AmbientLight {
    const light: AmbientLight = new AmbientLight(0x003388, intensity);
    return light;
  }

  /**
   * Creates the sky.
   * @param directionalLightOffset The directional light offset.
   */
  private createSky(directionalLightOffset: Vector3): Sky {
    const sky: Sky = new Sky();
    sky.scale.setScalar(500000);
    const uniforms: any = sky.material.uniforms;
    uniforms.turbidity.value = 10;
    uniforms.rayleigh.value = 2;
    uniforms.luminance.value = 1;
    uniforms.mieCoefficient.value = 0.005;
    uniforms.mieDirectionalG.value = 0.8;
    uniforms.sunPosition = directionalLightOffset;
    sky.layers.set(1);
    return sky;
  }

}
