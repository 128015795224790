/** @module root */
import { EnvConfig } from "./helpers/EnvConfig";
import { log, Logger } from "./helpers/Logger";
import { Viewer3DConfiguration } from "./models/Viewer3DConfiguration";
import { GUIManager } from "./managers/GUIManager";
import { SceneManager } from "./managers/SceneManager";
import { RenderManager } from "./managers/RenderManager";
import { PostProcessingManager } from "./managers/PostProcessingManager";
import { ViewManager } from "./managers/ViewManager";
import { EventManager } from "./managers/EventManager";
import { SignalRBackend } from "./services/SignalRBackend";
import { ProxyBackend, ProxyBackendLegacy } from "./services/ProxyBackend";
import { BusBackend } from "./services/BusBackend";
import { Viewer3DProxy, Viewer3DProxyLegacy } from "./models/Viewer3DProxy";
import { GPSUtils } from "./helpers/GPSUtils";


/** This is the main class, it is responsible for the instantiation of the main modules like managers and services and for wiring dependencies. */

export class Viewer3D {

  /** The total number of Viewer3D instances created, it is incremented at instance creation and used for the instance identification. */
  private static InstanceCount: number = 0;
  /** The version of this Viewer3D instance. */
  private version: string = EnvConfig.Version;

  /**
   * The constructor allows to create a Viewer3D instance.
   * @param config - The configuration of the Viewer3D instance.
   */
  constructor(config: Viewer3DConfiguration) {
    Viewer3D.InstanceCount++;
    Logger.SetLogLevel(config.logLevel);
    GPSUtils.InitSceneOrigin(config.sceneOrigin);
    log.debug(`Viewer3D ${this.version}, instance id: ${Viewer3D.InstanceCount}, ${EnvConfig.NodeEnv} mode`);

    if (config.staticFilesPath)
      EnvConfig.StaticFilesPath = config.staticFilesPath;
    if (config.signalRUrl)
      EnvConfig.SignalRUrl = config.signalRUrl;

    const guiManager: GUIManager = new GUIManager(config);
    const proxyBackend: ProxyBackendLegacy | ProxyBackend = config.services.useLegacyProxy ?
      new ProxyBackendLegacy(config.services.proxy as Viewer3DProxyLegacy)
      : new ProxyBackend(config.services.proxy as Viewer3DProxy);
    const busBackend: BusBackend = new BusBackend(Viewer3D.InstanceCount, config.services.bus);
    const container: HTMLElement = guiManager.getContainer();

    const sceneManager: SceneManager = new SceneManager(container, proxyBackend, busBackend, config);
    const viewManager: ViewManager = new ViewManager(sceneManager);
    const renderManager: RenderManager = new RenderManager(container, sceneManager, guiManager);
    const postProcessingManager: PostProcessingManager = new PostProcessingManager(sceneManager, renderManager);
    const eventManager: EventManager = new EventManager(Viewer3D.InstanceCount, guiManager, busBackend, renderManager, sceneManager);
    const signalrBackend: SignalRBackend = new SignalRBackend(eventManager.getSignalRMessageHandler(), busBackend);

    sceneManager.setPostProcessingManager(postProcessingManager);
    sceneManager.createOrbitControls(renderManager.getWebGLRenderer().domElement);
    sceneManager.getAssetLoader().setDebugGUIManager(guiManager.getdebugGUIManager());

    renderManager.setPostProcessingManager(postProcessingManager);

    guiManager.setSceneManager(sceneManager);
    guiManager.setRenderManager(renderManager);
    guiManager.setBusBackend(busBackend);
    guiManager.setPostProcessingManager(postProcessingManager);
    guiManager.getMinimapManager().createOrbitControls(sceneManager.getOrbitControls().target);
    sceneManager.getScene().add(guiManager.getMinimapManager().createViewCone(sceneManager.getMainCamera()));
    guiManager.getMarkerManager().setRenderManager(renderManager);
    guiManager.getDebugGuiManager().setViewManager(viewManager);
    guiManager.getDebugGuiManager().setEventManager(eventManager);
    guiManager.getDebugGuiManager().addParams(sceneManager, signalrBackend, postProcessingManager, renderManager);
    guiManager.addEventListeners();
    guiManager.getMinimapManager().addEventListeners();

    eventManager.subscribeMainCamera(config.gui.navigationEventsSampleTime, sceneManager.getOrbitControls().getCameraAsObservable());

    busBackend.subscribe(eventManager.getBusMessageHandler(), eventManager.getBusErrorHandler(), eventManager.getBusCompletedHandler());

    renderManager.setRenderingEnabled(true);

    if (config.gui.isAutoLoadScene) {
      sceneManager.getAssetLoader().loadSceneAsync();
    }
  }

}
