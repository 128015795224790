/** @module managers */
import { Object3D, WebGLRenderTarget, WebGLRenderer, Scene, Camera, Color, Vector2, RGBAFormat, WebGLRenderTargetOptions } from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { RenderManager } from "./RenderManager";
import { SceneManager } from "./SceneManager";


/** This class is responsible for the postprocessing. */
export class PostProcessingManager {

  /** The render manager instance. */
  private renderManager: RenderManager;
  /** The scene manager instance. */
  private sceneManager: SceneManager;
  /** The effects composer. */
  private composer: EffectComposer;
  /** The selected objects. */
  private selectedObjects: Object3D[] = [];
  /** The render pass. */
  private renderPass: RenderPass;
  /** The outline pass. */
  private outlinePass: OutlinePass;
  /** Determines whether the postprocessing is enabled. */
  private isPostProcessingEnabled: boolean;
  /** The webgl multisample render target. */
  private renderTarget: WebGLRenderTarget;

  /**
   * Creates a new instance.
   * @param sceneManager The scene manager.
   * @param renderManager The render manager.
   */
  constructor(sceneManager: SceneManager, renderManager: RenderManager) {
    this.isPostProcessingEnabled = false;
    this.renderManager = renderManager;
    this.sceneManager = sceneManager;
    const webGLRenderer: WebGLRenderer = this.renderManager.getWebGLRenderer();
    const scene: Scene = this.sceneManager.getScene();
    const mainCamera: Camera = this.sceneManager.getMainCamera();
    const parameters: WebGLRenderTargetOptions = {
      format: RGBAFormat,
      stencilBuffer: false
    };
    this.renderTarget = new WebGLRenderTarget(webGLRenderer.domElement.offsetWidth, webGLRenderer.domElement.offsetHeight, parameters);
    this.renderTarget.samples = 4;
    this.composer = new EffectComposer(webGLRenderer, this.renderTarget);
    this.renderPass = new RenderPass(scene, mainCamera);
    this.outlinePass = new OutlinePass(new Vector2(256, 256), scene, mainCamera);
    this.outlinePass.pulsePeriod = 2;
    this.outlinePass.edgeStrength = 5;
    this.outlinePass.edgeGlow = 1;
    this.outlinePass.edgeThickness = 1;
    this.outlinePass.visibleEdgeColor = new Color(0x005c99);
    this.outlinePass.hiddenEdgeColor = new Color(0x005c99);
    this.composer.addPass(this.renderPass);
    this.composer.addPass(this.outlinePass);
    this.outlinePass.selectedObjects = this.selectedObjects;
    this.updatePassesSize();
  }

  /** Gets the effect composer. */
  public getComposer(): EffectComposer {
    return this.composer;
  }

  /** Gets the outline pass. */
  public getOutlinePass(): OutlinePass {
    return this.outlinePass;
  }

  /** Gets the enabled state of the postprocessing. */
  public getIsPostProcessingEnabled(): boolean {
    return this.isPostProcessingEnabled;
  }

  /** Toggles the enabled state of the postprocessing. */
  public togglePostProcessingEnabled(): void {
    this.isPostProcessingEnabled = !this.isPostProcessingEnabled;
  }

  /** Updates passes size */
  public updatePassesSize(): void {
    const webGLRenderer: WebGLRenderer = this.renderManager.getWebGLRenderer();
    const pixelRatio: number = window.devicePixelRatio;
    this.outlinePass.setSize(webGLRenderer.domElement.offsetWidth * pixelRatio, webGLRenderer.domElement.offsetHeight * pixelRatio);
    this.composer.setSize(webGLRenderer.domElement.offsetWidth * pixelRatio, webGLRenderer.domElement.offsetHeight * pixelRatio);
  }

  /**
   * Pushes the object to the selected objects array (used for the outline).
   * @param obj The object.
   */
  public pushOutlineObject(obj: Object3D): void {
    this.selectedObjects.push(obj);
  }

  /**
   * Removes the object from the selected objects array (used for the outline).
   * @param obj The object.
   */
  public removeOutlineObject(obj: Object3D): void {
    this.selectedObjects.splice(this.selectedObjects.indexOf(obj));
  }
}
