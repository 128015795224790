/** @module models */

/** This interface models the serialized SignalR bus input message. */
export interface SignalRInputSerializedMessage {
  /** The serialized message type. */
  type: string;
  /** The serialized message parameters. */
  params: string;
  /** The viewer instance id for which this message is intended. */
  instanceId: number | number[];
}

/** This interface models the SignalR bus input message. */
export interface SignalRInputMessage {
  /** The message type. */
  type: SignalRInputType;
  /** The message parameters. */
  params: SetAnimationValueInputMessageParameters;
}

/** This enum lists all the SignalR message types. */
export enum SignalRInputType {
  /** Input type for setting an animation value. */
  SetAnimationValue = "SetAnimationValue",
  /** Input type for setting the location of a dynamic asset instance. */
  SetDynamicAssetsLocation = "SetDynamicAssetsLocation",
  /** Input type for adding a dynamic asset instance. */
  SetDynamicAssetsAdd = "SetDynamicAssetsAdd",
  /** Input type for removing a dynamic asset instance. */
  SetDynamicAssetsRemove = "SetDynamicAssetsRemove"
}


/** Models the set animation input message. */
export class SetAnimationValueInputMessage implements SignalRInputMessage {
  /** The message type. */
  public type: SignalRInputType = SignalRInputType.SetAnimationValue;
  /** The message parameters. */
  public params: SetAnimationValueInputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The set animation message parameters.
   */
  constructor(parameters: SetAnimationValueInputMessageParameters) {
    this.params = parameters;
  }
}

/** This interface models the set animation input message parameters */
export interface SetAnimationValueInputMessageParameters {
  /** The asset id. */
  assetId: string;
  /** The asset value. */
  value: number;
  /** Optional animation id. */
  animationId?: string;
}

/** This interface models the set dynamic asset location input message parameters */
export interface SetDynamicAssetsLocationInputMessageParameters {
  /** The id of the dynamic asset. */
  id: string;
  /** The latitude of the dynamic asset. */
  lat: number;
  /** The longitude of the dynamic asset. */
  lon: number;
  /** The direction (heading) of the dynamic asset. */
  dir: number;
  /** The timestamp of the event (optional). */
  time?: number;
}

/** This interface models the set dynamic asset add input message parameters */
export interface SetDynamicAssetsAddInputMessageParameters {
  /** The id of the dynamic asset. */
  id: string;
  /** The dynamic asset 3D model (optional). */
  template?: string;
  /** The dynamic asset length in meters (optional). */
  length?: number;
  /** The dynamic asset width in meters (optional). */
  width?: number;
  /** The distance of the instrumentation point from the front of the dynamic asset, in meters (optional). */
  pivotFrontDistance?: number;
  /** The distance of the instrumentation point from the left of the dynamic asset, in meters (optional). */
  pivotLeftDistance?: number;
  /** The latitude of the dynamic asset (optional). */
  lat?: number;
  /** The longitude of the dynamic asset (optional). */
  lon?: number;
  /** The direction (heading) of the dynamic asset (optional). */
  dir?: number;
  /** The timestamp of the event (optional). */
  time?: number;
}

/** This interface models the set dynamic asset remove input message parameters */
export interface SetDynamicAssetsRemoveInputMessageParameters {
  /** The id of the dynamic asset. */
  id: string;
}
