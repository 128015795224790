/** @module models */

import { SetDynamicAssetsLocationInputMessageParameters, SetDynamicAssetsAddInputMessageParameters, SetDynamicAssetsRemoveInputMessageParameters } from "./SignalRInputMessage";

/** This interface models the serialized bus input message. */
export interface BusInputSerializedMessage {
  /** The requested method. */
  method: string;
  /** The serialized request parameters. */
  params: string;
  /** The viewer instance for which this message is intended. */
  instanceId: number | number[];
}

/** This interface models the bus input message. */
export interface BusInputMessage {
  /** The requested method. */
  method: BusInputMethod;
  /** The request parameters. */
  params: SetViewerEnabledInputMessageParameters | SetNavigationInputMessageParameters | PlaySoundInputMessageParameters |
  SetMarkersInputMessageParameters | SetSelectedAssetsInputMessageParameters | StopSoundInputMessageParameters | SetWaterSpeedInputMessageParameters |
  SetDynamicAssetsVisibilityInputMessageParameters | SetDynamicAssetsAddInputMessageParameters[] | SetDynamicAssetsRemoveInputMessageParameters[] |
  SetDynamicAssetsLocationInputMessageParameters[];
}

/** This enum lists all the input method names. */
export enum BusInputMethod {
  /** Input method name for enabling/disabling rendering and controls. */
  SetViewerEnabled = "SetViewerEnabled",
  /** Input method name for setting the navigation. */
  SetNavigation = "SetNavigation",
  /** Input method name for setting selected assets. */
  SetSelectedAssets = "SetSelectedAssets",
  /** Input method name for setting markers. */
  SetMarkers = "SetMarkers",
  /** Input method name for playing a sound. */
  PlaySound = "PlaySound",
  /** Input method name for stopping a sound. */
  StopSound = "StopSound",
  /** Input method name for set animation. */
  SetAnimationValue = "SetAnimationValue",
  /** Input method name for setting water speed */
  SetWaterSpeed = "SetWaterSpeed",
  /** Input method name for setting the visibility of dynamic asset instances. */
  SetDynamicAssetsVisibility = "SetDynamicAssetsVisibility",
  /** Input type for setting the location of a dynamic asset instance. */
  SetDynamicAssetsLocation = "SetDynamicAssetsLocation",
  /** Input type for adding a dynamic asset instance. */
  SetDynamicAssetsAdd = "SetDynamicAssetsAdd",
  /** Input type for removing a dynamic asset instance. */
  SetDynamicAssetsRemove = "SetDynamicAssetsRemove"
}


/** Models the set viewer enabled input message. */
export class SetViewerEnabledInputMessage implements BusInputMessage {
  /** The method name. */
  public method: BusInputMethod = BusInputMethod.SetViewerEnabled;
  /** The message parameters. */
  public params: SetViewerEnabledInputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The set viewer enabled message parameters.
   */
  constructor(parameters: SetViewerEnabledInputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the set viewer enabled input message parameters. */
export interface SetViewerEnabledInputMessageParameters {
  /** Enable or disable main, minimap rendering and controls. */
  isEnabled: boolean;
}


/** Models the set selected assets input message. */
export class SetSelectedAssetsInputMessage implements BusInputMessage {
  /** The method name. */
  public method: BusInputMethod = BusInputMethod.SetSelectedAssets;
  /** The message parameters. */
  public params: SetSelectedAssetsInputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The set selected assets message parameters.
   */
  constructor(parameters: SetSelectedAssetsInputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the set selected assets input message parameters. */
export interface SetSelectedAssetsInputMessageParameters {
  /** The list of assets to be selected. */
  ids: string[];
  /** If the existing selection must be cleared before applying the new selection. */
  clear: boolean;
}


/** Models the set navigation input message. */
export class SetNavigationInputMessage implements BusInputMessage {
  /** The method name. */
  public method: BusInputMethod = BusInputMethod.SetNavigation;
  /** The message parameters. */
  public params: SetNavigationInputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The set navigation message parameters.
   */
  constructor(parameters: SetNavigationInputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the set navigation input message parameters. */
export interface SetNavigationInputMessageParameters {
  /** The asset id */
  assetId?: string;
  /** The camera position. */
  position?: { x: number, y: number, z: number };
  /** The optional camera target. */
  target?: { x: number, y: number, z: number };
}


/** Models the set markers input message. */
export class SetMarkersInputMessage implements BusInputMessage {
  /** The method name. */
  public method: BusInputMethod = BusInputMethod.SetMarkers;
  /** The message parameters. */
  public params: SetMarkersInputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The set markers message parameters.
   */
  constructor(parameters: SetMarkersInputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the marker input message parameters. */
export interface SetMarkersInputMessageParameters {
  /** The list of markers. */
  markers: MarkerMessage[];
}

/** Models the marker input message. */
export interface MarkerMessage {
  /** The marker id. */
  markerId: string;
  /** The marker icon. */
  icon?: string;
  /** The marker position. */
  position?: { x: number, y: number, z: number };
  /** The marker associated asset id. */
  assetId?: string;
  /** The id of an html element to load into the balloon. */
  balloonDivId?: string;
  /** Shows the balloon upon marker creation. */
  isBalloonVisible?: boolean;
}


/** Models the play sound input message. */
export class PlaySoundInputMessage implements BusInputMessage {
  /** The method name. */
  public method: BusInputMethod = BusInputMethod.PlaySound;
  /** The message parameters. */
  public params: PlaySoundInputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The play sound message parameters.
   */
  constructor(parameters: PlaySoundInputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the play sound input message parameters. */
export interface PlaySoundInputMessageParameters {
  /** The id of the sound to be played. */
  id: string;
  /** Whether the sound has to be looped. */
  loop: boolean;
}


/** Models the stop sound input message. */
export class StopSoundInputMessage implements BusInputMessage {
  /** The method name. */
  public method: BusInputMethod = BusInputMethod.StopSound;
  /** The message parameters. */
  public params: StopSoundInputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The stop sound message parameters.
   */
  constructor(parameters: StopSoundInputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the stop sound input message parameters. */
export interface StopSoundInputMessageParameters {
  /** The id of the sound to be stopped. */
  id: string;
}

export interface SetWaterSpeedInputMessageParameters {
  /** Water speed */
  speed: number;
}

/** This interface models the set dynamic assets visibility input message parameters */
export interface SetDynamicAssetsVisibilityInputMessageParameters {
  /** If the event will show or hide the dynamic assets. */
  show: boolean;
  /** If the event will affect all the dynamic assets. */
  all: boolean;
  /** The id of the dynamic asset to show or hide, used only when the all property is false. */
  id: string;
}
