/** @module models */

/** This interface models the bus output message. */
export interface BusOutputMessage {
  /** The requested method. */
  method: BusOutputMethod;
  /** The request parameters. */
  params: AssetSelectedOutputMessageParameters | MarkerSelectedOutputMessageParameters | NavigationUpdatedOutputMessageParameters |
  SignalRConnUpdatedOutputMessageParameters | ErrorOutputMessageParameters | SceneLoadedOutputMessageParameters;
}

/** This interface models the serialized bus output message. */
export interface BusOutputSerializedMessage {
  /** The serialized requested method. */
  method: string;
  /** The serialized request parameters. */
  params: string;
  /** The sender viewer instance id. */
  instanceId: number;
}

/** This enum lists all the output method names. */
export enum BusOutputMethod {
  /** Output method name for scene loaded. */
  SceneLoaded = "SceneLoaded",
  /** Output method name for asset selection. */
  AssetSelected = "AssetSelected",
  /** Output method name for marker selection. */
  MarkerSelected = "MarkerSelected",
  /** Output method name for navigation updates. */
  NavigationUpdated = "NavigationUpdated",
  /** Output method name for signalr connection state updates. */
  SignalRConnUpdated = "SignalRConnUpdated",
  /** Output method name for error related activites. */
  ErrorMessage = "ErrorMessage"
}

/** Models the scene loaded output message. */
export class SceneLoadedOutputMessage implements BusOutputMessage {
  /** The method name. */
  public method: BusOutputMethod = BusOutputMethod.SceneLoaded;
  /** The message parameters. */
  public params: SceneLoadedOutputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The scene loaded message parameters.
   */
  constructor(parameters: SceneLoadedOutputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the scene loaded output message parameters. */
export class SceneLoadedOutputMessageParameters { }

/** Models the asset selected output message. */
export class AssetSelectedOutputMessage implements BusOutputMessage {
  /** The method name. */
  public method: BusOutputMethod = BusOutputMethod.AssetSelected;
  /** The message parameters. */
  public params: AssetSelectedOutputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The asset selected message parameters.
   */
  constructor(parameters: AssetSelectedOutputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the asset selected output message parameters. */
export interface AssetSelectedOutputMessageParameters {
  /** The asset id. */
  id: string;
  /** The asset title. */
  title: string;
  /** Determines if the asset is a dynamic asset instance. */
  isDynamicAsset?: boolean;
  /** If the item is an IFC model returns the IFC data of the selected part. Only on click selection. */
  itemProps?: any;
}


/** Models the marker selected message. */
export class MarkerSelectedOutputMessage implements BusOutputMessage {
  /** The method name. */
  public method: BusOutputMethod = BusOutputMethod.MarkerSelected;
  /** The message parameters. */
  public params: MarkerSelectedOutputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The marker selected message parameters.
   */
  constructor(parameters: MarkerSelectedOutputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the marker output message parameters. */
export interface MarkerSelectedOutputMessageParameters {
  /** The marker id. */
  id: string;
  /** The action performed on the marker. */
  action: "clicked" | "entered" | "exited";
  /** The marker position. */
  position?: { x: number, y: number, z: number };
  /** The asset id associated with marker. */
  assetId?: string;
}


/** Models the navigation output message. */
export class NavigationUpdatedOutputMessage implements BusOutputMessage {
  /** The method name. */
  public method: BusOutputMethod = BusOutputMethod.NavigationUpdated;
  /** The message parameters. */
  public params: NavigationUpdatedOutputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The navigation message parameters.
   */
  constructor(parameters: NavigationUpdatedOutputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the navigation output message parameters. */
export interface NavigationUpdatedOutputMessageParameters {
  /** The previous navigation data. */
  previous: NavigationUpdatedOutputMessageParameter;
  /** The current current data. */
  current: NavigationUpdatedOutputMessageParameter;
}

/** Models the navigation output message parameter. */
export interface NavigationUpdatedOutputMessageParameter {
  /** The camera frustum far plane. */
  farPlane: {
    /** The bottom-left origin coordinate of the plane. */
    f1: { x: number, y: number, z: number },
    /** The top-left origin coordinate of the plane. */
    f2: { x: number, y: number, z: number },
    /** The top-right origin coordinate of the plane. */
    f3: { x: number, y: number, z: number },
    /** The bottom-left origin coordinate of the plane. */
    f4: { x: number, y: number, z: number }
  };
  /** The camera position. */
  position: { x: number, y: number, z: number };
  /** The camera direction. */
  direction: { x: number, y: number, z: number };
  /** The camera target */
  target: { x: number, y: number, z: number };
}


/** Models the signalr output message. */
export class SignalRConnUpdatedOutputMessage implements BusOutputMessage {
  /** The method name. */
  public method: BusOutputMethod = BusOutputMethod.SignalRConnUpdated;
  /** The message parameters. */
  public params: SignalRConnUpdatedOutputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The signalr message parameters.
   */
  constructor(parameters: SignalRConnUpdatedOutputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the signalr output message parameter. */
export interface SignalRConnUpdatedOutputMessageParameters {
  /** The signalr hub connection state. */
  isConnected: boolean;
}


/** Models the error output message. */
export class ErrorOutputMessage implements BusOutputMessage {
  /** The method name. */
  public method: BusOutputMethod = BusOutputMethod.ErrorMessage;
  /** The message parameters. */
  public params: ErrorOutputMessageParameters;
  /**
   * Creates a new instance.
   * @param parameters The error message parameters.
   */
  constructor(parameters: ErrorOutputMessageParameters) {
    this.params = parameters;
  }
}

/** Models the error output message parameter. */
export interface ErrorOutputMessageParameters {
  /** The error message. */
  message: string;
}

