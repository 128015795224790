/** @module gui */
import { Vector3, Vector2 } from "three";
import { MarkerManager } from "../managers/MarkerManager";
import { MarkerMessage } from "../models/BusInputMessage";
import { log } from "../helpers/Logger";

/** This class is responsible for the managment of a Marker gui element. */
export class Marker {

  /** The marker manager. */
  private markerManager: MarkerManager;
  /** The marker message parameter. */
  private markerMessage: MarkerMessage;
  /** The marker html container element. */
  private container: HTMLElement;
  /** The marker html element. */
  private marker: HTMLElement;
  /** The label html container element. */
  private label: HTMLElement;
  /** The balloon html element. */
  private balloon?: HTMLElement;
  /** The balloon minimize button html element. */
  private balloonMinimizeButton?: HTMLElement;
  /** The balloon minimized icon html element. */
  private balloonMinimizedIcon?: HTMLElement;
  /** The parent element of current balloon content */
  private currentBalloonContentParent: HTMLElement | null = null;
  /** The current balloon content */
  private currentBalloonContent: HTMLElement | null = null;


  /**
   * Creates a new instance.
   * @param markerManager The marker manager.
   * @param sceneManager The scene manager.
   * @param busBackend The bus backend sevice.
   * @param markerMessage The marker message parameter.
   */
  constructor(markerManager: MarkerManager, /*sceneManager: SceneManager, busBackend: BusBackend,*/ markerMessage: MarkerMessage) {
    this.markerManager = markerManager;
    this.markerMessage = markerMessage;
    if (!this.markerMessage.position) {
      throw new Error(`Marker position is not defined`);
    }
    this.container = document.createElement("div");
    this.container.className = "label label-obj";
    this.container.style.pointerEvents = "none";

    this.marker = document.createElement("i");
    this.marker.className = "label " + this.markerMessage.icon;
    this.marker.style.overflow = "hidden";
    this.marker.style.pointerEvents = "all";

    this.label = document.createElement("span");
    this.label.className = "span-label";
    this.label.innerHTML = this.markerMessage.assetId || "no asset";
    this.label.style.display = "none";
    this.label.style.zIndex = "50";
    this.label.style.pointerEvents = "all";

    this.container.appendChild(this.label);
    this.container.appendChild(this.marker);

    if (this.markerMessage.balloonDivId) {
      this.balloon = document.createElement("div");
      this.balloon.className = "balloon-container";
      this.balloon.style.pointerEvents = "all";
      this.balloon.style.display = "none";

      this.balloonMinimizeButton = document.createElement("i");
      this.balloonMinimizeButton.className = "far fa-window-minimize balloon-minimize-button";
      this.balloonMinimizeButton.style.pointerEvents = "all";
      this.balloonMinimizeButton.onclick = () => this.toggleBalloon();

      this.balloonMinimizedIcon = document.createElement("i");
      this.balloonMinimizedIcon.className = "fas balloon-minimized-icon fa-comment-dots";
      this.balloonMinimizedIcon.style.pointerEvents = "all";
      this.balloonMinimizedIcon.onclick = () => this.toggleBalloon();

      this.setBalloonContent(this.markerMessage.balloonDivId);
      if (this.markerMessage.isBalloonVisible) {
        this.balloon.style.display = "block";
        this.balloonMinimizedIcon.style.display = "none";
      }
      else {
        this.balloon.style.display = "none";
        this.balloonMinimizedIcon.style.display = "block";
      }

      this.balloon.appendChild(this.balloonMinimizeButton);
      this.container.appendChild(this.balloon);
      this.marker.appendChild(this.balloonMinimizedIcon);
      this.balloon.addEventListener("click", this.balloonClickHandler.bind(this));
    }
    this.marker.addEventListener("click", this.clickHandler.bind(this));
    this.marker.addEventListener("mouseenter", this.mouseEnterHandler.bind(this));
    this.marker.addEventListener("mouseout", this.mouseOutHandler.bind(this));
  }

  /** Gets the marker html container element. */
  public getContainer(): HTMLElement {
    return this.container;
  }

  /** Updates the marker position and style. */
  public update(): void {
    const screenPosition: Vector2 | null = this.markerManager.getMarkerScreenPosition(this.markerMessage.position as Vector3);
    if (screenPosition) {
      this.container.style.display = "block";
      this.container.style.left = screenPosition.x + "px";
      this.container.style.top = screenPosition.y + "px";
    }
    else {
      this.container.style.display = "none";
    }
  }

  /**
   * Sets the balloon content by injecting some external html content that is found on document.
   * @param htmlElementId The id of the html element to be injected into the balloon.
   */
  public setBalloonContent(htmlElementId: string): void {
    const contentDiv: HTMLElement | null = document.getElementById(htmlElementId);
    if (contentDiv && this.balloon) {
      this.removeBalloonContent();
      this.currentBalloonContentParent = contentDiv.parentElement;
      contentDiv.style.display = "block";
      this.currentBalloonContent = this.balloon.insertBefore(contentDiv, this.balloon.firstChild);
    }
    else {
      log.warn(`Unable to create ballon content from html element with id ${this.markerMessage.balloonDivId}`);
    }
  }

  /** Removes the balloon content. */
  public removeBalloonContent(): void {
    if (this.currentBalloonContent && this.balloon) {
      this.currentBalloonContent.style.display = "none";
      if (this.currentBalloonContentParent)
        this.currentBalloonContentParent.appendChild(this.currentBalloonContent);
      else
        this.balloon.removeChild(this.currentBalloonContent);
      this.currentBalloonContentParent = null;
      this.currentBalloonContent = null;
    }
  }

  /** Closes the balloon. */
  public closeBalloon(): void {
    if (this.balloon) {
      this.balloon.style.display = "none";
      this.removeBalloonContent();
    }
  }

  /** Minimizes the balloon. */
  public minimizeBalloon(): void {
    if (this.currentBalloonContent) {
      if (this.balloon && this.balloon.style)
        this.balloon.style.display = "none";
      if (this.balloonMinimizedIcon && this.balloonMinimizedIcon.style)
        this.balloonMinimizedIcon.style.display = "block";
    }
  }

  /** Maximizes the balloon. */
  public maximizeBalloon(): void {
    if (this.currentBalloonContent) {
      if (this.balloon && this.balloon.style)
        this.balloon.style.display = "block";
      if (this.balloonMinimizedIcon && this.balloonMinimizedIcon.style)
        this.balloonMinimizedIcon.style.display = "none";
    }
  }

  /** Toggles the balloon minimize/maximize */
  public toggleBalloon(): void {
    if (this.currentBalloonContent) {
      if (this.balloon && this.balloon.style) {
        if (this.balloon.style.display === "none")
          this.maximizeBalloon();
        else
          this.minimizeBalloon();
      }
    }
  }

  /** The marker balloon click handler. */
  private balloonClickHandler(ev: MouseEvent): void {
    this.markerManager.newMarkerInFocus(this);
  }

  /** The marker click handler function. */
  private clickHandler(): void {
    if (this.balloon && this.markerMessage.balloonDivId) {
      this.markerManager.publishMarkerMessage(this.markerMessage, "clicked");
    }
  }

  /** The marker mouse enter handler function. */
  private mouseEnterHandler(): void {
    this.markerManager.publishMarkerMessage(this.markerMessage, "entered");
  }

  /** The marker mouse out handler function. */
  private mouseOutHandler(): void {
    this.markerManager.publishMarkerMessage(this.markerMessage, "exited");
  }

}
