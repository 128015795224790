/** @module helpers */

/**
 * This class helps managing application errors.
 */
export class ErrorUtils {

  /**
   * Get the message from the error.
   * @param error The error.
   */
  public static GetErrorMessage(error: unknown): string {
    return error instanceof Error ?
      error.message
      : String(error);
  }

}
