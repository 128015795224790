/** @module managers */
import { Vector3, PerspectiveCamera, Camera } from "three";
import { Observable, Subscription } from "rxjs";
import { sampleTime, pairwise } from "rxjs/operators";
import { log } from "../helpers/Logger";
import { RenderManager } from "./RenderManager";
import { GUIManager } from "./GUIManager";
import { BusBackend } from "../services/BusBackend";
import { NavigationUpdatedOutputMessage, NavigationUpdatedOutputMessageParameter, ErrorOutputMessage } from "../models/BusOutputMessage";
import {
  BusInputMethod, BusInputSerializedMessage, SetViewerEnabledInputMessageParameters, SetMarkersInputMessageParameters, SetNavigationInputMessageParameters,
  PlaySoundInputMessageParameters,
  SetSelectedAssetsInputMessageParameters,
  StopSoundInputMessageParameters,
  SetWaterSpeedInputMessageParameters,
  SetDynamicAssetsVisibilityInputMessageParameters
} from "../models/BusInputMessage";
import {
  SignalRInputSerializedMessage, SignalRInputType, SetAnimationValueInputMessageParameters, SetDynamicAssetsLocationInputMessageParameters,
  SetDynamicAssetsAddInputMessageParameters, SetDynamicAssetsRemoveInputMessageParameters
} from "../models/SignalRInputMessage";
import { AnimationManager } from "./AnimationManager";
import { SceneManager } from "./SceneManager";
import { AudioManager } from "./AudioManager";
import { DynamicAssetManager } from "./DynamicAssetManager";
import { ErrorUtils } from "../helpers/ErrorUtils";


/** This class is responsible for managing input events from bus and from the SignalR backend. */
export class EventManager {

  /** The id of the viewer instance. */
  private instanceId: number;
  /** The gui manager instance. */
  private guiManager: GUIManager;
  /** The bus backend service instance. */
  private busBackend: BusBackend;
  /** The render manager instance. */
  private renderManager: RenderManager;
  /** The scene manager instance. */
  private sceneManager: SceneManager;
  /** The animation manager. */
  private animationManager: AnimationManager;
  /** The dynamic asset manager. */
  private dynamicAssetManager: DynamicAssetManager;
  /** The audio manager. */
  private audioManager: AudioManager;
  /** The subscription of the camera observable. */
  private subscription!: Subscription;
  /** The last sampled navigation data. */
  private lastNavigationData: NavigationUpdatedOutputMessageParameter | undefined;


  /**
   * Creates a new instance.
   * @param instanceId The id of the viewer instance.
   * @param guiMgr The gui manager instance.
   * @param busBackend The bus backend service instance.
   * @param renderMgr The render manager instance.
   * @param sceneMgr The scene manager instance.
   */
  public constructor(instanceId: number, guiMgr: GUIManager, busBackend: BusBackend, renderMgr: RenderManager, sceneMgr: SceneManager) {
    this.instanceId = instanceId;
    this.guiManager = guiMgr;
    this.audioManager = guiMgr.getAudioManager();
    this.busBackend = busBackend;
    this.renderManager = renderMgr;
    this.sceneManager = sceneMgr;
    this.animationManager = sceneMgr.getAnimationManager();
    this.dynamicAssetManager = sceneMgr.getDynamicAssetManager();
  }

  /** Gets the last navigation data. */
  public getLastNavigationData(): NavigationUpdatedOutputMessageParameter | undefined {
    return this.lastNavigationData;
  }

  /**
   * Subscribes to main camera observable and publish data on the output bus.
   * @param time The sample time in milliseconds.
   * @param observable The observable for subscription.
   */
  public subscribeMainCamera(time: number, observable: Observable<Camera>): Subscription {
    const sampledObservable: Observable<[Camera, Camera]> = observable.pipe(sampleTime(time), pairwise());
    this.subscription = sampledObservable.subscribe(
      camera => {
        const previous: NavigationUpdatedOutputMessageParameter = this.createOutputNavigationParams(camera[0] as PerspectiveCamera);
        const current: NavigationUpdatedOutputMessageParameter = this.createOutputNavigationParams(camera[1] as PerspectiveCamera);
        this.lastNavigationData = current;
        this.busBackend.publish(new NavigationUpdatedOutputMessage({ previous, current }));
      },
      error => log.debug(`Error in cameraPositionSubscription ${error.message}`),
      () => log.debug("cameraPositionSubscription completed")
    );
    return this.subscription;
  }

  /** Gets the input bus message handler function. */
  public getBusMessageHandler(): (message: BusInputSerializedMessage) => void {
    return (message: BusInputSerializedMessage) => {
      try {
        log.debug(`bus input ${JSON.stringify(message)}`);
        const isInstanceIdArray: boolean = Array.isArray(message.instanceId);
        if (
          (!isInstanceIdArray && (message.instanceId === this.instanceId || message.instanceId === -1)) ||
          (isInstanceIdArray && (message.instanceId as number[]).includes(this.instanceId))
        ) {
          switch (message.method) {
            case BusInputMethod.SetViewerEnabled: {
              const params: SetViewerEnabledInputMessageParameters = JSON.parse(message.params);
              this.renderManager.setRenderingEnabled(params.isEnabled);
              this.guiManager.setOrbitControlsEnabled(params.isEnabled);
              break;
            }
            case BusInputMethod.SetNavigation: {
              const params: SetNavigationInputMessageParameters = JSON.parse(message.params);
              this.sceneManager.setMainCameraViewFromEvent(params);
              break;
            }
            case BusInputMethod.SetSelectedAssets: {
              const params: SetSelectedAssetsInputMessageParameters = JSON.parse(message.params);
              this.sceneManager.selectAssetsFromIds(params.ids, params.clear);
              break;
            }
            case BusInputMethod.SetMarkers: {
              const params: SetMarkersInputMessageParameters = JSON.parse(message.params);
              this.guiManager.getMarkerManager().updateMarkersFromEvent(params);
              break;
            }
            case BusInputMethod.PlaySound: {
              const params: PlaySoundInputMessageParameters = JSON.parse(message.params);
              this.audioManager.playSound(params.id, params.loop);
              break;
            }
            case BusInputMethod.StopSound: {
              const params: StopSoundInputMessageParameters = JSON.parse(message.params);
              this.audioManager.stopSound(params.id);
              break;
            }
            case BusInputMethod.SetAnimationValue: {
              const params: SetAnimationValueInputMessageParameters = JSON.parse(message.params);
              this.animationManager.updateAnimation(params.assetId, params.value, params.animationId);
              break;
            }
            case BusInputMethod.SetWaterSpeed: {
              const params: SetWaterSpeedInputMessageParameters = JSON.parse(message.params);
              this.sceneManager.updateWaterSpeed(params.speed);
              break;
            }
            case BusInputMethod.SetDynamicAssetsVisibility: {
              const params: SetDynamicAssetsVisibilityInputMessageParameters = JSON.parse(message.params);
              this.dynamicAssetManager.SetDynamicAssetsVisibility(params);
              break;
            }
            case BusInputMethod.SetDynamicAssetsLocation: {
              const params: SetDynamicAssetsLocationInputMessageParameters[] = JSON.parse(message.params);
              this.dynamicAssetManager.setDynamicAssetsLocation(params);
              break;
            }
            case BusInputMethod.SetDynamicAssetsAdd: {
              const params: SetDynamicAssetsAddInputMessageParameters[] = JSON.parse(message.params);
              this.dynamicAssetManager.setDynamicAssetsAdd(params);
              break;
            }
            case BusInputMethod.SetDynamicAssetsRemove: {
              const params: SetDynamicAssetsRemoveInputMessageParameters[] = JSON.parse(message.params);
              this.dynamicAssetManager.setDynamicAssetsRemove(params);
              break;
            }
            default: {
              log.warn("Bus message handler no matching type for " + message.method);
            }
          }
        }
      }
      catch (error) {
        const errorMessage: string = ErrorUtils.GetErrorMessage(error);
        log.warn(`Error in input bus message handler: ${errorMessage}`);
        this.busBackend.publish(new ErrorOutputMessage({ message: errorMessage }));
      }
    };
  }

  /** Gets the input bus error handler function. */
  public getBusErrorHandler(): (error: Error) => void {
    return (error: Error) => {
      log.debug(`BusErrorHandler ${error.message}`);
    };
  }

  /** Gets the input bus completed handler function. */
  public getBusCompletedHandler(): () => void {
    return () => {
      log.debug("BusCompletedHandler");
    };
  }

  /** Gets the SignalR message handler function. */
  public getSignalRMessageHandler(): (message: SignalRInputSerializedMessage) => void {
    return (message: SignalRInputSerializedMessage) => {
      try {
        if (typeof message === "string") {
          message = JSON.parse(message);
        }
        log.debug(`SignalR input ${JSON.stringify(message)}`);
        const isInstanceIdArray: boolean = Array.isArray(message.instanceId);
        if (
          (!isInstanceIdArray && (message.instanceId === this.instanceId || message.instanceId === -1)) ||
          (isInstanceIdArray && (message.instanceId as number[]).includes(this.instanceId))
        ) {
          switch (message.type) {
            case SignalRInputType.SetAnimationValue: {
              const params: SetAnimationValueInputMessageParameters = JSON.parse(message.params);
              this.animationManager.updateAnimation(params.assetId, params.value, params.animationId);
              break;
            }
            case SignalRInputType.SetDynamicAssetsLocation: {
              const params: SetDynamicAssetsLocationInputMessageParameters[] = JSON.parse(message.params);
              this.dynamicAssetManager.setDynamicAssetsLocation(params);
              break;
            }
            case SignalRInputType.SetDynamicAssetsAdd: {
              const params: SetDynamicAssetsAddInputMessageParameters[] = JSON.parse(message.params);
              this.dynamicAssetManager.setDynamicAssetsAdd(params);
              break;
            }
            case SignalRInputType.SetDynamicAssetsRemove: {
              const params: SetDynamicAssetsRemoveInputMessageParameters[] = JSON.parse(message.params);
              this.dynamicAssetManager.setDynamicAssetsRemove(params);
              break;
            }
            default: {
              log.warn("SignalR message handler no matching type for " + message.type);
            }
          }
        }
      }
      catch (error) {
        const errorMessage: string = ErrorUtils.GetErrorMessage(error);
        log.warn(`Error in SignalR message handler: ${errorMessage}`);
      }
    };
  }


  /**
   * Creates the navigation message parameters.
   * @param camera The camera.
   */
  private createOutputNavigationParams(camera: PerspectiveCamera): NavigationUpdatedOutputMessageParameter {
    const f1: Vector3 = new Vector3();
    const f2: Vector3 = new Vector3();
    const f3: Vector3 = new Vector3();
    const f4: Vector3 = new Vector3();
    f1.set(-1, -1, 1).unproject(camera);
    f2.set(-1, 1, 1).unproject(camera);
    f3.set(1, 1, 1).unproject(camera);
    f4.set(1, -1, 1).unproject(camera);
    return {
      farPlane: { f1, f2, f3, f4 },
      position: camera.position.clone(),
      direction: camera.getWorldDirection(new Vector3()).normalize(),
      target: this.sceneManager.getOrbitControls().target.clone()
    };
  }

}
