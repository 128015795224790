/** @module helpers */
import {
  SetViewerEnabledInputMessage, SetNavigationInputMessage, PlaySoundInputMessage, StopSoundInputMessage,
  SetSelectedAssetsInputMessage, SetMarkersInputMessage
} from "../models/BusInputMessage";
import { BusBackend } from "../services/BusBackend";
import { SignalRInputSerializedMessage, SetAnimationValueInputMessage, SignalRInputMessage, SetDynamicAssetsLocationInputMessageParameters } from "../models/SignalRInputMessage";
import { DebugGUIManager } from "../managers/DebugGUIManager";
import { v4 as uuidv4 } from "uuid";
import { BusInputMethod } from "../models/BusInputMessage";
import { GPSUtils } from "./GPSUtils";
import { MarkerInputMessage } from "../models/MarkerInputMessage";

/** This class is used by the debug gui and implements method for testing the Bus input API and the SignalR input API. */
export class TestInputAPI {

  /** The bus backend, for testing the publication of messages on the input bus. */
  private busBackend!: BusBackend;
  /** The signalr handler, for testing the signalr input message */
  private signalRHandler!: (message: SignalRInputSerializedMessage) => void;
  /** The debug GUI reference. */
  private debugGui!: DebugGUIManager;
  /** If the dynamic assets are visibile. */
  private visibleDynamicAssets: boolean = true;
  /** Counts dynamic assets already added to the scene. */
  private dynamicAssetCount: number = 0;
  /** Lists id of dynamic asset templates. */
  private dynamicAssetTemplates: string[] = [
    // termoli
    "ship_template_08_Generiche",
    "ship_template_01_Cargo",
    "ship_template_02_Tanker",
    "ship_template_03_Passeggeri",
    "ship_template_04_Militari",
    "ship_template_05_Vela",
    "ship_template_06_Yacht",
    "ship_template_07_Pescherecci",
    "rac_advanced_sample_project3"
    // mose
    // "d95a8b7b-e399-4ab5-9d14-ab10228a157f", // generiche
    // "cec7650a-cfbf-4d67-bfa1-76e6d2d36f42", // cargo
    // "71211470-8402-4ce5-97a7-85b057309879", // tanker
    // "8996dc3e-72d3-4f64-a85d-d10512583b73", // passeggeri
    // "5811f367-c136-48c7-886a-c6bce063dbb2", // militari
    // "81f666e2-5ce4-4c3f-b7fe-56be86f7eaf5", // vela
    // "d9f1ddb4-5841-4dd0-8de7-72e1a8b65373", // yacht
    // "e9082a42-2c10-46fa-8ee1-e76400ce3718"  // pescherecci
  ];
  /** Keeps the index of the dynamic assets position. */
  private dynamicAssetsPositionIndex: number = 0;
  /** Lists dynamic assets GPS positions. */
  private dynamicAssetPositions: { lat: number, lon: number, dir: number }[] = [
    // scene origin
    // { lat: GPSUtils.SceneOrigin.lat, lon: GPSUtils.SceneOrigin.lon, dir: 0 },
    // termoli
    { lat: 42.00456902916422, lon: 15.00231097361207, dir: 0 },
    { lat: 42.002897358453744, lon: 15.007214492081625, dir: 0 },
    { lat: 42.003377270889196, lon: 15.0031395591195, dir: 0 }
    // // malamocco
    // { lat: 45.337164706801424, lon: 12.32849501871314, dir: 0 },
    // { lat: 45.33445570481872, lon: 12.324504452297964, dir: 90 },
    // { lat: 45.33771688178266, lon: 12.32614881043915, dir: 180 },
    // // lido
    // { lat: 45.437115340958606, lon: 12.412827632366492, dir: 360 },
    // { lat: 45.43434614600177, lon: 12.414100215919902, dir: 270 },
    // { lat: 45.43205214281128, lon: 12.41138075922367, dir: 360 },
    // { lat: 45.428937868015325, lon: 12.408794837818254, dir: 0 },
    // // chioggia
    // { lat: 45.233861923160276, lon: 12.29655242575869, dir: 90 },
    // { lat: 45.23080422621014, lon: 12.29392192118838, dir: 180 },
    // { lat: 45.23404965449691, lon: 12.294084623291335, dir: 270 }
  ];

  /**
   * Constructs an instance of test API.
   * @param debugGui The debug gui instance.
   */
  public constructor(debugGui: DebugGUIManager) {
    this.debugGui = debugGui;
  }

  /**
   * Sets the bus backend service instance.
   * @param busBackend The bus backend.
   */
  public setBusBackend(busBackend: BusBackend): void {
    this.busBackend = busBackend;
  }

  /**
   * Sets the signalr service instance.
   * @param handler The signalr handler.
   */
  public setSignalRHandler(handler: (message: SignalRInputSerializedMessage) => void): void {
    this.signalRHandler = handler;
  }

  /** Set a random animation value for the 3-PAR02 bulkhead via SignalR input message. */
  public setAnimationValue(): void {
    const inputMessage: SetAnimationValueInputMessage = new SetAnimationValueInputMessage({
      assetId: "5ed87956-6a34-4786-bdf9-f7325f21b33d",
      value: Math.random()
    });
    const serializedInputMessage: SignalRInputSerializedMessage = this.serializeSignalRMessage(inputMessage);
    this.signalRHandler(serializedInputMessage);
  }


  /** Set the rendering state to enabled via bus input message. */
  public setRenderingEnabled(): void {
    this.busBackend.publishDebug(new SetViewerEnabledInputMessage({
      isEnabled: true
    }));
  }

  /** Set the rendering state to disabled via bus input message. */
  public setRenderingDisabled(): void {
    this.busBackend.publishDebug(new SetViewerEnabledInputMessage({
      isEnabled: false
    }));
  }

  /** Set the navigation to the scene origin via bus input message. */
  public setNavigationOrigin(): void {
    this.busBackend.publishDebug(new SetNavigationInputMessage({
      position: { x: 0, y: 0, z: 0 },
      target: { x: 0, y: 0, z: 0 }
    }));
  }

  /** Set the navigation to the "Lido" view values via bus input message. */
  public setNavigationLido(): void {
    this.busBackend.publishDebug(new SetNavigationInputMessage({
      position: { x: 6278.25, y: 68.27, z: -10271.92 },
      target: { x: 6394.23, y: 0, z: -10319.01 }
    }));
  }

  /** Set the navigation to the "Malamocco" view values via bus input message. */
  public setNavigationMalamocco(): void {
    this.busBackend.publishDebug(new SetNavigationInputMessage({
      position: { x: -620.96, y: 145.85, z: 282.13 },
      target: { x: -543.81, y: 0, z: 436.16 }
    }));
  }

  /** Set the navigation to the "Chioggia" view values via bus input message. */
  public setNavigationChioggia(): void {
    this.busBackend.publishDebug(new SetNavigationInputMessage({
      position: { x: -3472.28, y: 102.45, z: 11923.97 },
      target: { x: -3360.99, y: 0, z: 11819.49 }
    }));
  }

  /** Set the navigation to the specified asset via bus input message. */
  public setNavigationAsset(): void {
    this.busBackend.publishDebug(new SetNavigationInputMessage({
      assetId: "00403c61-c342-41a7-965c-e31ce7c2be52"
    }));
  }

  /** Play the "alertLow" sound in loop via bus input message. */
  public playSoundAlertLow(): void {
    this.busBackend.publishDebug(new PlaySoundInputMessage({
      id: "alertLow",
      loop: true
    }));
  }

  /** Stop the "alertLow" sound via bus input message. */
  public stopSoundAlertLow(): void {
    this.busBackend.publishDebug(new StopSoundInputMessage({
      id: "alertLow"
    }));
  }

  /** Highlight one asset without clearing the highlight on current selected assets via bus input message. */
  public selectAsset(): void {
    this.busBackend.publishDebug(new SetSelectedAssetsInputMessage({
      ids: ["rac_advanced_sample_project"],
      // ids: ["00403c61-c342-41a7-965c-e31ce7c2be52"], // 3-EAC MOSE
      clear: false
    }));
  }

  /** Highlight multiple assets without clearing the highlight on current selected assets via bus input message. */
  public selectAssets(): void {
    this.busBackend.publishDebug(new SetSelectedAssetsInputMessage({
      ids: [
        "rac_advanced_sample_project",
        "rac_advanced_sample_project2"
      ],
      // ids: [
      //   "fe9776cc-7abd-4db3-8a83-aad6107b72bc", // 3-PAR01 MOSE
      //   "5ed87956-6a34-4786-bdf9-f7325f21b33d", // 3-PAR02 MOSE
      //   "116115cf-e07d-4379-b5a3-e222061afd57" // 3-PAR03 MOSE
      // ],
      clear: false
    }));
  }

  /** Highlight the dynamic-asset-1 asset clearing the highlight via bus input message. */
  public selectDynamicAsset(): void {
    this.busBackend.publishDebug(new SetSelectedAssetsInputMessage({
      ids: ["dynamic-asset-0"],
      clear: true
    }));
  }

  /** Clear the highlight on all highlighted assets via bus input message. */
  public clearAssetSelection(): void {
    this.busBackend.publishDebug(new SetSelectedAssetsInputMessage({
      ids: [],
      clear: true
    }));
  }

  /** Creates specified markers with associated balloon contents via bus input message. */
  public createMarkers(): void {
    this.busBackend.publishDebug(new SetMarkersInputMessage({
      markers: [
        {
          markerId: "330c3fc6-ab88-4be5-a00f-8cd31210d33f",
          assetId: "fe9776cc-7abd-4db3-8a83-aad6107b72bc",
          isBalloonVisible: true,
          balloonDivId: "330c3fc6-ab88-4be5-a00f-8cd31210d33f",
          icon: "fas marker fa-map-marker-alt red"
        },
        {
          markerId: "330c3fc6-ab88-4be5-a00f-8cd31210d34f",
          assetId: "5ed87956-6a34-4786-bdf9-f7325f21b33d",
          isBalloonVisible: false,
          balloonDivId: "330c3fc6-ab88-4be5-a00f-8cd31210d34f",
          icon: "fas marker fa-map-marker-alt green big"
        },
        {
          markerId: "330c3fc6-ab88-4be5-a00f-8cd31210d35f",
          assetId: "116115cf-e07d-4379-b5a3-e222061afd57",
          isBalloonVisible: false,
          balloonDivId: "330c3fc6-ab88-4be5-a00f-8cd31210d35f",
          icon: "fas marker fas fa-exclamation-triangle yellow"
        },
        {
          markerId: "330c3fc6-ab88-4be5-a00f-8cd31210d36f",
          assetId: "9a26a3df-6edf-45d1-9aa9-a00223a3cc7c",
          icon: "fas marker fas fa-question red"
        }
      ]
    }));
  }

  /** Clear all markers via bus input message. */
  public clearMarkers(): void {
    this.busBackend.publishDebug(new SetMarkersInputMessage({
      markers: []
    }));
  }

  /** Creates a marker for each dynamic asset in the scene with associated balloon contents via bus input message. */
  public createDynamicAssetMarkers(): void {
    this.clearMarkers();
    const dynAssetsIds: string[] = this.debugGui.getDynamicAssetsIds();
    const markers: MarkerInputMessage[] = [];
    for (const dynAssetId of dynAssetsIds) {
      markers.push({
        markerId: uuidv4(),
        assetId: dynAssetId,
        isBalloonVisible: true,
        balloonDivId: "330c3fc6-ab88-4be5-a00f-8cd31210d34f",
        icon: ""
      });
    }
    this.busBackend.publishDebug(new SetMarkersInputMessage({
      markers
    }));
  }

  /** Toggle dynamic assets visibility via bus input message. */
  public toggleDynamicAssetsVisibility(): void {
    this.visibleDynamicAssets = !this.visibleDynamicAssets;
    this.busBackend.publishDebug({
      method: BusInputMethod.SetDynamicAssetsVisibility,
      params: {
        show: this.visibleDynamicAssets,
        all: true,
        id: ""
      }
    });
  }

  /** Set dynamic assets location via bus input message. */
  public setDynamicAssetsLocation(): void {
    this.dynamicAssetsPositionIndex = (this.dynamicAssetsPositionIndex + 1) % this.dynamicAssetPositions.length;
    const params: SetDynamicAssetsLocationInputMessageParameters[] = [];
    for (let i: number = 0; i < this.dynamicAssetCount; i++) {
      const position: { lat: number, lon: number, dir: number } = this.dynamicAssetPositions[this.dynamicAssetsPositionIndex];
      const param: SetDynamicAssetsLocationInputMessageParameters = {
        id: "dynamic-asset-" + i,
        lat: position.lat,
        lon: position.lon + (i * 0.001),
        dir: position.dir,
        time: new Date().getTime()
      };
      params.push(param);
    }
    this.busBackend.publishDebug({
      method: BusInputMethod.SetDynamicAssetsLocation,
      params
    });
  }

  /** Add dynamic assets via bus input message. */
  public setDynamicAssetsAdd(): void {
    const position: { lat: number, lon: number, dir: number } = this.dynamicAssetPositions[this.dynamicAssetsPositionIndex];
    this.busBackend.publishDebug({
      method: BusInputMethod.SetDynamicAssetsAdd,
      params: [{
        id: "dynamic-asset-" + this.dynamicAssetCount,
        template: this.dynamicAssetTemplates[this.dynamicAssetCount % this.dynamicAssetTemplates.length],
        // length: 200,
        // width: 40,
        // height: 70,
        // pivotFrontDistance: 58,
        // pivotLeftDistance: 20,
        lat: position.lat,
        lon: position.lon + (this.dynamicAssetCount * 0.001),
        dir: position.dir,
        time: new Date().getTime()
      }]
    });
    this.dynamicAssetCount++;
  }

  /** Add dynamic assets via bus input message. */
  public setDynamicAssetsRemove(): void {
    this.busBackend.publishDebug({
      method: BusInputMethod.SetDynamicAssetsRemove,
      params: [{
        id: "dynamic-asset-" + (this.dynamicAssetCount - 1)
      }]
    });
    if (this.dynamicAssetCount > 0)
      this.dynamicAssetCount--;
  }

  /** Utility method to serialize the provided SignalR message. */
  private serializeSignalRMessage(message: SignalRInputMessage): SignalRInputSerializedMessage {
    return {
      type: message.type,
      params: JSON.stringify(message.params),
      instanceId: 1
    };
  }

}
