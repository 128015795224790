/** @module helpers */
import { LogLevel } from "typescript-logging";
import { Log4TSProvider, Logger as TSLogger } from "typescript-logging-log4ts-style";

/**
 * This class holds the logger. It supports six log levels: Trace, Debug, Info, Warn, Error, Fatal.
 * The log level to use is configurable on the Viewer3DConfiguration supplied at viewer initialization time.
 * See https://www.npmjs.com/package/typescript-logging.
 */
export class Logger {

  /** The log provider. */
  private static provider: Log4TSProvider;

  /** Creates a new instance of typescript-logging Logger, see https://www.npmjs.com/package/typescript-logging#log4j-style-logging. */
  public static CreateTSLogger(): TSLogger {
    const provider: Log4TSProvider = Log4TSProvider.createProvider("LogProvider", {
      groups: [{
        expression: new RegExp(".+")
      }]
    });
    Logger.provider = provider;
    return provider.getLogger("Viewer3D");
  }

  /**
   * Sets the log level of the Logger according to the level provided.
   * @param level The log level.
   */
  public static SetLogLevel(level: string): void {
    Logger.provider.updateRuntimeSettings({ level: LogLevel.toLogLevel(level) });
  }
}

/** The instance of the Logger that substitutes conventional calls to console.log. E.g. log.debug(), log.warn(). */
export const log: TSLogger = Logger.CreateTSLogger();
