/**
 * This module contains code related to the gui like html elements.
 * @module gui
 */
import { EnvConfig } from "../helpers/EnvConfig";


/** This class is responsible for the Compass gui element. */
export class Compass {

  /** This is the compass container html element. */
  private compassContainer: HTMLElement;

  /** Creates a new instance of Compass. */
  constructor() {
    this.compassContainer = this.create();
  }

  /** Returns the compass container */
  public getContainer(): HTMLElement {
    return this.compassContainer;
  }

  /**
   * Changes the compass container style (rotation).
   * @param angle The rotation angle in radians.
   */
  public setRotationRad(angle: number): void {
    this.compassContainer.style.transform = "rotate(" + angle + "rad)";
  }

  /** Creates the container html element */
  private create(): HTMLElement {
    const compassContainer: HTMLElement = document.createElement("div");
    compassContainer.id = "compass";
    const compassImage: HTMLImageElement = document.createElement("img");
    compassImage.alt = "";
    compassImage.src = EnvConfig.GetStaticFilesBaseUrl() + "ui/compass.png";
    compassContainer.appendChild(compassImage);
    return compassContainer;
  }

}
