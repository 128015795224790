/** @module helpers */
import proj4 from "proj4";
import { Viewer3DConfiguration } from "../models/Viewer3DConfiguration";
import { ReferencePoint } from "../models/ReferencePoint";

/**
 * This class helps working with GPS coordinates conversion.
 * Relies on Proj4js library, see https://github.com/proj4js/proj4js.
 */
export class GPSUtils {

  /** The mercator projection configuration string. */
  static GMercatorProjection: string = "+title=GoogleMercator +proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +no_defs";

  /** The WGS84/Mercator converter. */
  static Converter: proj4.Converter = proj4(proj4.WGS84, GPSUtils.GMercatorProjection);

  /** The GPS coordinates corresponding to the scene origin. */
  static SceneOrigin: ReferencePoint = {
    lat: 0,
    lon: 0,
    x: 0,
    z: 0
  };

  /** Store the GPS coordinates as well as the correspondent local coordinates.
   * @param configSceneOrigin The value of the Viewer3D configuration sceneOrigin property.
   */
  public static InitSceneOrigin(configSceneOrigin: Viewer3DConfiguration["sceneOrigin"]): void {
    const localSceneOrigin: number[] = GPSUtils.GPSPosition2Local([configSceneOrigin.lon, configSceneOrigin.lat]);
    GPSUtils.SceneOrigin = {
      lat: configSceneOrigin.lat,
      lon: configSceneOrigin.lon,
      x: localSceneOrigin[0],
      z: localSceneOrigin[1]
    };
  }

  /** Convert GPS coordinates to local scene coordinates.
   * @param objPosi The GPS coordinates.
   */
  public static GPSPosition2Local(objPosi: number[]): number[] {
    return GPSUtils.Converter.forward(objPosi);
  }

  /** Convert local scene coordinates to GPS coordinates.
   * @param objPosi The local scene coordinates.
   */
  public static LocalPosition2GPS(objPosi: number[]): number[] {
    return GPSUtils.Converter.inverse(objPosi);
  }

}
