/**
 * This module contains helpers and utility code.
 * @module helpers
 */

/**
 * This class holds the .env configuration. This configuration is common to all Viewer3D instances.
 * The application must be rebuilt to take into effect the .env file configuration changes.
 */
export class EnvConfig {

  /** The 3d viewer version injected by webpack from package.json. */
  public static Version: string;
  /** Determines if it is a development or production build, it is injected by webpack, https://webpack.js.org/guides/production/#specify-the-mode. */
  public static NodeEnv: string;
  /** The base url of the proxy backend, used only if no proxy is provided at the time of the viewer instance creation. */
  public static BackendBaseUrl: string;
  /** The jwt auth token for the proxy backend requests, used only if no proxy is provided at the time of the viewer instance creation. */
  public static BackendAuthToken: string;
  /** The url of the SignalR backend. */
  public static SignalRUrl: string;
  /** The path where static assets will be found. E.g. assets/viewer3d-static/ will get static assets in that subfolder relative to the root of the web server. */
  public static StaticFilesPath: string;

  /** Loads process.env variables injected from the .env file performing a basic validation. */
  public static Load(): void {
    if (process.env.VERSION) {
      EnvConfig.Version = process.env.VERSION;
    }
    else {
      throw new Error("AppConfig Error: VERSION is undefined");
    }

    if (process.env.NODE_ENV) {
      EnvConfig.NodeEnv = process.env.NODE_ENV;
    }
    else {
      throw new Error("AppConfig Error: NODE_ENV is undefined");
    }

    if (process.env.BACKEND_BASE_URL) {
      EnvConfig.BackendBaseUrl = process.env.BACKEND_BASE_URL;
    }
    else {
      throw new Error("AppConfig Error: BACKEND_BASE_URL is undefined");
    }

    if (process.env.BACKEND_AUTH_TOKEN) {
      EnvConfig.BackendAuthToken = process.env.BACKEND_AUTH_TOKEN;
    }
    else {
      throw new Error("AppConfig Error: BACKEND_AUTH_TOKEN is undefined");
    }

    if (process.env.SIGNALR_URL) {
      EnvConfig.SignalRUrl = process.env.SIGNALR_URL;
    }

    if (process.env.STATIC_FILES_PATH) {
      EnvConfig.StaticFilesPath = process.env.STATIC_FILES_PATH;
    }
    else {
      throw new Error("AppConfig Error: STATIC_FILES_PATH is undefined");
    }

  }

  /**
   * Returns the absolute base url of static assets, it does so by concatenating
   * window.location.origin and the STATIC_FILES_PATH .env config.
   */
  public static GetStaticFilesBaseUrl(): string {
    return EnvConfig.StaticFilesPath.startsWith('http') ?
      EnvConfig.StaticFilesPath
      : window.location.origin + "/" + EnvConfig.StaticFilesPath;
  }

}
