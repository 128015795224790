/** @module objects */
import {
  Mesh, ShaderMaterial, Vector3, Color, Plane, Side, FrontSide, Matrix4, Vector4, PerspectiveCamera,
  LinearFilter, RGBAFormat, WebGLRenderTarget, UniformsUtils, MathUtils as TMath
} from "three";
import { Shaders } from "../shaders/Shaders";


/**
 * This class implements water. It extends a Mesh and using a custom shader.
 * See https://threejs.org/docs/#api/en/objects/Mesh and https://threejs.org/examples/?q=ocean#webgl_shaders_ocean.
 */
export class Water extends Mesh {

  /** The water custom shader material. */
  public material: ShaderMaterial;
  /** The water options. */
  private options: any;
  /** True if provided geometry is vertical. */
  private vertical: boolean = false;

  /**
   * Creates a new Water instance.
   * @param geometry The geometry to use.
   * @param options The configuration options.
   * @param vertical The geometry orientation, true if vertical.
   */
  constructor(geometry: any, options: any, vertical: boolean = false) {
    super(geometry);

    this.vertical = vertical;

    this.options = this.options || {};

    const textureWidth: number = options.textureWidth !== undefined ? options.textureWidth : 512;
    const textureHeight: number = options.textureHeight !== undefined ? options.textureHeight : 512;
    const clipBias: number = options.clipBias !== undefined ? options.clipBias : 0.0;
    const alpha: number = options.alpha !== undefined ? options.alpha : 0.9;
    const time: number = options.time !== undefined ? options.time : 0.0;
    const normalSampler: any = options.waterNormals !== undefined ? options.waterNormals : null;
    const sunDirection: Vector3 = options.sunDirection !== undefined ? options.sunDirection : new Vector3(0.70707, 0.70707, 0.0);
    const sunColor: Color = new Color(options.sunColor !== undefined ? options.sunColor : 0xffffff);
    const waterColor: Color = new Color(options.waterColor !== undefined ? options.waterColor : 0x7F7F7F);
    const eye: Vector3 = options.eye !== undefined ? options.eye : new Vector3(0, 0, 0);
    const distortionScale: number = options.distortionScale !== undefined ? options.distortionScale : 20.0;
    const side: Side = options.side !== undefined ? options.side : FrontSide;
    const fog: boolean = options.fog !== undefined ? options.fog : false;
    // const morphTargets: boolean = options.morphTargets !== undefined ? options.morphTargets : false;

    const mirrorPlane: Plane = new Plane();
    const normal: Vector3 = new Vector3();
    const mirrorWorldPosition: Vector3 = new Vector3();
    const cameraWorldPosition: Vector3 = new Vector3();
    const rotationMatrix: Matrix4 = new Matrix4();
    const lookAtPosition: Vector3 = new Vector3(0, 0, - 1);
    const clipPlane: Vector4 = new Vector4();

    const view: Vector3 = new Vector3();
    const target: Vector3 = new Vector3();
    const q: Vector4 = new Vector4();

    const textureMatrix: Matrix4 = new Matrix4();

    const mirrorCamera: PerspectiveCamera = new PerspectiveCamera();
    mirrorCamera.layers.mask = options.mirrorCameraMask !== undefined ? options.mirrorCameraMask : -1;
    this.layers.mask = options.mask !== undefined ? options.mask : -1;

    const parameters: any = {
      minFilter: LinearFilter,
      magFilter: LinearFilter,
      format: RGBAFormat,
      stencilBuffer: false
    };

    const renderTarget: WebGLRenderTarget = new WebGLRenderTarget(textureWidth, textureHeight, parameters);

    if (!TMath.isPowerOfTwo(textureWidth) || !TMath.isPowerOfTwo(textureHeight)) {
      renderTarget.texture.generateMipmaps = false;
    }

    const material: ShaderMaterial = new ShaderMaterial({
      fragmentShader: Shaders.MirrorShader.fragmentShader,
      vertexShader: Shaders.MirrorShader.vertexShader,
      uniforms: UniformsUtils.clone(Shaders.MirrorShader.uniforms),
      transparent: true,
      lights: true,
      side,
      fog
      //morphNormals: false,
      //morphTargets
    });

    material.uniforms.mirrorSampler.value = renderTarget.texture;
    material.uniforms.textureMatrix.value = textureMatrix;
    material.uniforms.alpha.value = alpha;
    material.uniforms.time.value = time;
    material.uniforms.normalSampler.value = normalSampler;
    material.uniforms.sunColor.value = sunColor;
    material.uniforms.waterColor.value = waterColor;
    material.uniforms.sunDirection.value = sunDirection;
    material.uniforms.distortionScale.value = distortionScale;

    material.uniforms.speed.value = 1;
    material.uniforms.rotX.value = 1;
    material.uniforms.rotY.value = 1;

    material.uniforms.eye.value = eye;

    this.material = material;


    // called before rendering
    this.onBeforeRender = (renderer, scene, camera: any) => {

      mirrorWorldPosition.setFromMatrixPosition(this.matrixWorld);
      cameraWorldPosition.setFromMatrixPosition(camera.matrixWorld);

      rotationMatrix.extractRotation(this.matrixWorld);

      if (this.vertical) {
        //Normals for vertical geometry
        normal.set(0, 0, 1);
      } else {
        //Normals for horizontal Geometry
        normal.set(0, 1, 0);
      }

      normal.applyMatrix4(rotationMatrix);

      view.subVectors(mirrorWorldPosition, cameraWorldPosition);

      // Avoid rendering when mirror is facing away

      if (view.dot(normal) > 0) {
        return;
      }

      view.reflect(normal).negate();
      view.add(mirrorWorldPosition);

      rotationMatrix.extractRotation(camera.matrixWorld);

      lookAtPosition.set(0, 0, - 1);
      lookAtPosition.applyMatrix4(rotationMatrix);
      lookAtPosition.add(cameraWorldPosition);

      target.subVectors(mirrorWorldPosition, lookAtPosition);
      target.reflect(normal).negate();
      target.add(mirrorWorldPosition);

      mirrorCamera.position.copy(view);
      mirrorCamera.up.set(0, 1, 0);
      mirrorCamera.up.applyMatrix4(rotationMatrix);
      mirrorCamera.up.reflect(normal);
      mirrorCamera.lookAt(target);

      mirrorCamera.far = camera.far; // Used in WebGLBackground

      mirrorCamera.updateMatrixWorld(false);
      mirrorCamera.projectionMatrix.copy(camera.projectionMatrix);

      // Update the texture matrix
      textureMatrix.set(
        0.5, 0.0, 0.0, 0.5,
        0.0, 0.5, 0.0, 0.5,
        0.0, 0.0, 0.5, 0.5,
        0.0, 0.0, 0.0, 1.0
      );
      textureMatrix.multiply(mirrorCamera.projectionMatrix);
      textureMatrix.multiply(mirrorCamera.matrixWorldInverse);

      // Now update projection matrix with new clip plane, implementing code from: http://www.terathon.com/code/oblique.html
      // Paper explaining this technique: http://www.terathon.com/lengyel/Lengyel-Oblique.pdf
      mirrorPlane.setFromNormalAndCoplanarPoint(normal, mirrorWorldPosition);
      mirrorPlane.applyMatrix4(mirrorCamera.matrixWorldInverse);

      clipPlane.set(mirrorPlane.normal.x, mirrorPlane.normal.y, mirrorPlane.normal.z, mirrorPlane.constant);

      const projectionMatrix: Matrix4 = mirrorCamera.projectionMatrix;

      q.x = (Math.sign(clipPlane.x) + projectionMatrix.elements[8]) / projectionMatrix.elements[0];
      q.y = (Math.sign(clipPlane.y) + projectionMatrix.elements[9]) / projectionMatrix.elements[5];
      q.z = - 1.0;
      q.w = (1.0 + projectionMatrix.elements[10]) / projectionMatrix.elements[14];

      // Calculate the scaled plane vector
      clipPlane.multiplyScalar(2.0 / clipPlane.dot(q));

      // Replacing the third row of the projection matrix
      projectionMatrix.elements[2] = clipPlane.x;
      projectionMatrix.elements[6] = clipPlane.y;
      projectionMatrix.elements[10] = clipPlane.z + 1.0 - clipBias;
      projectionMatrix.elements[14] = clipPlane.w;

      eye.setFromMatrixPosition(camera.matrixWorld);

      //

      const currentRenderTarget: WebGLRenderTarget | null = renderer.getRenderTarget();

      //const currentVrEnabled: boolean = renderer.vr.enabled;
      const currentShadowAutoUpdate: boolean = renderer.shadowMap.autoUpdate;

      this.visible = false;

      //renderer.vr.enabled = false; // Avoid camera modification and recursion
      renderer.shadowMap.autoUpdate = false; // Avoid re-computing shadows

      renderer.setRenderTarget(renderTarget);
      renderer.clear();
      renderer.render(scene, mirrorCamera);

      this.visible = true;

      //renderer.vr.enabled = currentVrEnabled;
      renderer.shadowMap.autoUpdate = currentShadowAutoUpdate;

      renderer.setRenderTarget(currentRenderTarget);

    };

  }


}
