/** @module services */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { log } from "../helpers/Logger";
import { EnvConfig } from "../helpers/EnvConfig";
import { Viewer3DProxyLegacy, Viewer3DProxy } from "../models/Viewer3DProxy";
import { AssetDescriptor, GLTFResponse } from "../models/Viewer3DProxy";
import { firstValueFrom } from "rxjs";
import { ErrorUtils } from "../helpers/ErrorUtils";

/**
 * This class is responsible for managing the proxy backend requests. If a proxy is provided at the time of Viewer3D instance creation,
 * that proxy will be used, otherwise, no proxy will be used and the XHR requests will be performed directly without intermediation.
 * In the latter case, the variables BACKEND_BASE_URL and BACKEND_AUTH_TOKEN from the .env file are used.
 */
export class ProxyBackendLegacy {

  /** Determines if the proxy is legacy. */
  public isLegacy: boolean = true;

  /** The proxy instance. */
  private proxy!: Viewer3DProxyLegacy;
  /** Max retry count for bin and textures resources. */
  private maxRetryCount: number = 3;

  /**
   * Creates a new instance of this class.
   * @param proxy The proxy object.
   */
  constructor(proxy: Viewer3DProxyLegacy) {
    if (proxy) {
      log.debug("Proxy was provided, using it");
      this.proxy = proxy;
    }
    else {
      log.warn("Proxy was not provided, using dev proxy");
    }
  }

  /** Gets all the assets descriptors asyncronously from the proxy (if provided) or directly. */
  public async getAllAssetsAsync(): Promise<AssetDescriptor[]> {
    return this.proxy ?
      await firstValueFrom(this.proxy.getAllAssetsAsync())
      : await this.getAllAssetsAsyncDev();
  }

  /**
   * Gets gltf resources asyncronously from the proxy (if provided) or directly.
   * @param ids The ids of the gltf resources.
   */
  public async getGltfsAsync(ids: string[]): Promise<GLTFResponse[]> {
    return this.proxy ?
      await firstValueFrom((this.proxy).getGltfsAsync(ids))
      : await this.getGltfsAsyncDev(ids);
  }

  /**
   * Gets the bin resource asyncronously from the proxy (if provided) or directly.
   * @param id The id of the bin resource.
   */
  public async getBinAsync(id: string): Promise<Blob> {
    return this.proxy ?
      await firstValueFrom((this.proxy).getBinAsync(id))
      : await this.tryRequest(() => this.getBinAsyncDev(id));
  }

  /**
   * Gets the texture resources asyncronously from the proxy (if provided) or directly.
   * @param id The id of the texture resource.
   */
  public async getTextureAsync(id: string): Promise<Blob> {
    return this.proxy ?
      await firstValueFrom((this.proxy).getTextureAsync(id))
      : await this.tryRequest(() => this.getTextureAsyncDev(id));
  }

  /**
   * Try to execute the provided function a predefined numer of times.
   * @param requestFn The async function to execute.
   * @param iter The current iteration count.
   */
  private async tryRequest(requestFn: () => Promise<Blob>, iter: number = 0): Promise<Blob> {
    const retrySec: number = Math.pow(3, iter);
    try {
      return await requestFn();
    }
    catch (error) {
      const errorMessage: string = ErrorUtils.GetErrorMessage(error);
      if (iter < this.maxRetryCount) {
        log.warn(`Request failed, will try again in ${retrySec} seconds ` + errorMessage);
        await this.waitSeconds(retrySec);
        return this.tryRequest(requestFn, iter + 1);
      }
      else {
        throw new Error("Request failed, reached max retry count");
      }
    }
  }

  /**
   * Wait the specified number of seconds.
   * @param seconds The number of seconds to wait.
   */
  private waitSeconds(seconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, seconds * 1e3));
  }

  /**
   * Method to reject the promise if the backend response is faulted.
   * @param response The response to check.
   * @param resolve The promise resolve function.
   * @param reject The promise reject function.
   */
  private responseHandler(response: AxiosResponse): any {
    const data: any = response.data;
    if (!data.isFaulted)
      return data.result;
    else
      throw new Error(data);
  }

  /** Gets all the assets descriptors asyncronously directly without proxying. */
  private async getAllAssetsAsyncDev(): Promise<AssetDescriptor[]> {
    const request: AxiosRequestConfig = {
      baseURL: EnvConfig.BackendBaseUrl,
      url: "/GetAllAssetsAsync",
      method: "post",
      data: {},
      headers: { Authorization: "Bearer " + EnvConfig.BackendAuthToken }
    };
    const response: AxiosResponse = await axios.request(request);
    return this.responseHandler(response) as AssetDescriptor[];
  }

  /**
   * Gets gltf resources asyncronously directly without proxying.
   * @param ids The ids of the gltf resources.
   */
  private async getGltfsAsyncDev(ids: string[]): Promise<GLTFResponse[]> {
    const request: AxiosRequestConfig = {
      baseURL: EnvConfig.BackendBaseUrl,
      url: "/GetGltfsAsync",
      method: "post",
      data: { ids },
      headers: { Authorization: "Bearer " + EnvConfig.BackendAuthToken }
    };
    const response: AxiosResponse = await axios.request(request);
    return this.responseHandler(response) as GLTFResponse[];
  }

  /**
   * Gets the bin resource asyncronously directly without proxying.
   * @param id The id of the bin resource.
   */
  private async getBinAsyncDev(id: string): Promise<Blob> {
    const request: AxiosRequestConfig = {
      baseURL: EnvConfig.BackendBaseUrl,
      url: "/GetBinAsync/stream?id=" + id,
      method: "get",
      headers: { Authorization: "Bearer " + EnvConfig.BackendAuthToken },
      responseType: "blob"
    };
    const response: AxiosResponse = await axios.request(request);
    return response.data as Blob;
  }

  /**
   * Gets the texture resources asyncronously directly without proxying.
   * @param id The id of the texture resource.
   */
  private async getTextureAsyncDev(id: string): Promise<Blob> {
    const request: AxiosRequestConfig = {
      baseURL: EnvConfig.BackendBaseUrl,
      url: "/GetTextureAsync/stream?id=" + id,
      method: "get",
      headers: { Authorization: "Bearer " + EnvConfig.BackendAuthToken },
      responseType: "blob"
    };
    const response: AxiosResponse = await axios.request(request);
    return response.data as Blob;
  }

  /**
 * Gets the asset resource asyncronously directly without proxying.
 * @param id The id of the asset resource.
 */
  private async getAssetAsyncDev(id: string): Promise<Blob> {
    const request: AxiosRequestConfig = {
      baseURL: EnvConfig.BackendBaseUrl,
      url: "/GetAssetAsync/stream?id=" + id,
      method: "get",
      headers: { Authorization: "Bearer " + EnvConfig.BackendAuthToken },
      responseType: "blob"
    };
    const response: AxiosResponse = await axios.request(request);
    return response.data as Blob;
  }

}

export class ProxyBackend {

  /** The proxy instance. */
  private proxy!: Viewer3DProxy;
  /** Max retry count for bin and textures resources. */
  private maxRetryCount: number = 3;

  /**
   * Creates a new instance of this class.
   * @param proxy The proxy object.
   */
  constructor(proxy: Viewer3DProxy) {
    if (proxy) {
      log.debug("Proxy was provided, using it");
      this.proxy = proxy;
    }
    else {
      log.warn("Proxy was not provided, using dev proxy");
    }
  }

  /** Gets all the assets descriptors asyncronously from the proxy (if provided) or directly. */
  public async getAllAssetsAsync(): Promise<AssetDescriptor[]> {
    return this.proxy ?
      await firstValueFrom(this.proxy.getAllAssetsAsync())
      : await this.getAllAssetsAsyncDev();
  }

  /**
   * Gets the bin resource asyncronously from the proxy (if provided) or directly.
   * @param id The id of the bin resource.
   */
  public async getAssetAsync(id: string): Promise<Blob> {
    return this.proxy ?
      await firstValueFrom(this.proxy.getAssetAsync(id))
      : await this.getAssetAsyncDev(id);
  }

  /**
   * Try to execute the provided function a predefined numer of times.
   * @param requestFn The async function to execute.
   * @param iter The current iteration count.
   */
  private async tryRequest(requestFn: () => Promise<Blob>, iter: number = 0): Promise<Blob> {
    const retrySec: number = Math.pow(3, iter);
    try {
      return await requestFn();
    }
    catch (error) {
      const errorMessage: string = ErrorUtils.GetErrorMessage(error);
      if (iter < this.maxRetryCount) {
        log.warn(`Request failed, will try again in ${retrySec} seconds ` + errorMessage);
        await this.waitSeconds(retrySec);
        return this.tryRequest(requestFn, iter + 1);
      }
      else {
        throw new Error("Request failed, reached max retry count");
      }
    }
  }

  /**
   * Wait the specified number of seconds.
   * @param seconds The number of seconds to wait.
   */
  private waitSeconds(seconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, seconds * 1e3));
  }

  /**
   * Method to reject the promise if the backend response is faulted.
   * @param response The response to check.
   * @param resolve The promise resolve function.
   * @param reject The promise reject function.
   */
  private responseHandler(response: AxiosResponse): any {
    const data: any = response.data;
    if (!data.isFaulted)
      return data.result;
    else
      throw new Error(data);
  }

  /** Gets all the assets descriptors asyncronously directly without proxying. */
  private async getAllAssetsAsyncDev(): Promise<AssetDescriptor[]> {
    const request: AxiosRequestConfig = {
      baseURL: EnvConfig.BackendBaseUrl,
      url: "/GetAllAssetsAsync",
      method: "post",
      data: {},
      headers: { Authorization: "Bearer " + EnvConfig.BackendAuthToken }
    };
    const response: AxiosResponse = await axios.request(request);
    return this.responseHandler(response) as AssetDescriptor[];
  }

  /**
   * Gets the asset resource asyncronously directly without proxying.
   * @param id The id of the asset resource.
   */
  private async getAssetAsyncDev(id: string): Promise<Blob> {
    const request: AxiosRequestConfig = {
      baseURL: EnvConfig.BackendBaseUrl,
      url: "/GetAssetAsync/stream?id=" + id,
      method: "get",
      headers: { Authorization: "Bearer " + EnvConfig.BackendAuthToken },
      responseType: "blob"
    };
    const response: AxiosResponse = await axios.request(request);
    return response.data as Blob;
  }

}
