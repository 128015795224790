/** @module managers */
import { log } from "../helpers/Logger";
import { PerspectiveCamera, Vector3 } from "three";
import { SavedView } from "../models/SavedView";
import { SceneManager } from "./SceneManager";

/** This class is responsible for the management of the views. It allows a view to be saved/loaded. */
export class ViewManager {

  /** The available views, the key is the view id, the value is the saved view data. */
  private viewes: Map<string, SavedView>;
  /** The scene manager instance. */
  private sceneManager: SceneManager;

  /**
   * Creates a new instance.
   * @param sceneManager The scene manager.
   */
  public constructor(sceneManager: SceneManager) {
    this.sceneManager = sceneManager;
    this.viewes = new Map();
    this.viewes.set("Malamocco", new SavedView(new Vector3(-620.96, 145.85, 282.13), new Vector3(-543.81, 0, 436.16)));
    this.viewes.set("Chioggia", new SavedView(new Vector3(-3472.28, 102.45, 11923.97), new Vector3(-3360.99, 0, 11819.49)));
    this.viewes.set("Lido", new SavedView(new Vector3(6278.25, 68.27, -10271.92), new Vector3(6394.23, 0, -10319.01)));
  }

  /**
   * Saves current view.
   * @param name Optional view name.
   */
  public saveCurrentView(name?: string): string {
    const camera: PerspectiveCamera = this.sceneManager.getMainCamera();
    const viewName: string = name ? name : camera.name + this.viewes.size;
    const savedView: SavedView = new SavedView(camera.position.clone(), this.sceneManager.getOrbitControls().target.clone());
    this.viewes.set(viewName, savedView);
    return viewName;
  }

  /**
   * Gets the specified saved view.
   * @param name The name of the view.
   */
  public getView(name: string): SavedView | null {
    return this.viewes.has(name) ?
      this.viewes.get(name) as SavedView
      : null;
  }

  /**
   * Loads the specified view by name.
   * @param name The name of the saved view.
   */
  public loadViewFromName(name: string): void {
    const savedView: SavedView | undefined = this.viewes.get(name);
    if (savedView) {
      this.sceneManager.setMainCameraView(savedView.position, savedView.target);
    }
    else {
      log.warn(`Cannot load view ${name}`);
    }
  }

}

