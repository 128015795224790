/**
 * This module contains the models like interfaces for data definition.
 * @module models
 */
import { AnimationClip, Mesh, AnimationAction, AnimationMixer } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { LoadedAsset } from "./LoadedAsset";

/**
 * This class holds the asset animations.
 * See https://threejs.org/docs/#manual/en/introduction/Animation-system.
 */
export class AnimationDescriptor {

  /** Stores all the asset animation clips */
  public animationClips: Map<string, AnimationClip>;
  /** Stores all the asset animation actions */
  public animationActions: Map<string, AnimationAction>;
  /** Stores all the asset animation mixers */
  public animationMixers: Map<string, AnimationMixer>;

  /**
   * Creates a new instance.
   * @param loadedAsset The loaded asset.
   */
  public constructor(loadedAsset: LoadedAsset) {
    this.animationClips = new Map();
    this.animationActions = new Map();
    this.animationMixers = new Map();
    const mesh: Mesh = loadedAsset.getScene().children.filter(child => child instanceof Mesh)[0] as Mesh;
    for (const animation of loadedAsset.getAsset().animations) {
      const animationMixer: AnimationMixer = new AnimationMixer(mesh);
      const animationAction: AnimationAction = animationMixer.clipAction(animation, loadedAsset.getScene());
      this.animationClips.set(animation.name, animation);
      this.animationMixers.set(animation.name, animationMixer);
      animationAction.play();
      animationAction.paused = true;
      this.animationActions.set(animation.name, animationAction);
    }
  }

}
