/**
 * This module contains all managers code which pertains various activities.
 * @module managers
 */
import { AnimationAction, AnimationMixer, MathUtils as TMath } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { log } from "../helpers/Logger";
import { AnimationDescriptor } from "../models/AnimationDescriptor";
import { LoadedAsset } from "../models/LoadedAsset";


/** This class is responsible for holding the assets animations and for managing their updates. */
export class AnimationManager {

  /** The assets animations map. The key is the asset id, the value is the asset animation descriptor. */
  private animationsMap: Map<string, AnimationDescriptor>;

  /** Creates a new instance. */
  public constructor() {
    this.animationsMap = new Map();
  }

  /**
   * Pushes an animation into the animations map.
   * @param assetId The asset id.
   * @param loadedAsset The loaded asset.
   */
  public pushAnimations(assetId: string, loadedAsset: LoadedAsset): void {
    const animationDescriptor: AnimationDescriptor = new AnimationDescriptor(loadedAsset);
    this.animationsMap.set(assetId, animationDescriptor);
  }

  /**
   * Updates the asset animation.
   * @param objectId The asset id.
   * @param value The animation value (progression value).
   * @param animationId The optinal animation id, takes the default if not provided.
   */
  public updateAnimation(objectId: string, value: number, animationId?: string): void {
    const animationDescriptor: AnimationDescriptor | undefined = this.animationsMap.get(objectId);
    if (animationDescriptor) {
      const animationAction: AnimationAction | undefined = animationDescriptor.animationActions.get(animationId ? animationId : animationDescriptor.animationActions.keys().next().value);
      const animationMixer: AnimationMixer | undefined = animationDescriptor.animationMixers.get(animationId ? animationId : animationDescriptor.animationActions.keys().next().value);
      if (animationAction && animationMixer) {
        let time: number = 0;
        time = TMath.mapLinear(value, 0, 1, 0, animationAction.getClip().duration - 0.001);
        animationAction.reset();
        animationMixer.update(time);
      }
    }
    else {
      log.warn("animationDescriptor not found for " + objectId);
    }
  }
}
