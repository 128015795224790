/** @module services */

import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState } from "@microsoft/signalr";
import { log } from "../helpers/Logger";
import { EnvConfig } from "../helpers/EnvConfig";
import { BusBackend } from "./BusBackend";
import { SignalRConnUpdatedOutputMessage } from "../models/BusOutputMessage";
import { SignalRInputSerializedMessage } from "../models/SignalRInputMessage";


/** This class is responsible for managing the SignalR connection. */
export class SignalRBackend {

  /** The bus backend. */
  private busBackend: BusBackend;
  /** The hub connection. */
  private connection: HubConnection | undefined;

  /**
   * Creates a new SignalRBackend instance.
   * @param messageHandler The message handler function.
   * @param busBackend The bus needed for the publish of events.
   */
  constructor(messageHandler: (message: SignalRInputSerializedMessage) => void, busBackend: BusBackend) {
    this.busBackend = busBackend;
    if (EnvConfig.SignalRUrl) {
      this.connection = new HubConnectionBuilder()
        .withUrl(EnvConfig.SignalRUrl)
        .configureLogging(LogLevel.Information)
        .build();
      this.connection.on("NotifyMessage", messageHandler);
      this.connection.onclose(() => {
        this.disconnectedHandler.bind(this);
        this.startConnection();
      });

      this.startConnection();
    }
  }

  /** Returns the hub connection state. */
  public getIsConnected(): boolean {
    let isConnected: boolean = false;
    if (this.connection) {
      log.debug(`SignalR connection state: ${HubConnectionState[this.connection.state]}`);
      isConnected = this.connection.state === HubConnectionState.Connected;
    }
    return isConnected;
  }

  /** Starts connecting asyncronously to the SignalR backend. Returns false if the connection failed. */
  private async startConnection(): Promise<boolean> {
    let isConnected: boolean = false;
    if (this.connection) {
      try {
        await this.connection.start();
        isConnected = this.connection.state === HubConnectionState.Connected;
        log.debug(`SignalR connected: ${isConnected}`);
        if (isConnected)
          this.busBackend.publish(new SignalRConnUpdatedOutputMessage({ isConnected: true }));
      }
      catch (error) {
        this.disconnectedHandler();
      }
    }
    return isConnected;
  }

  /** The disconnected handler function. */
  private disconnectedHandler(): void {
    log.warn(`SignalR connection failed`);
    this.busBackend.publish(new SignalRConnUpdatedOutputMessage({ isConnected: false }));
  }

}
