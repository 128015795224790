/**
 * This module contains code related to the asset loading activities.
 * @module loaders
 */
import { Object3D, Vector3, Scene, Mesh, TextureLoader, RepeatWrapping, Group } from "three";
import PQueue from "p-queue";
import { log } from "../helpers/Logger";
import { GLTFLoader as GLTFLoaderExtended } from "./GLTFLoader";
import { LoadedAssetDescriptor } from "../models/LoadedAssetDescriptor";
import { AnimationManager } from "../managers/AnimationManager";
import { SceneManager } from "../managers/SceneManager";
import { DebugGUIManager } from "../managers/DebugGUIManager";
import { ProxyBackendLegacy, ProxyBackend } from "../services/ProxyBackend";
import { AssetDescriptor, GLTFResponse } from "../models/Viewer3DProxy";
import { Water } from "../objects/Water";
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { ErrorUtils } from "../helpers/ErrorUtils";
import { IFCLoader, IFCModel } from "three/examples/jsm/loaders/IFCLoader";
import { AssetType, LoadedAsset } from "../models/LoadedAsset";
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from "three-mesh-bvh";
import { BusBackend } from "../services/BusBackend";
import { SceneLoadedOutputMessage } from "../models/BusOutputMessage";
import { EnvConfig } from "../helpers/EnvConfig";


/**
 * This class is responsible for the loading of the assets. Assets are loaded from
 * the backend using a parallel queue (default parallelism is 8).
 */
export class AssetLoader {

  /** Reference to the debug gui manager. */
  private debugGuiManager!: DebugGUIManager;
  /** Reference to the scene manager. */
  private sceneManager: SceneManager;
  /** Reference to the proxy backend. */
  private proxyBackend: ProxyBackendLegacy | ProxyBackend;
  /** Reference to the bus backend. */
  private busBackend: BusBackend;
  /** Determines if legacy proxy has to be used. */
  private useLegacyProxy: boolean;
  /** The parallel queue. */
  private queue: PQueue;
  /** The number of processed item. */
  private processingTaskCount: number;
  /** The GLTF extended loader instance. */
  private gltfLoaderExtended: GLTFLoaderExtended | undefined;
  /** The GLTF loader from the three library. */
  private gltfLoader: GLTFLoader | undefined;
  /** The asset descriptor array. */
  private assetDescriptors: AssetDescriptor[];
  /** The array of object urls, textures loaded as blobs. */
  private objectURLs: string[];
  /** The asset map, the key is the asset id, the value is an object containing both the parsed gltf asset and its descriptor. */
  private assetMap: Map<string, LoadedAssetDescriptor>;
  /** The IFC loader from the three library */
  private ifcLoader: IFCLoader;

  /**
   * Creates a new instance.
   * @param sceneManager The scene manager instance.
   * @param proxyBackend The proxy backend instance.
   * @param busBackend The bus backend instance.
   */
  constructor(sceneManager: SceneManager, proxyBackend: ProxyBackendLegacy | ProxyBackend, busBackend: BusBackend) {
    this.sceneManager = sceneManager;
    this.proxyBackend = proxyBackend;
    this.busBackend = busBackend;
    this.queue = this.createParallelQueue(8);
    this.processingTaskCount = 0;

    if (proxyBackend instanceof ProxyBackendLegacy) {
      this.gltfLoaderExtended = new GLTFLoaderExtended(this, proxyBackend, true, false);
      this.gltfLoader = new GLTFLoader();
      this.useLegacyProxy = true;
    } else {
      this.gltfLoader = new GLTFLoader();
      this.useLegacyProxy = false;
    }

    this.assetDescriptors = [];
    this.objectURLs = [];
    this.assetMap = new Map();

    this.ifcLoader = new IFCLoader();
    this.ifcLoader.ifcManager.setWasmPath('./');
    this.ifcLoader.ifcManager.setupThreeMeshBVH(computeBoundsTree, disposeBoundsTree, acceleratedRaycast);
  }

  /** Gets the IFC loader. */
  public getIfcLoader(): IFCLoader {
    return this.ifcLoader;
  }

  /** Gets the asset map. */
  public getAssetMap(): Map<string, LoadedAssetDescriptor> {
    return this.assetMap;
  }

  /**
   * Adds an object url to the object url array.
   * @param objectURL The object url to add.
   */
  public addBlobURL(objectURL: string): void {
    this.objectURLs.push(objectURL);
  }

  /**
   * Set the debug gui manager.
   * @param manager The debug gui manager instance.
   */
  public setDebugGUIManager(manager: DebugGUIManager): void {
    this.debugGuiManager = manager;
  }

  /** Gets the asset descriptor array. */
  public getAssetDescriptors(): AssetDescriptor[] {
    return this.assetDescriptors;
  }

  /** Loads the scene by fetching the descriptors, then adds each asset task on the parallel queue for loading. */
  public async loadSceneAsync(): Promise<void> {
    this.assetDescriptors = await this.proxyBackend.getAllAssetsAsync();
    log.info("assetDescriptors length: " + this.assetDescriptors.length);
    this.assetDescriptors = this.assetDescriptors.sort(this.compareDescriptorsByDistance.bind(this));
    for (let i: number = 0; i < this.assetDescriptors.length; i++) {
      const assetDescriptor: AssetDescriptor = this.assetDescriptors[i];
      this.queue.add((() => this.processAsset(assetDescriptor, i + 1)).bind(this));
    }
  }

  /** Utility method to process asset depending on normal or legacy behavior.
   * @param assetDescriptor The asset descriptor to process
   * @param taskId The id of the task
   */
  private async processAsset(assetDescriptor: AssetDescriptor, taskId: number): Promise<void> {
    return this.useLegacyProxy ?
      this.loadGltfAsync(assetDescriptor, taskId)
      : this.loadAssetAsync(assetDescriptor, taskId);
  }

  /** Loads an asset and its resources using the library GLTFLoader, assumes the asset is self-contained.
   * @param assetDescriptor The asset descriptor to process
   * @param taskId The id of the task
   */
  private async loadAssetAsync(assetDescriptor: AssetDescriptor, taskId: number): Promise<void> {
    if (assetDescriptor.geometryId !== null && this.gltfLoader) {
      try {
        const extension: string | undefined = assetDescriptor.geometryId.split('.').pop();
        if (extension) {
          const asset: Blob = await (this.proxyBackend as ProxyBackend).getAssetAsync(assetDescriptor.geometryId);
          const buffer: ArrayBuffer = await asset.arrayBuffer();
          let loadedAsset: LoadedAsset | undefined;
          if (extension === 'glb') {
            const model: GLTF = await this.gltfLoader.parseAsync(buffer, "");
            loadedAsset = new LoadedAsset(model, AssetType.GLB);
          }
          if (extension === 'ifc') {
            const model: IFCModel = await this.ifcLoader.parse(buffer);
            loadedAsset = new LoadedAsset(model, AssetType.IFC);
          }
          if (loadedAsset) {
            if (assetDescriptor.isDynamicAsset != null && assetDescriptor.isDynamicAsset) {
              this.sceneManager.getDynamicAssetManager().setTemplateModel(assetDescriptor.id, loadedAsset);
            }
            else {
              this.assetMap.set(assetDescriptor.id, { loadedAsset, descriptor: assetDescriptor });
              this.alterAssetRecursive(assetDescriptor, loadedAsset.getScene());
              this.addAssetAnimations(assetDescriptor, loadedAsset);
              this.addAssetToScene(assetDescriptor, loadedAsset);
            }
          }
        }
      }
      catch (error) {
        const errorMessage: string = ErrorUtils.GetErrorMessage(error);
        log.warn(`Asset ${assetDescriptor.title} loading failed (id: ${assetDescriptor.id}) (taskId: ${taskId}) ${errorMessage}`);
        this.debugGuiManager.showToastMessage(`${assetDescriptor.title} loading failed`);
      }
    }
    if (taskId === this.assetDescriptors.length) {
      this.busBackend.publish(new SceneLoadedOutputMessage({}));
      this.disposeBlobs();
    }
  }


  /**
   * Loads a gltf asset and its resources, alters if necessary, then adds to the scene.
   * @param assetDescriptor The asset descriptor.
   * @param taskId The progressive task id used for identification.
   */
  private async loadGltfAsync(assetDescriptor: AssetDescriptor, taskId: number): Promise<void> {
    if (assetDescriptor.geometryId !== null && this.gltfLoaderExtended) {
      try {
        const gltfResponse: GLTFResponse[] = await (this.proxyBackend as ProxyBackendLegacy).getGltfsAsync([assetDescriptor.geometryId]);
        if (gltfResponse && gltfResponse.length > 0) {
          const gltf: string = gltfResponse[0].data;
          const parsedGltf: GLTF = await this.gltfLoaderExtended.parseAsync(gltf);
          const loadedAsset: LoadedAsset = new LoadedAsset(parsedGltf, AssetType.GLB);
          if (assetDescriptor.isDynamicAsset != null && assetDescriptor.isDynamicAsset) {
            this.sceneManager.getDynamicAssetManager().setTemplateModel(assetDescriptor.id, loadedAsset);
          }
          else {
            this.assetMap.set(assetDescriptor.id, { loadedAsset, descriptor: assetDescriptor });
            this.alterAssetRecursive(assetDescriptor, loadedAsset.getScene());
            this.addAssetAnimations(assetDescriptor, loadedAsset);
            this.addAssetToScene(assetDescriptor, loadedAsset);
          }

        }
        else {
          throw new Error("empty gltf response");
        }
      }
      catch (error) {
        const errorMessage: string = ErrorUtils.GetErrorMessage(error);
        log.warn(`${assetDescriptor.title} gltf loading failed (id: ${assetDescriptor.id}) (taskId: ${taskId}) ${errorMessage}`);
        this.debugGuiManager.showToastMessage(`${assetDescriptor.title} gltf loading failed`);
      }
    }
    if (taskId === this.assetDescriptors.length) {
      this.disposeBlobs();
    }
  }

  /**
   * Alters an asset recursively on its sub-components.
   * @param assetDescriptor The asset descriptor.
   * @param object The object to be modified.
   */
  private alterAssetRecursive(assetDescriptor: AssetDescriptor, object: Object3D): void {
    for (let i: number = 0; i < object.children.length; i++) {
      const child: Object3D = object.children[i];
      if (child.type === "Mesh") {
        if (assetDescriptor.title.includes("MARE")) {
          object.children[i] = this.createWaterFromAsset(child as Mesh, assetDescriptor);
        }
        else if (assetDescriptor.title.includes("SABBIA")) {
          child.layers.set(2);
        }
        else if (assetDescriptor.title.includes("BASAMENT")) {
          child.layers.set(1);
        }
        else {
          child.layers.set(0);
        }
        (child as any).material.envMap = this.sceneManager.getEnvMap();
        child.castShadow = true;
        child.receiveShadow = true;
        child.userData.id = assetDescriptor.id;
      }
      if (child.children && child.children.length > 0) {
        this.alterAssetRecursive(assetDescriptor, child);
      }
    }
  }

  /**
   * Adds the asset animations to the animation manager.
   * @param assetDescriptor The asset descriptor.
   * @param loadedAsset The loaded asset.
   */
  private addAssetAnimations(assetDescriptor: AssetDescriptor, loadedAsset: LoadedAsset): void {
    if (loadedAsset.getAsset().animations.length > 0) {
      const animationManager: AnimationManager = this.sceneManager.getAnimationManager();
      animationManager.pushAnimations(assetDescriptor.id, loadedAsset);
      this.debugGuiManager.pushAnimations(assetDescriptor, animationManager, loadedAsset.getAsset().animations);
    }
  }

  /**
   * Adds an asset to the main scene.
   * @param assetDescriptor The asset descriptor.
   * @param loadedAsset The loaded asset.
   */
  private addAssetToScene(assetDescriptor: AssetDescriptor, loadedAsset: LoadedAsset): void {
    const assetScene: Group = loadedAsset.getScene();
    assetScene.position.add(new Vector3(assetDescriptor.pose.pos.x, assetDescriptor.pose.pos.y, assetDescriptor.pose.pos.z));
    // this.sceneManager.getDynamicAssetManager().rotateAroundWorldAxis(assetScene); // MOSE LEGACY GPS
    const scene: Scene = this.sceneManager.getScene();
    scene.add(assetScene);
  }

  /**
   * Creates water from asset geometry.
   * @param object The mesh object to take the geometry from.
   * @param assetDescriptor The asset descriptor.
   */
  private createWaterFromAsset(object: Mesh, assetDescriptor: AssetDescriptor): Water {
    const waterOptions: object = {
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: new TextureLoader()
        .setPath(EnvConfig.StaticFilesPath + "textures/")
        .load("waternormals.jpg", texture => {
          texture.wrapS = texture.wrapT = RepeatWrapping;
        }),
      alpha: 0.9,
      sunDirection: this.sceneManager.getDirectionalLight().position.clone().normalize(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: this.sceneManager.getScene().fog !== undefined,
      mask: 1 << 1,
      mirrorCameraMask: -1 ^ (1 << 5) ^ (1 << 2) ^ (1 << 3),
      morphTargets: assetDescriptor.title !== "MARE"
    };
    const water: Water = new Water(object.geometry, waterOptions);
    water.layers.set(1);
    this.sceneManager.pushWater(water);
    this.debugGuiManager.addWaterItems(water, object.name);
    water.name = object.name;
    water.parent = object.parent;
    water.renderOrder = -1;
    return water;
  }

  /** Revoke the objects url for each reference in the object url array. */
  private disposeBlobs(): void {
    setTimeout(() => {
      this.objectURLs.forEach(objectURL => URL.revokeObjectURL(objectURL));
    }, 3000);
  }

  /**
   * Function to compare two asset descriptors based on the distance from the camera position.
   * @param assetDescriptor1 The first asset descriptor to compare.
   * @param assetDescriptor2 The second asset descriptor to compare.
   */
  private compareDescriptorsByDistance(assetDescriptor1: AssetDescriptor, assetDescriptor2: AssetDescriptor): number {
    const cameraPosition: Vector3 = this.sceneManager.getMainCamera().position.clone();
    const distance1: number = cameraPosition.clone().sub(new Vector3(assetDescriptor1.pose.pos.x, assetDescriptor1.pose.pos.y, assetDescriptor1.pose.pos.z)).length();
    const distance2: number = cameraPosition.clone().sub(new Vector3(assetDescriptor2.pose.pos.x, assetDescriptor2.pose.pos.y, assetDescriptor2.pose.pos.z)).length();
    if (distance1 > distance2) {
      return 1;
    }
    if (distance1 < distance2) {
      return -1;
    }
    return 0;
  }

  /**
   * Creates the parellel queue.
   * @param queueSlots Number of slots to be executed in parallel (concurrency).
   */
  private createParallelQueue(queueSlots: number): PQueue {
    const queue: PQueue = new PQueue({ concurrency: queueSlots });
    queue.on("active", () => {
      if (this.processingTaskCount > this.assetDescriptors.length) {
        this.processingTaskCount = 0;
      }
      this.processingTaskCount++;
      const progress: number = this.processingTaskCount / this.assetDescriptors.length;
      log.debug(`progress: ${progress}, processingTaskCount: ${this.processingTaskCount} / assetDescriptors.length: ${this.assetDescriptors.length}`);
      // const progress: number = this.processingItemCount / (queue.size + (this.processingItemCount + queue.pending) - queueSlots);
      // log.debug(`progress: ${progress}, queue.size: ${queue.size}, processingItemCount: ${this.processingItemCount}, queue.pending: ${queue.pending}, queueSlots: ${queueSlots}`);
      if (this.debugGuiManager) {
        const progressMax: number = progress > 1 ? 1 : progress;
        this.debugGuiManager.updateLoadingBar(progressMax);
      }
      // if (progress === 1) {
      //   this.processingItemCount = 0;
      // }
    });
    return queue;
  }

}
