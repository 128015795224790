/**
 * This module contains code related to the backend services.
 * @module services
 */
import { Subject, Observable } from "rxjs";
import { log } from "../helpers/Logger";
import { Viewer3DBus } from "../models/Viewer3DBus";
import { BusOutputMessage, BusOutputSerializedMessage } from "../models/BusOutputMessage";
import { BusInputSerializedMessage, BusInputMessage } from "../models/BusInputMessage";

/**
 * This class is responsible for the bus logic. If a bus is provided at the time of creation of the Viewer3D instance,
 * the provided bus will be used, otherwise two local subjects are used.
 */
export class BusBackend {

  /** The id of this Viewer3D instance. */
  private instanceId: number;
  /** The bus passed in the config at the time of Viewer3D instance creation. */
  private bus!: Viewer3DBus;
  /** The dev input bus used if no bus is passed at the time of Viewer3D instance creation. */
  private devBusViewer!: Subject<BusInputSerializedMessage>;
  /** The dev output bus used if no bus is passed at the time of Viewer3D instance creation. */
  private devBusBrowser!: Subject<BusOutputSerializedMessage>;

  /**
   * Creates a new instance of this class.
   * @param instanceId The id of the Viewer3D instance
   * @param bus The bus passed at the time of Viewer3D instance creation.
   */
  constructor(instanceId: number, bus: Viewer3DBus) {
    this.instanceId = instanceId;
    if (bus) {
      log.debug("Bus was provided, using it");
      this.bus = bus;
    }
    else {
      log.warn("Bus was not provided, using dev bus");
      this.devBusViewer = new Subject<BusInputSerializedMessage>();
      this.devBusBrowser = new Subject<BusOutputSerializedMessage>();
    }
  }

  /**
   * Subscribes to the input bus, using provided handlers.
   * @param messageHandler The message handler function
   * @param errorHandler The error handler function
   * @param completedHandler The completed handler function
   */
  public subscribe(
    messageHandler: (message: BusInputSerializedMessage) => void,
    errorHandler: (error: Error) => void,
    completedHandler: () => void
  ): void {
    const observable: Observable<BusInputSerializedMessage> = this.bus ?
      this.bus.getViewerMessage() :
      this.devBusViewer.asObservable();
    observable.subscribe(messageHandler, errorHandler, completedHandler);
  }

  /**
   * Publishes the provided message on the output bus.
   * @param outputMessage The message to be published
   */
  public publish(outputMessage: BusOutputMessage): void {
    const method: string = outputMessage.method;
    const params: string = JSON.stringify(outputMessage.params);
    log.trace(`Message published on output bus ${JSON.stringify({ method, params, instanceId: this.instanceId })}`);
    if (this.bus)
      this.bus.sendBrowserMessage(method, params, this.instanceId);
    else
      this.devBusBrowser.next({ method, params, instanceId: this.instanceId });
  }

  /**
   * Publishes the provided message on the input bus, used for testing the input api.
   * @param inputMessage The message to be published
   */
  public publishDebug(inputMessage: BusInputMessage): void {
    const method: string = inputMessage.method;
    const params: string = JSON.stringify(inputMessage.params);
    log.trace(`Message published on input bus ${JSON.stringify({ method, params, instanceId: this.instanceId })}`);
    if (this.bus)
      this.bus.sendViewerMessage(method, params, this.instanceId);
    else
      this.devBusViewer.next({ method, params, instanceId: this.instanceId });
  }

}
