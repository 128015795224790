/**
 * This module contains code at the top/root level.
 * @module root
 */
import { EnvConfig } from "./helpers/EnvConfig";
EnvConfig.Load();
import { Viewer3D } from "./Viewer3D";

/** Exports the Viewer3D main class so the client code can instantiate the viewer. */
module.exports = Viewer3D;
