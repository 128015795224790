/** @module managers */
import { Vector2, PerspectiveCamera, WebGLRenderer } from "three";
import { log } from "../helpers/Logger";
import { MinimapManager } from "./MinimapManager";
import { DebugGUIManager } from "./DebugGUIManager";
import { MarkerManager } from "./MarkerManager";
import { SceneManager } from "./SceneManager";
import { RenderManager } from "./RenderManager";
import { ViewFrustum } from "../helpers/ViewFrustum";
import { PostProcessingManager } from "./PostProcessingManager";
import { AudioManager } from "./AudioManager";
import { BusBackend } from "../services/BusBackend";
import { Viewer3DConfiguration } from "../models/Viewer3DConfiguration";
import { Compass } from "../gui/Compass";
import { AssetSelectedOutputMessage } from "../models/BusOutputMessage";
import { AssetDescriptor } from "../models/Viewer3DProxy";


/**
 * This class is responsible for the management of the gui components,
 * i.e compass, minimap, markers, debug gui.
 */
export class GUIManager {

  /** The viewer 3d container html element. */
  private container: HTMLElement;
  /** The viewer 3d configuration. */
  private config: Viewer3DConfiguration;
  /** The audio manager. */
  private audioManager: AudioManager;
  /** The scene manager. */
  private sceneManager!: SceneManager;
  /** The render manager. */
  private renderManager!: RenderManager;
  /** The postprocessing manager. */
  private postprocessingManager!: PostProcessingManager;
  /** The debug gui manager. */
  private debugGUIManager: DebugGUIManager;
  /** The compass. */
  private compass: Compass;
  /** The minimap manager. */
  private minimapManager!: MinimapManager;
  /** The marker manager. */
  private markerManager: MarkerManager;
  /** The bus backend service. */
  private busBackend!: BusBackend;
  /** True if mouse button is clicked down, false otherwise. */
  private isMouseDown: boolean;
  /** The position of the last mouse click. */
  private mouseDownPosition: Vector2;
  /** True if the mouse is being dragged, false otherwise. */
  private isMouseDrag: boolean;
  /** The mouse moive timeout timer used for the selection of assets on mouse hover. */
  private mouseMoveTimeoutId: NodeJS.Timeout | null;
  /** The last touch event. */
  private lastTouchEvent!: TouchEvent;
  /** The current touch event. */
  private firstTouchEvent!: TouchEvent;


  /**
   * Creates a new instance.
   * @param config The viewer configuration.
   */
  constructor(config: Viewer3DConfiguration) {
    this.container = this.findContainer(config.gui.domContainerId);
    this.config = config;
    this.audioManager = new AudioManager(config.audio);
    this.isMouseDown = false;
    this.mouseDownPosition = new Vector2();
    this.isMouseDrag = false;
    this.mouseMoveTimeoutId = null;
    this.compass = new Compass();
    this.minimapManager = new MinimapManager(this);
    this.markerManager = new MarkerManager();
    this.debugGUIManager = new DebugGUIManager(this);
    this.container.appendChild(this.compass.getContainer());
    this.container.appendChild(this.minimapManager.getContainer());
    this.container.appendChild(this.markerManager.getContainer());
    this.container.appendChild(this.debugGUIManager.getLoadingBarContainer());
    if (config.gui.isDebugGUIEnabled) {
      this.container.appendChild(this.debugGUIManager.getDatGuiContainer());
    }
  }

  /** Gets the viewer configuration. */
  public getConfig(): Viewer3DConfiguration {
    return this.config;
  }

  /** Gets the audio manager. */
  public getAudioManager(): AudioManager {
    return this.audioManager;
  }

  /** Gets the debug gui manager. */
  public getDebugGuiManager(): DebugGUIManager {
    return this.debugGUIManager;
  }

  /** Gets the compass. */
  public getCompass(): Compass {
    return this.compass;
  }

  /** Gets the marker manager */
  public getMarkerManager(): MarkerManager {
    return this.markerManager;
  }

  /** Gets the isMouseDrag state. */
  public getIsMouseDrag(): boolean {
    return this.isMouseDrag;
  }

  /** Gets the minimap manager */
  public getMinimapManager(): MinimapManager {
    return this.minimapManager;
  }

  /** Gets the debug gui manager */
  public getdebugGUIManager(): DebugGUIManager {
    return this.debugGUIManager;
  }

  /** Gets the main viewer 3d container. */
  public getContainer(): HTMLElement {
    return this.container;
  }

  /**
   * Sets the bus backend service.
   * @param busBackend The bus backend service.
   */
  public setBusBackend(busBackend: BusBackend): void {
    this.busBackend = busBackend;
    this.markerManager.setBusBackend(busBackend);
    this.debugGUIManager.setBusBackend(busBackend);
  }

  /**
   * Sets the scene manager.
   * @param sceneManager The scene manager instance.
   */
  public setSceneManager(sceneManager: SceneManager): void {
    this.sceneManager = sceneManager;
    this.minimapManager.setSceneManager(sceneManager);
    this.markerManager.setSceneManager(sceneManager);
    this.debugGUIManager.setSceneManager(sceneManager);
  }

  /**
   * Sets the render manager.
   * @param renderManager The render manager.
   */
  public setRenderManager(renderManager: RenderManager): void {
    this.renderManager = renderManager;
  }

  /**
   * Sets the postprocessing manager.
   * @param postprocessingManager The postprocessing manager.
   */
  public setPostProcessingManager(postprocessingManager: PostProcessingManager): void {
    this.postprocessingManager = postprocessingManager;
  }

  /** Adds the mouse, keyboard and window event listeners. */
  public addEventListeners(): void {
    window.addEventListener("resize", this.onWindowResize.bind(this), false);
    window.addEventListener("mousedown", this.onMouseDown.bind(this), false);
    window.addEventListener("mouseup", this.onMouseUp.bind(this), false);
    window.addEventListener("mousemove", this.onMouseMove.bind(this), false);
    window.addEventListener("touchstart", this.onTouchStart.bind(this), false);
    window.addEventListener("touchend", this.onTouchEnd.bind(this), false);
    window.addEventListener("touchmove", this.onTouchMove.bind(this), false);
    window.addEventListener("keydown", this.onKeyDown.bind(this), false);
  }

  /**
   * Sets the enabled state of the orbit controls.
   * @param isEnabled Determines the enabled state of the orbit controls (main and minimap), true enable them, false disable them.
   */
  public setOrbitControlsEnabled(isEnabled: boolean): void {
    this.sceneManager.getOrbitControls().enabled = isEnabled;
    this.minimapManager.getMinimapOrbitControls().enabled = isEnabled;
    log.debug(`GUIManager orbitControlsEnabled ${isEnabled}`);
  }

  /**
   * Fixes space coordinates from screen coordinates taking into account the containers margins (viewer not at fullscreen size).
   * @param container The container html element.
   * @param point The point in screen coordinates.
   */
  public fixScreenSpaceCoordinatesFromScreen(container: HTMLElement, point: Vector2): Vector2 {
    return new Vector2(point.x - container.getBoundingClientRect().left, (point.y - container.getBoundingClientRect().top));
  }


  /**
   * The key down handler function (for zooming with keys period and comma on the minimap).
   * @param event The keyboard event.
   */
  private onKeyDown(event: KeyboardEvent): void {
    switch (event.code) {
      case "Period":
        this.minimapManager.getMinimapOrbitControls().dollyIn();
        break;
      case "Comma":
        this.minimapManager.getMinimapOrbitControls().dollyOut();
        break;
    }
  }

  /**
   * The asset selection handler function.
   * @param x The x screen coordinate.
   * @param y The y screen coordinate.
   */
  private selectAsset(x: number, y: number): void {
    if (this.renderManager.getIsRenderingEnabled()) {
      const fixedClick: Vector2 = this.fixScreenSpaceCoordinatesFromScreen(this.container, new Vector2(x, y));
      const mouseClickCoord: Vector2 = new Vector2((fixedClick.x / this.container.offsetWidth) * 2 - 1, - (fixedClick.y / this.container.offsetHeight) * 2 + 1);
      const selectedAsset: { assetDescriptor: AssetDescriptor | undefined, itemProps: any } = this.sceneManager.selectAssetFromMouseCoords(mouseClickCoord);
      if (selectedAsset.assetDescriptor) {
        const assetDescriptor: AssetDescriptor = selectedAsset.assetDescriptor;
        this.debugGUIManager.showToastMessage(`${assetDescriptor.title} - ${assetDescriptor.id}`);
        this.busBackend.publish(new AssetSelectedOutputMessage({
          id: assetDescriptor.id,
          title: assetDescriptor.title,
          isDynamicAsset: assetDescriptor.isDynamicAsset != null ? assetDescriptor.isDynamicAsset : false,
          itemProps: selectedAsset.itemProps
        }));
      }
    }
  }

  /**
   * The touch move handler function.
   * @param event The touch event.
   */
  private onTouchMove(event: TouchEvent): void {
    this.lastTouchEvent = event;
    event.stopPropagation();
    const dragDelta: number = new Vector2(event.touches[0].clientX - this.mouseDownPosition.x, event.touches[0].clientY - this.mouseDownPosition.y).length();
    if (dragDelta > 5) {
      this.isMouseDrag = true;
    }
    this.isMouseDrag = true;
  }

  /**
   * The touch end handler function.
   * @param event The touch event.
   */
  private onTouchEnd(event: TouchEvent): void {
    if (!this.isMouseDrag) {
      const el: Element = this.lastTouchEvent.touches[0].target as Element;
      if (el && el.id === "main-canvas") {
        const endTouch: Vector2 = new Vector2(this.lastTouchEvent.touches[0].clientX, this.lastTouchEvent.touches[0].clientY);
        const startTouch: Vector2 = new Vector2(this.firstTouchEvent.touches[0].clientX, this.firstTouchEvent.touches[0].clientY);
        const touchDelta: number = endTouch.clone().sub(startTouch).length();
        if (touchDelta < 5) {
          this.selectAsset(this.lastTouchEvent.touches[0].clientX, this.lastTouchEvent.touches[0].clientY);
        }
      }
    }
  }

  /**
   * The touch start handler function.
   * @param event The touch event.
   */
  private onTouchStart(event: TouchEvent): void {
    this.firstTouchEvent = event;
    this.lastTouchEvent = event;
    this.mouseDownPosition = new Vector2(event.touches[0].clientX, event.touches[0].clientY);
    this.isMouseDrag = false;
  }

  /**
   * The mouse move handler function.
   * @param event The mouse event.
   */
  private onMouseMove(event: MouseEvent): void {
    const dragDelta: number = new Vector2(event.clientX - this.mouseDownPosition.x, event.clientY - this.mouseDownPosition.y).length();
    if (this.isMouseDown && dragDelta > 5) {
      this.isMouseDrag = true;
    }
    if (this.config.gui.isAssetSelectedOnMouseHover) {
      if (this.mouseMoveTimeoutId !== null) {
        clearTimeout(this.mouseMoveTimeoutId);
      }
      this.mouseMoveTimeoutId = setTimeout(() => {
        this.mouseMoveTimeoutId = null;
        this.selectAsset(event.clientX, event.clientY);
      }, 250);
    }
  }

  /**
   * The mouse up handler function.
   * @param event The mouse event.
   */
  private onMouseUp(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isMouseDown = false;
    const element: Element = event.target as Element;
    if (event.button === 0 && element && element.id === "main-canvas") {
      if (!this.minimapManager.getIsMouseOverMinimap() && !this.isMouseDrag && !this.config.gui.isAssetSelectedOnMouseHover) {
        this.selectAsset(event.clientX, event.clientY);
      }
    }
    this.isMouseDrag = false;
  }

  /**
   * The mouse down handler function.
   * @param event The mouse event.
   */
  private onMouseDown(event: MouseEvent): void {
    this.isMouseDown = true;
    if (event.button === 0) {
      this.mouseDownPosition = new Vector2(event.clientX, event.clientY);
      this.isMouseDrag = false;
    }
  }

  /** The windows resize handler function. */
  private onWindowResize(): void {
    const mainCamera: PerspectiveCamera = this.sceneManager.getMainCamera();
    const webGLRenderer: WebGLRenderer = this.renderManager.getWebGLRenderer();
    const viewCone: ViewFrustum = this.minimapManager.getViewCone();
    mainCamera.aspect = this.container.offsetWidth / this.container.offsetHeight;
    mainCamera.updateProjectionMatrix();
    webGLRenderer.setPixelRatio(window.devicePixelRatio);
    webGLRenderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    viewCone.update();
    if (this.postprocessingManager && this.postprocessingManager.getIsPostProcessingEnabled()) {
      this.postprocessingManager.updatePassesSize();
    }
  }

  /**
   * Find the main viewer container (if exists) otherwise throws.
   * @param id The id of the HTML element.
   */
  private findContainer(id: string): HTMLElement {
    const container: HTMLElement | null = document.getElementById(id);
    if (!container) {
      throw new Error(`Container HTML element with id ${id} not found`);
    }
    return container;
  }


}
