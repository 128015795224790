/** @module managers */
import {
  WebGLRenderer, PCFSoftShadowMap, Scene, PerspectiveCamera, OrthographicCamera, DirectionalLight,
  Vector3, MathUtils as TMath, WebGLRendererParameters
} from "three";
import { log } from "../helpers/Logger";
import { OrbitControls } from "../controls/OrbitControls";
import { SceneManager } from "./SceneManager";
import { GUIManager } from "./GUIManager";
import { MinimapManager } from "./MinimapManager";
import { PostProcessingManager } from "./PostProcessingManager";
import { MarkerManager } from "./MarkerManager";
import { Compass } from "../gui/Compass";
import TWEEN from "@tweenjs/tween.js";

/**
 * This class wraps the main WebGL rendering logic.
 * https://threejs.org/docs/#api/en/renderers/WebGLRenderer
 */
export class RenderManager {

  /** Determines whether the rendering is enable. */
  private isRenderingEnabled: boolean;
  /** The main webgl renderer. */
  private webGLRenderer: WebGLRenderer;
  /** The postprocessing manager */
  private postProcessingManager!: PostProcessingManager;
  /** The scene manager. */
  private sceneManager: SceneManager;
  /** The minimap manager. */
  private minimapManager: MinimapManager;
  /** The compass. */
  private compass: Compass;
  /** The marker manager. */
  private markerManager: MarkerManager;

  /**
   * Creates a new instance.
   * @param container The viewer 3d container.
   * @param sceneMgr The scene manager.
   * @param guiMgr The gui manager.
   */
  constructor(container: HTMLElement, sceneMgr: SceneManager, guiMgr: GUIManager) {
    this.sceneManager = sceneMgr;
    this.minimapManager = guiMgr.getMinimapManager();
    this.markerManager = guiMgr.getMarkerManager();
    this.compass = guiMgr.getCompass();
    this.isRenderingEnabled = false;
    this.webGLRenderer = this.createWebGLRenderer(container);
    container.appendChild(this.webGLRenderer.domElement);
  }

  /**
   * Sets the rendering state.
   * @param isEnabled The boolean flag, true enables the rendering, false disables it.
   */
  public setRenderingEnabled(isEnabled: boolean): void {
    if (isEnabled) {
      if (this.isRenderingEnabled === false) {
        this.isRenderingEnabled = true;
        this.render();
      }
    }
    else {
      this.isRenderingEnabled = false;
    }
    log.debug(`RenderManager isRenderingEnabled ${this.isRenderingEnabled}`);
  }

  /** Gets the rendering enabled boolean state. */
  public getIsRenderingEnabled(): boolean {
    return this.isRenderingEnabled;
  }

  /** Gets the main webgl renderer */
  public getWebGLRenderer(): WebGLRenderer {
    return this.webGLRenderer;
  }

  /**
   * Sets the postprocessing manager.
   * @param ppm The postprocessing manager.
   */
  public setPostProcessingManager(ppm: PostProcessingManager): void {
    this.postProcessingManager = ppm;
  }


  /**
   * Creates the main webgl renderer
   * @param container The viewer 3d html container element.
   */
  private createWebGLRenderer(container: HTMLElement): WebGLRenderer {
    const canvasT: HTMLCanvasElement = document.createElement("canvas");
    canvasT.id = "main-canvas";
    const webGLRendererOptions: WebGLRendererParameters = {
      canvas: canvasT,
      antialias: true
    };
    const webGLRenderer: WebGLRenderer = new WebGLRenderer(webGLRendererOptions);
    webGLRenderer.setPixelRatio(window.devicePixelRatio * 1);
    webGLRenderer.setSize(container.offsetWidth, container.offsetHeight);
    webGLRenderer.autoClear = false;
    webGLRenderer.shadowMap.enabled = false;
    webGLRenderer.shadowMap.type = PCFSoftShadowMap;
    return webGLRenderer;
  }

  /** The main render method, renders the scene. */
  private render(): void {
    if (this.isRenderingEnabled) {
      requestAnimationFrame(this.render.bind(this));
    }

    TWEEN.update();

    const scene: Scene = this.sceneManager.getScene();
    const mainCamera: PerspectiveCamera = this.sceneManager.getMainCamera();
    const minimapCamera: OrthographicCamera = this.minimapManager.getMinimapCamera();
    const directionalLight: DirectionalLight = this.sceneManager.getDirectionalLight();
    const directionalLightOffset: Vector3 = this.sceneManager.getDirectionalLightOffset();
    const orbitControls: OrbitControls = this.sceneManager.getOrbitControls();

    for (const water of this.sceneManager.getWaters()) {
      water.material.uniforms.time.value += 0.5 / 60.0;
    }

    minimapCamera.position.set(orbitControls.target.x, orbitControls.target.y + 1000, orbitControls.target.z);
    orbitControls.update();
    directionalLight.target.position.set(orbitControls.target.x, orbitControls.target.y, orbitControls.target.z);
    const deltaShadowCamera: Vector3 = new Vector3(directionalLightOffset.x + orbitControls.target.x,
      directionalLightOffset.y + orbitControls.target.y, directionalLightOffset.z + orbitControls.target.z);
    directionalLight.position.set(deltaShadowCamera.x, deltaShadowCamera.y, deltaShadowCamera.z);

    mainCamera.near = TMath.clamp(mainCamera.position.y - 100, 1, 1000);
    mainCamera.far = TMath.clamp(Math.abs(orbitControls.getPolarAngle() * 180 / Math.PI) * 120, 3000, 5000);
    // mainCamera.far = TMath.clamp(Math.abs(orbitControls.getPolarAngle() * 180 / Math.PI) * 120, 15000, 20000);
    mainCamera.updateProjectionMatrix();

    if (this.postProcessingManager.getIsPostProcessingEnabled())
      this.postProcessingManager.getComposer().render();
    else
      this.webGLRenderer.render(scene, mainCamera);

    if (!this.minimapManager.getIsMinimapMinimized()) {
      this.minimapManager.getWebGLMinimapRenderer().render(scene, minimapCamera);
      if (this.minimapManager && this.minimapManager.getViewCone && this.minimapManager.getViewCone().update) {
        this.minimapManager.getViewCone().update();
      }
    }
    this.markerManager.updateMarkers();
    this.compass.setRotationRad(this.sceneManager.getOrbitControls().getRotationY());
  }

}
