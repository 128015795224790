/** @module managers */
import { Vector2, Vector3, OrthographicCamera, Camera, WebGLRenderer, WebGLRendererParameters } from "three";
import { GUIManager } from "./GUIManager";
import { SceneManager } from "./SceneManager";
import { OrbitControls } from "../controls/OrbitControls";
import { ViewFrustum } from "../helpers/ViewFrustum";
import { Viewer3DConfiguration } from "../models/Viewer3DConfiguration";

/** This class is responsible for the management of the minimap and its related features. */
export class MinimapManager {

  /** The minimap container html element */
  private minimapContainer: HTMLElement;
  /** The gui manager. */
  private guiManager: GUIManager;
  /** The scene manager. */
  private sceneManager!: SceneManager;

  /** Determines if the mouse is over the minimap. */
  private isMouseOverMinimap: boolean;
  /** Determines if the minimap is minimized. */
  private isMinimapMinimized: boolean;
  /** Scale factor for minimap (enforced when the mouse is over the minimap). */
  private onMouseOverMapScale: number;

  /** The initial minimap size, in pixel. */
  private initialMinimapSize: Vector2;
  /** The initial minimap offest, in pixel. */
  private initialMinimapOffset: Vector2;
  /** The actual minimap size, in pixel. */
  private actualMinimapSize: Vector2;
  /** The actual minimap offest, in pixel. */
  private actualMinimapOffset: Vector2;

  /** The minimap camera. */
  private minimapCamera: OrthographicCamera;
  /** The minimap orbit controls. */
  private minimapOrbitControls!: OrbitControls;
  /** The minimap view cone. */
  private viewCone!: ViewFrustum;
  /** The minimap webgl renderer. */
  private webGLMinimapRenderer: WebGLRenderer;

  /**
   * Creates a new instance.
   * @param guiManager The gui manager.
   */
  public constructor(guiManager: GUIManager) {
    this.guiManager = guiManager;
    this.minimapContainer = this.createMinimap();
    this.isMouseOverMinimap = false;
    this.isMinimapMinimized = false;
    const config: Viewer3DConfiguration = guiManager.getConfig();
    this.onMouseOverMapScale = config.gui.onMouseOverMapScale;

    this.initialMinimapSize = new Vector2(config.gui.initialMinimapSize.x, config.gui.initialMinimapSize.y);
    this.initialMinimapOffset = new Vector2(config.gui.initialMinimapOffset.x, config.gui.initialMinimapOffset.y);
    this.actualMinimapSize = this.initialMinimapSize.clone();
    this.actualMinimapOffset = this.initialMinimapOffset.clone();
    const minimapZoomFactor: number = 0.8;
    const minimapCameraLeft: number = this.initialMinimapSize.x * -minimapZoomFactor;
    const minimapCameraRight: number = this.initialMinimapSize.x * minimapZoomFactor;
    const minimapCameraTop: number = this.initialMinimapSize.y * minimapZoomFactor;
    const minimapCameraBottom: number = this.initialMinimapSize.y * -minimapZoomFactor;
    this.minimapCamera = this.createMinimapCamera(minimapCameraLeft, minimapCameraRight, minimapCameraTop, minimapCameraBottom);
    this.webGLMinimapRenderer = this.createWebGLMinimapRenderer();
    this.minimapContainer.appendChild(this.webGLMinimapRenderer.domElement);
    this.updateMinimapDiv();
    this.updateMinimapCanvasSize();
  }

  /**
   * Sets the scene manager instance.
   * @param sceneManager The scene manager.
   */
  public setSceneManager(sceneManager: SceneManager): void {
    this.sceneManager = sceneManager;
  }

  /** Gets the minimap container html element */
  public getContainer(): HTMLElement {
    return this.minimapContainer;
  }

  /** Get the minimap orbit controls. */
  public getMinimapOrbitControls(): OrbitControls {
    return this.minimapOrbitControls;
  }

  /** Get the boolean indicating whether the mouse is over the minimap. */
  public getIsMouseOverMinimap(): boolean {
    return this.isMouseOverMinimap;
  }

  /** Get a boolean indicating whether the minimap is minimized. */
  public getIsMinimapMinimized(): boolean {
    return this.isMinimapMinimized;
  }

  /** Gets the minmap webgl renderer. */
  public getWebGLMinimapRenderer(): WebGLRenderer {
    return this.webGLMinimapRenderer;
  }

  /** Gets the minimap camera. */
  public getMinimapCamera(): OrthographicCamera {
    return this.minimapCamera;
  }

  /** Gets the minimap view cone. */
  public getViewCone(): ViewFrustum {
    return this.viewCone;
  }

  /** Toggles the visibility of the minimap view cone. */
  public toggleViewConeVisible(): void {
    this.viewCone.visible = !this.viewCone.visible;
  }

  /** Adds the minimap event listeners. */
  public addEventListeners(): void {
    this.minimapContainer.addEventListener("dblclick", this.onMouseDoubleClick.bind(this), false);
    this.minimapContainer.addEventListener("mouseenter", this.onMouseEnterMinimap.bind(this), false);
    this.minimapContainer.addEventListener("mouseleave", this.onMouseLeaveMinimap.bind(this), false);
  }

  /**
   * Creates the minimap orbit controls.
   * @param target The main orbit controls target.
   */
  public createOrbitControls(target: Vector3): void {
    const orbitControls: OrbitControls = new OrbitControls(this.minimapCamera, this.minimapContainer, false);
    orbitControls.maxZoom = 10;
    orbitControls.minZoom = 0.1;
    orbitControls.enablePan = false;
    orbitControls.enableRotate = false;
    orbitControls.target = target;
    this.minimapOrbitControls = orbitControls;
  }

  /**
   * Creates the minimap view cone.
   * @param camera The camera.
   */
  public createViewCone(camera: Camera): ViewFrustum {
    const viewCone: ViewFrustum = new ViewFrustum(camera);
    viewCone.layers.set(5);
    this.viewCone = viewCone;
    return viewCone;
  }


  /**
   * Creates the webgl minimap renderer.
   */
  private createWebGLMinimapRenderer(): WebGLRenderer {
    const webGLRendererOptions: WebGLRendererParameters = {
      antialias: true
    };
    const webGLRenderer: WebGLRenderer = new WebGLRenderer(webGLRendererOptions);
    return webGLRenderer;
  }

  /**
   * Sets the minimap size.
   * @param size Scaling factor (from 0 to 1).
   */
  private setMinimapSize(size: number): void {
    this.actualMinimapSize = this.initialMinimapSize.clone().multiplyScalar(size);
  }

  /**
   * Minimizes the minimap.
   * @param offset Optional value of the offset from the margin.
   */
  private minimizeMinimap(offset?: number): void {
    this.actualMinimapOffset.add(new Vector2(0, - (this.actualMinimapSize.y - (offset ? offset : 0))));
  }

  /**
   * Maximizes the minimap.
   * @param offset Optional value of the offset from the margin.
   */
  private maximizeMinimap(offset?: number): void {
    this.actualMinimapOffset.add(new Vector2(0, +(this.actualMinimapSize.y + (offset ? offset : 0))));
  }

  /**
   * Creates the minimap camera.
   * @param left Camera frustum left plane.
   * @param right Camera frustum right plane.
   * @param top Camera frustum top plane.
   * @param bottom Camera frustum bottom plane.
   */
  private createMinimapCamera(left: number, right: number, top: number, bottom: number): OrthographicCamera {
    const minimapCamera: OrthographicCamera = new OrthographicCamera(left, right, top, bottom, -20, 2000);
    minimapCamera.position.set(0, 1, 0);
    minimapCamera.up = new Vector3(0, 0, -1);
    minimapCamera.lookAt(new Vector3(0, -1, 0));
    minimapCamera.layers.mask = ((1 << 0) | (1 << 1) | (1 << 5) | (1 << 2));
    return minimapCamera;
  }

  /**
   * Computes a click on the minimap.
   * @param event The mouse event.
   */
  private computeMinimapClick(event: MouseEvent): Vector2 {
    const fixedClick: Vector2 = this.guiManager.fixScreenSpaceCoordinatesFromScreen(this.minimapContainer, new Vector2(event.clientX, event.clientY));
    const clickX: number = fixedClick.x - (this.minimapContainer.clientWidth - (this.actualMinimapSize.x));
    const clickY: number = fixedClick.y - (this.minimapContainer.clientHeight - (this.actualMinimapSize.y));
    const mouseClickCoord: Vector2 = new Vector2((clickX / this.actualMinimapSize.x) * 2 - 1, - (clickY / this.actualMinimapSize.y) * 2 + 1);
    return mouseClickCoord;
  }

  /** Updates the minimap container style size using the actual size. */
  private updateMinimapDiv(): void {
    this.minimapContainer.style.width = this.actualMinimapSize.x + "px";
    this.minimapContainer.style.height = this.actualMinimapSize.y + "px";
    this.minimapContainer.style.marginRight = this.actualMinimapOffset.x + "px";
    this.minimapContainer.style.marginBottom = this.actualMinimapOffset.y + "px";
  }

  /**
   * Updates the minimap canvas.
   * @param dpi Optional dpi value.
   */
  private updateMinimapCanvasSize(dpi?: number): void {
    this.webGLMinimapRenderer.domElement.style.width = "100%";
    this.webGLMinimapRenderer.domElement.style.height = "100%";
    this.webGLMinimapRenderer.setPixelRatio(window.devicePixelRatio);
    const mapScale: number = this.isMouseOverMinimap ? this.onMouseOverMapScale : 1;
    this.webGLMinimapRenderer.setSize(this.initialMinimapSize.x * mapScale, this.initialMinimapSize.y * mapScale);
    if (this.viewCone) {
      this.viewCone.update();
    }
  }

  /**
   * Move the camera to the specified minimap position.
   * @param position The specified position.
   * @param orbitControls The orbit controls.
   * @param camera The camera.
   */
  private moveTo(position: Vector3, orbitControls: OrbitControls, camera: Camera): void {
    if (position.length() > 0) {
      const delta: Vector3 = position.clone().sub(orbitControls.target);
      delta.y = 0;
      orbitControls.target.set(position.x, orbitControls.target.y, position.z);
      camera.position.add(delta);
    }
  }

  /**
   * The minimap mouse double click handler function.
   * @param event The mouse event.
   */
  private onMouseDoubleClick(event: MouseEvent): void {
    const mouseClickCoord: Vector2 = this.computeMinimapClick(event);
    const position: Vector3 = this.sceneManager.screenToWorldPoint(this.minimapCamera, mouseClickCoord);
    this.moveTo(position, this.sceneManager.getOrbitControls(), this.sceneManager.getMainCamera());
  }

  /**
   * The minimap mouse leave handler function.
   * @param event The mouse event.
   */
  private onMouseLeaveMinimap(event: MouseEvent): void {
    this.isMouseOverMinimap = false;
    if (!this.isMinimapMinimized) {
      this.setMinimapSize(1);
      this.updateMinimapDiv();
      this.updateMinimapCanvasSize();
    }
  }

  /**
   * The minimap mouse enter handler function.
   * @param event The mouse event.
   */
  private onMouseEnterMinimap(event: MouseEvent): void {
    this.isMouseOverMinimap = true;
    if (!this.isMinimapMinimized && !this.guiManager.getIsMouseDrag()) {
      this.setMinimapSize(this.onMouseOverMapScale);
      this.updateMinimapDiv();
      this.updateMinimapCanvasSize();
    }
  }

  /** Creates the minimap open/close button and its handler. */
  private createMinimapButton(): HTMLElement {
    const minimapButton: HTMLElement = document.createElement("i");
    minimapButton.id = "minimap-button";
    minimapButton.className = "far fa-window-minimize";
    minimapButton.onclick = () => {
      if (this.isMinimapMinimized) {
        this.maximizeMinimap(-20);
        this.setMinimapSize(this.onMouseOverMapScale);
        this.isMinimapMinimized = false;
        this.isMouseOverMinimap = true;
      }
      else {
        this.setMinimapSize(1);
        this.minimizeMinimap(20);
        this.isMinimapMinimized = true;
        this.isMouseOverMinimap = false;
      }
      minimapButton.className = this.isMinimapMinimized ? "far fa-window-maximize" : "far fa-window-minimize";
      this.updateMinimapDiv();
      this.updateMinimapCanvasSize();
    };
    return minimapButton;
  }

  /** Creates the minimap container element */
  private createMinimap(): HTMLElement {
    const minimapContainer: HTMLElement = document.createElement("div");
    minimapContainer.id = "minimap";
    const minimapButton: HTMLElement = this.createMinimapButton();
    minimapContainer.appendChild(minimapButton);
    return minimapContainer;
  }

}
