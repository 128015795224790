import { Group } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { IFCModel } from "three/examples/jsm/loaders/IFCLoader";

/** This enum models the asset types. */
export enum AssetType {
  GLB = "GLB",
  IFC = "IFC"
}

/** This class models the loaded asset (GLB/IFC). */
export class LoadedAsset {

  /** The asset. */
  private asset: GLTF | IFCModel;
  /** The asset type. */
  private type: AssetType;
  /** The asset scene. */
  private scene: Group;

  /**
   * Creates a new instance.
   * @param asset The asset/3D model.
   * @param type The asset type.
   */
  constructor(asset: GLTF | IFCModel, type: AssetType) {
    this.asset = asset;
    this.type = type;
    if (this.type === AssetType.IFC) {
      const ifc: IFCModel = this.asset as IFCModel;
      const group: Group = new Group();
      group.add(ifc);
      this.scene = group;
    }
    else {
      const gltf: GLTF = this.asset as GLTF;
      this.scene = gltf.scene;
    }
  }

  /** Gets the asset type. */
  public getType(): AssetType {
    return this.type;
  }

  /** Gets the asset. */
  public getAsset(): GLTF | IFCModel {
    return this.asset;
  }

  /** Gets the asset scene. */
  public getScene(): Group {
    return this.scene;
  }

  /**
   * Sets the asset scene.
   * @param scene The scene to be set.
   */
  public setScene(scene: Group): void {
    this.scene = scene;
    if (this.type === AssetType.GLB) {
      (this.asset as GLTF).scene = scene;
    }
  }

}


