/** @module models */
import { Vector3 } from "three";


/** This class models a saved view. */
export class SavedView {

  /** The view position. */
  public position: Vector3;
  /** The view target. */
  public target: Vector3;

  /**
   * Creates a new instance.
   * @param position The view position (from camera position).
   * @param target The view target (from orbit controls target).
   */
  public constructor(position: Vector3, target: Vector3) {
    this.position = position;
    this.target = target;
  }
}
