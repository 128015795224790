/** @module managers */
import { Box3, Group, Object3D, Vector3, MathUtils, Quaternion, Euler, Mesh, BoxGeometry, MeshStandardMaterial, Color } from "three";
import { Tween } from "@tweenjs/tween.js";
import CheapRuler from "cheap-ruler";
import { Observable, Subject } from "rxjs";
import { sampleTime } from "rxjs/operators";
import { SetDynamicAssetsLocationInputMessageParameters, SetDynamicAssetsAddInputMessageParameters, SetDynamicAssetsRemoveInputMessageParameters } from "../models/SignalRInputMessage";
import { SetDynamicAssetsVisibilityInputMessageParameters } from "../models/BusInputMessage";
import { SceneManager } from "./SceneManager";
import { ReferencePoint } from "../models/ReferencePoint";
import { DynamicAssetDescriptor } from "../models/DynamicAssetDescriptor";
import { Viewer3DConfiguration } from "../models/Viewer3DConfiguration";
import { log } from "../helpers/Logger";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { GPSUtils } from "../helpers/GPSUtils";
import { TweenValue } from "../models/TweenValue";
import { AssetType, LoadedAsset } from "../models/LoadedAsset";

/** This class is responsible for managing the dynamic assets. */
export class DynamicAssetManager {

  /** The ruler for euclidean geodesic approximation. */
  private ruler: CheapRuler;
  /** The reference point for gps coordinates conversion. */
  private referencePoint: ReferencePoint;
  /*+ The dynamic asset instances map. */
  private dynamicAssets: Map<string, DynamicAssetDescriptor>;
  /** The dynamic asset templates map. */
  private dynamicAssetTemplates: Map<string, LoadedAsset>;
  /** The scene manager. */
  private sceneManager!: SceneManager;
  /** The default template id. */
  private defaultTemplateId: string;
  /** The reference point vector. */
  private referencePointVector: Vector3;
  /** The correction axis. */
  private correctionAxis: Vector3;
  /** The correction angle. */
  private correctionAngle: number;
  /** Determines if the dynamic assets are visible. */
  private visibleDynamicAssets: boolean;
  /** Determines if the dynamic assets functionality is enabled. */
  private dynamicAssetsEnabled: boolean;
  /** The maximum duration of dynamic asset movement between locations. */
  private maxAnimationDuration: number;
  // private subject: Subject<any>;
  // private pipedObservable: Observable<any>;
  /** The map containing the dynamic asset tweens. */
  private dynamicAssetsTweens: Map<string, TweenValue>;
  /** Determines if the mock template is used in place of the dynamic assets. */
  private isMockTemplate: boolean;

  /**
   * Creates a new instance.
   * @param config The viewer configuration.
   */
  constructor(config: Viewer3DConfiguration) {
    this.ruler = new CheapRuler(45.337170, "meters");
    this.referencePoint = {
      lat: 45.337170,
      lon: 12.328493,
      x: -313.0530512575940,
      z: 101.51472386822607
    };
    this.dynamicAssets = new Map();
    this.dynamicAssetTemplates = new Map();
    this.defaultTemplateId = "";
    this.visibleDynamicAssets = true;
    this.dynamicAssetsEnabled = config.dynamicAssetsEnabled != null ? config.dynamicAssetsEnabled : true;
    this.maxAnimationDuration = config.dynamicAssetsAnimationDurationLimit != null && config.dynamicAssetsAnimationDurationLimit > 0 ? config.dynamicAssetsAnimationDurationLimit : 3000;
    // this.subject = new Subject();
    // this.pipedObservable = this.subject.asObservable().pipe(sampleTime(1000));
    // this.pipedObservable.subscribe(data => {
    //     this.setDynamicAssetsLocationSampled(data);
    // });
    this.referencePointVector = new Vector3(this.referencePoint.x, 0, this.referencePoint.z);
    this.correctionAxis = new Vector3(0, 1, 0);
    this.correctionAngle = MathUtils.degToRad(1.9);
    this.dynamicAssetsTweens = new Map();
    this.isMockTemplate = false;
  }

  /** Gets the dynamic asset instances ids from the inner map. */
  public getDynamicAssetIds(): string[] {
    return Array.from(this.dynamicAssets.keys());
  }

  /**
   * Sets the scene manager instance.
   * @param sceneManager The scene manager instance.
   */
  public setSceneManager(sceneManager: SceneManager): void {
    this.sceneManager = sceneManager;
  }

  /**
   * Sets the dynamic asset template model on the dynamicAssetTemplates map.
   * @param templateId The id of the template model.
   * @param loadedAsset The loaded template asset.
   */
  public setTemplateModel(templateId: string, loadedAsset: LoadedAsset): void {
    this.dynamicAssetTemplates.set(templateId, loadedAsset);
    if (this.defaultTemplateId === "")
      this.defaultTemplateId = templateId;
  }

  /**
   * Creates dynamic assets instances.
   * @param data An array of items, each item represent a dynamic asset to be added to the scene.
   */
  public setDynamicAssetsAdd(data: SetDynamicAssetsAddInputMessageParameters[]): void {
    if (this.dynamicAssetsEnabled) {
      for (const item of data) {
        const { id, template, pivotFrontDistance, pivotLeftDistance, length, width, lat, lon, dir, time } = item;

        const templateName: string = template || this.defaultTemplateId;

        if ((this.dynamicAssetTemplates.has(templateName) && !this.dynamicAssets.has(id) && !this.isMockTemplate)
          || (!this.dynamicAssets.has(id) && this.isMockTemplate)) {

          let dynamicAssetScene: Group | undefined;
          let dynamicLoadedAsset: LoadedAsset | undefined;

          if (this.isMockTemplate) {
            dynamicAssetScene = new Group().add(
              new Mesh(new BoxGeometry(10, 10, 10),
                new MeshStandardMaterial({ color: new Color(0xff0000) }))
            );
            dynamicLoadedAsset = new LoadedAsset({} as any, AssetType.GLB);
          }
          else {
            const templateModel: LoadedAsset | undefined = this.dynamicAssetTemplates.get(templateName);
            if (templateModel) {
              dynamicAssetScene = templateModel.getScene().clone();
              dynamicLoadedAsset = new LoadedAsset(templateModel.getAsset(), templateModel.getType());
              dynamicLoadedAsset.setScene(dynamicAssetScene);
            }
          }

          if (dynamicAssetScene) {
            dynamicAssetScene.rotateY(-Math.PI / 2);

            const box: Box3 = new Box3().setFromObject(dynamicAssetScene);
            const size: Vector3 = new Vector3();
            box.getSize(size);

            let offsetX: number = size.x / 2;
            let offsetZ: number = size.z / 2;

            if (length != null && length > 0 && width != null && width > 0) {
              const height: number = width * (size.y / size.z);
              dynamicAssetScene.scale.set(width / size.z, height / size.y, length / size.x);
              offsetX = length / 2;
              offsetZ = width / 2;
            }

            if (pivotFrontDistance != null && pivotLeftDistance != null) {
              offsetX = offsetX - pivotFrontDistance;
              offsetZ = offsetZ - pivotLeftDistance;
              dynamicAssetScene.position.set(dynamicAssetScene.position.x - offsetX, dynamicAssetScene.position.y, dynamicAssetScene.position.z + offsetZ);
            }

            const pivot: Group = new Group();
            // pivot.add(new AxesHelper(300));
            pivot.add(dynamicAssetScene);
            pivot.traverse(obj => obj.userData.id = id);

            const dynamicAssetDescriptor: DynamicAssetDescriptor = { model: pivot };
            this.dynamicAssets.set(id, dynamicAssetDescriptor);

            if (lat != null && lon != null && dir != null) {
              const position: Vector3 = this.gpsToSceneCoords(lat, lon);
              const targetRotation: number = this.getTargetRotation(dir);
              pivot.position.set(position.x, position.y, position.z);
              pivot.rotation.set(0, targetRotation, 0);
              this.addDynamicAssetToScene(pivot, dynamicAssetDescriptor);
            }

            if (time != null) {
              dynamicAssetDescriptor.prevTime = time;
            }

            this.sceneManager.getAssetLoader().getAssetMap().set(id, {
              loadedAsset: dynamicLoadedAsset as LoadedAsset,
              descriptor: {
                id,
                title: id,
                parentId: null,
                children: [],
                geometryId: null,
                pose: {
                  pos: { x: 0, y: 0, z: 0 },
                  rot: { x: 0, y: 0, z: 0 }
                },
                isDynamicAsset: true
              }
            });
          }
          else {
            log.debug(`Loaded template asset not found: ${templateName}`);
          }
        }
        else {
          log.debug("Dynamic asset not added, already exist or template is unknown");
        }
      }
      log.debug(`Dynamic assets map count: ${this.dynamicAssets.size}`);
      log.debug(`Dynamic assets template count: ${this.dynamicAssetTemplates.size}`);
    }
  }

  /**
   * Removes dynamic asset instances.
   * @param data An array of items, each item represent a dynamic asset to be removed from the scene.
   */
  public setDynamicAssetsRemove(data: SetDynamicAssetsRemoveInputMessageParameters[]): void {
    if (this.dynamicAssetsEnabled) {
      for (const item of data) {
        if (this.dynamicAssets.has(item.id)) {
          this.stopAndRemoveTween(item.id);
          const dynamicAssetDescriptor: DynamicAssetDescriptor = (this.dynamicAssets.get(item.id) as DynamicAssetDescriptor);
          this.sceneManager.getScene().remove(dynamicAssetDescriptor.model);
          this.dynamicAssets.delete(item.id);
          this.sceneManager.getAssetLoader().getAssetMap().delete(item.id);
        }
      }
    }
  }

  /**
   * Sets dynamic assets location and direction.
   * @param data An array of items, each item represent a dynamic asset to be positioned in the scene.
   */
  public setDynamicAssetsLocation(data: SetDynamicAssetsLocationInputMessageParameters[]): void {
    if (this.dynamicAssetsEnabled) {
      for (const dynamicAssetData of data) {
        const { id, lat, lon, dir, time } = dynamicAssetData;
        if (this.dynamicAssets.has(id)) {
          const dynamicAssetDescriptor: DynamicAssetDescriptor = (this.dynamicAssets.get(id) as DynamicAssetDescriptor);
          const { model, prevTime, isAdded } = dynamicAssetDescriptor;

          const position: Vector3 = this.gpsToSceneCoords(lat, lon);
          const targetRotation: number = this.getTargetRotation(dir);

          if (time != null && prevTime != null && isAdded) {
            let animationDuration: number = time - prevTime;
            if (animationDuration > this.maxAnimationDuration) {
              animationDuration = this.maxAnimationDuration;
            }
            const currentRotation: number = model.rotation.y;
            const diff: number = targetRotation - currentRotation;
            if (Math.abs(diff) > Math.PI) {
              const adjustedCurrentRotation: number = currentRotation + (diff > 0 ? (2 * Math.PI) : -(2 * Math.PI));
              model.rotation.set(0, adjustedCurrentRotation, 0);
            }
            this.stopAndRemoveTween(id);
            const tweenPosition: Tween<Vector3> = new Tween(model.position).to(position, animationDuration);
            const tweenRotation: Tween<Euler> = new Tween(model.rotation).to(new Vector3(0, targetRotation, 0), animationDuration);
            this.dynamicAssetsTweens.set(id, { tweenPosition, tweenRotation });
            tweenPosition.start();
            tweenRotation.start();
            dynamicAssetDescriptor.prevTime = time;
          }
          else {
            model.position.set(position.x, position.y, position.z);
            model.rotation.set(0, targetRotation, 0);
            this.addDynamicAssetToScene(model, dynamicAssetDescriptor);
            if (time != null) {
              dynamicAssetDescriptor.prevTime = time;
            }
          }
        }
      }
    }
  }

  /**
   * Set dynamic assets visibility (visible or hidden)
   * @param data The visibility event data
   */
  public SetDynamicAssetsVisibility(data: SetDynamicAssetsVisibilityInputMessageParameters): void {
    const { show, all, id } = data;
    if (all) {
      this.visibleDynamicAssets = show;
      const dynamicAssets: DynamicAssetDescriptor[] = Array.from(this.dynamicAssets.values());
      for (const dynamicAsset of dynamicAssets) {
        dynamicAsset.model.visible = show;
      }
    }
    else {
      const dynamicAsset: DynamicAssetDescriptor | undefined = this.dynamicAssets.get(id);
      if (dynamicAsset != null) {
        dynamicAsset.model.visible = show;
      }
    }
  }

  /**
   * Translates the GPS coordinates of a point to the correspondent scene coordinates.
   * @param lat The latitude of the point
   * @param lon The longitude of the point
   */
  public gpsToSceneCoords(lat: number, lon: number): Vector3 {
    const scenePos: number[] = GPSUtils.GPSPosition2Local([lon, lat]);
    const relativePos: number[] = [
      scenePos[0] - GPSUtils.SceneOrigin.x,
      scenePos[1] - GPSUtils.SceneOrigin.z
    ];
    const x: number = relativePos[0];
    const z: number = -relativePos[1];
    const vec: Vector3 = new Vector3(x, 0, z);
    return vec;
  }

  /**
   * Translates the GPS coordinates of a point to the correspondent scene coordinates (legacy).
   * @param lat The latitude of the point
   * @param lon The longitude of the point
   */
  public gpsToSceneCoordsLegacy(lat: number, lon: number): Vector3 {
    const distance: number = this.ruler.distance([this.referencePoint.lon, this.referencePoint.lat], [lon, lat]);
    const bearing: number = this.ruler.bearing([this.referencePoint.lon, this.referencePoint.lat], [lon, lat]);
    const dz: number = Math.sin(MathUtils.degToRad(bearing - 90)) * distance;
    const dx: number = Math.cos(MathUtils.degToRad(bearing - 90)) * distance;
    const z: number = this.referencePoint.z + dz;
    const x: number = this.referencePoint.x + dx;
    const vec: Vector3 = new Vector3(x, 0, z);
    return vec;
  }

  /**
   * Rotates an object around predefined point in the scene, world axis and angle.
   * @param object The object to rotate.
   */
  public rotateAroundWorldAxis(object: Object3D): void {
    const rotation: Quaternion = new Quaternion();
    rotation.setFromAxisAngle(this.correctionAxis, this.correctionAngle);
    object.applyQuaternion(rotation);
    object.position.sub(this.referencePointVector);
    object.position.applyQuaternion(rotation);
    object.position.add(this.referencePointVector);
  }

  /**
   * Gets the reference point.
   */
  public getReferencePoint(): ReferencePoint {
    return this.referencePoint;
  }

  /** Stops and removes the dynamic asset tween from map.
   * @param id The dynamic asset id.
   */
  private stopAndRemoveTween(id: string): void {
    if (this.dynamicAssetsTweens.has(id)) {
      const tweens: TweenValue = this.dynamicAssetsTweens.get(id) as TweenValue;
      tweens.tweenPosition.stop();
      tweens.tweenRotation.stop();
      this.dynamicAssetsTweens.delete(id);
    }
  }

  /**
   * Calculate the rotation to be applied to the dynamic asset from the heading.
   * @param dir The heading of the dynamic asset.
   */
  private getTargetRotation(dir: number): number {
    const adjustedDirection: number = -dir + 90;
    const normalizedDirection: number = adjustedDirection % 360;
    const targetRotation: number = MathUtils.degToRad(normalizedDirection);
    return targetRotation;
  }

  /**
   * Auxiliary function to add a dynamic asset to the scene.
   * @param object The dynamic asset to add.
   * @param dynamicAssetDescriptor The dynamic asset descriptor.
   */
  private addDynamicAssetToScene(object: Object3D, dynamicAssetDescriptor: DynamicAssetDescriptor): void {
    if (!dynamicAssetDescriptor.isAdded) {
      if (!this.visibleDynamicAssets) {
        object.visible = false;
      }
      this.sceneManager.getScene().add(object);
      dynamicAssetDescriptor.isAdded = true;
    }
  }

  // public setDynamicAssetsLocation(data: SetDynamicAssetsLocationInputMessageParameters[]): void {
  //   this.subject.next(data);
  // }

}

