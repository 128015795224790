/** @module managers */
import { Vector3, Vector2, Frustum, Matrix4, Camera, WebGLRenderer, Group } from "three";
import { SceneManager } from "./SceneManager";
import { RenderManager } from "./RenderManager";
import { Marker } from "../gui/Marker";
import { BusBackend } from "../services/BusBackend";
import { SetMarkersInputMessageParameters, MarkerMessage } from "../models/BusInputMessage";
import { MarkerSelectedOutputMessageParameters, MarkerSelectedOutputMessage } from "../models/BusOutputMessage";
import { LoadedAssetDescriptor } from "../models/LoadedAssetDescriptor";


/** This class is responsible for markers management. */
export class MarkerManager {

  /** The markers container html element. */
  private markerContainer: HTMLElement;
  /** The scene manager instance. */
  private sceneManager!: SceneManager;
  /** The bus backend service. */
  private busBackend!: BusBackend;
  /** The render manager. */
  private renderManager!: RenderManager;
  /** The markers array. */
  private markers: Marker[];
  /** Holds the last focused marker. */
  private focusedMarker: Marker | undefined;

  /** Creates a new instance. */
  public constructor() {
    this.markers = [];
    this.markerContainer = this.createMarkersContainer();
  }

  /**
   * Sets the bus backend.
   * @param busBackend The bus backend service.
   */
  public setBusBackend(busBackend: BusBackend): void {
    this.busBackend = busBackend;
  }

  /**
   * Sets the scene manager.
   * @param sceneManager The scene manager.
   */
  public setSceneManager(sceneManager: SceneManager): void {
    this.sceneManager = sceneManager;
  }

  /** Gets the html element marker container. */
  public getContainer(): HTMLElement {
    return this.markerContainer;
  }

  /** Gets all markers. */
  public getMarkers(): Marker[] {
    return this.markers;
  }

  /**
   * Sets the render manager.
   * @param renderManager The render manager.
   */
  public setRenderManager(renderManager: RenderManager): void {
    this.renderManager = renderManager;
  }

  /**
   * Sends the marker message to the output bus.
   * @param markerMessage The marker message to be sent.
   * @param action The marker action.
   */
  public publishMarkerMessage(markerMessage: MarkerMessage, action: "clicked" | "entered" | "exited"): void {
    const params: MarkerSelectedOutputMessageParameters = {
      action,
      id: markerMessage.markerId,
      position: markerMessage.position,
      assetId: markerMessage.assetId
    };
    this.busBackend.publish(new MarkerSelectedOutputMessage(params));
  }

  /**
   * Set marker as focused (bring the marker to front).
   * @param marker The marker to be focused.
   */
  public newMarkerInFocus(marker: Marker): void {
    if (this.focusedMarker) {
      this.focusedMarker.getContainer().style.removeProperty("z-index");
    }
    marker.getContainer().style.zIndex = "100";
    this.focusedMarker = marker;
  }

  /** Updates all markers. */
  public updateMarkers(): void {
    for (const marker of this.markers) {
      marker.update();
    }
  }

  /** Removes all markers. */
  public removeAllMarkers(): void {
    for (const m of this.markers) {
      m.closeBalloon();
    }
    this.markers = [];
    while (this.markerContainer.firstChild) {
      this.markerContainer.removeChild(this.markerContainer.firstChild);
    }
  }

  /**
   * Removes the provided marker
   * @param marker The marker to be removed.
   */
  public removeMarker(marker: Marker): void {
    const index: number = this.markers.indexOf(marker);
    if (index >= 0) {
      marker.closeBalloon();
      this.markerContainer.removeChild(marker.getContainer());
      this.markers.splice(index, 1);
    }
  }

  /**
   * Updates markers from marker event parameters.
   * @param params The marker event parameters.
   */
  public updateMarkersFromEvent(params: SetMarkersInputMessageParameters): void {
    this.removeAllMarkers();
    const assetMap: Map<string, LoadedAssetDescriptor> = this.sceneManager.getAssetLoader().getAssetMap();
    for (const markerMessage of params.markers) {
      this.validateMarkerMessage(markerMessage, assetMap);
      const marker: Marker = new Marker(this, /*this.sceneManager, this.busBackend,*/ markerMessage);
      this.markers.push(marker);
      this.markerContainer.appendChild(marker.getContainer());
    }
  }

  /**
   * Gets the marker screen position, returns null if it is outside view frustum.
   * @param position
   */
  public getMarkerScreenPosition(position: Vector3): Vector2 | null {
    const pos: Vector3 = position instanceof Vector3 ?
      position.clone() :
      new Vector3((position as any).x, (position as any).y, (position as any).z);
    const mainCamera: Camera = this.sceneManager.getMainCamera();
    const render: WebGLRenderer = this.renderManager.getWebGLRenderer();
    mainCamera.updateMatrix();
    mainCamera.updateMatrixWorld();
    const frustum: Frustum = new Frustum();
    frustum.setFromProjectionMatrix(new Matrix4().multiplyMatrices(mainCamera.projectionMatrix, mainCamera.matrixWorldInverse));
    if (frustum.containsPoint(pos)) {
      pos.project(mainCamera);
      const widthHalf: number = render.domElement.width / 2;
      const heightHalf: number = render.domElement.height / 2;
      pos.x = (pos.x * widthHalf) + widthHalf;
      pos.y = - (pos.y * heightHalf) + heightHalf;
      return new Vector2(pos.x / window.devicePixelRatio, pos.y / window.devicePixelRatio);
    }
    else {
      return null;
    }
  }


  /** Creates the markers html container element. */
  private createMarkersContainer(): HTMLElement {
    const labelsContainer: HTMLElement = document.createElement("div");
    labelsContainer.id = "Labels";
    return labelsContainer;
  }

  /**
   * Validates the marker message.
   * @param markerMessage The marker message to be validated.
   * @param assetMap The asset map.
   */
  private validateMarkerMessage(markerMessage: MarkerMessage, assetMap: Map<string, LoadedAssetDescriptor>): void {
    let valid: boolean = true;
    let error: string = "Invalid MarkerMessage: ";
    if (!markerMessage.markerId) {
      error += " -Missing markerId- ";
      valid = false;
    }
    if (markerMessage.icon == null) {
      error += " -Missing icon- ";
      valid = false;
    }
    if (!markerMessage.position && !markerMessage.assetId) {
      error += " -Missing both position and assetId, at least one is required- ";
      valid = false;
    }
    if (!valid) {
      throw new Error(error);
    }
    if (markerMessage.assetId !== undefined && markerMessage.position === undefined) {
      const loadedAssetDescriptor: LoadedAssetDescriptor | undefined = assetMap.get(markerMessage.assetId);
      if (loadedAssetDescriptor) {
        const assetScene: Group = loadedAssetDescriptor.loadedAsset.getScene();
        let assetPosition: Vector3 = assetScene.position;
        if (loadedAssetDescriptor.descriptor.isDynamicAsset && assetScene.parent) {
          assetPosition = assetScene.parent.position;
        }
        markerMessage.position = assetPosition;
      }
      else {
        throw new Error(`validateMarkerMessage: Asset ${markerMessage.assetId} not found`);
      }
    }
  }

}
